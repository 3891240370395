/*
 * Generated type guards for "inputCalculator.ts".
 * WARNING: Do not manually change this file.
 */

import type {
  InputCalculatorControlData,
  InputCalculatorControlOptions
} from './inputCalculator'
import { FactorPreset } from './inputCalculator'

export function isInputCalculatorControlData(
  obj: any,
  _argumentName?: string
): obj is InputCalculatorControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.numberInput === 'undefined' ||
        typeof obj.numberInput === 'string') &&
      (typeof obj.numberUnit === 'undefined' ||
        typeof obj.numberUnit === 'string') &&
      (typeof obj.frequencyUnit === 'undefined' ||
        typeof obj.frequencyUnit === 'string') &&
      (typeof obj.devices === 'undefined' ||
        (Array.isArray(obj.devices) &&
          obj.devices.every(
            (e: any) =>
              ((e !== null && typeof e === 'object') ||
                typeof e === 'function') &&
              (e.name === null || typeof e.name === 'string') &&
              typeof e.quantity === 'string' &&
              typeof e.unitaryConsumption === 'string' &&
              (typeof e.consumption === 'undefined' ||
                typeof e.consumption === 'string')
          ))) &&
      (typeof obj._isValid === 'undefined' ||
        obj._isValid === false ||
        obj._isValid === true))
  )
}

export function isInputCalculatorControlOptions(
  obj: any,
  _argumentName?: string
): obj is InputCalculatorControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.deviceUnit === 'undefined' ||
      typeof obj.deviceUnit === 'string') &&
    (typeof obj.deviceOptions === 'undefined' ||
      (Array.isArray(obj.deviceOptions) &&
        obj.deviceOptions.every(
          (e: any) =>
            ((e !== null && typeof e === 'object') ||
              typeof e === 'function') &&
            typeof e.value === 'string' &&
            (typeof e.factor === 'undefined' ||
              (((e.factor !== null && typeof e.factor === 'object') ||
                typeof e.factor === 'function') &&
                (typeof e.factor.preset === 'undefined' ||
                  e.factor.preset === FactorPreset.Gas ||
                  e.factor.preset === FactorPreset.Power ||
                  e.factor.preset === FactorPreset.WaterHeater ||
                  e.factor.preset === FactorPreset.Custom) &&
                (typeof e.factor.custom_mappings === 'undefined' ||
                  (Array.isArray(e.factor.custom_mappings) &&
                    e.factor.custom_mappings.every(
                      (e: any) =>
                        ((e !== null && typeof e === 'object') ||
                          typeof e === 'function') &&
                        typeof e.quantity === 'number' &&
                        (typeof e.unitaryDemand === 'undefined' ||
                          typeof e.unitaryDemand === 'number') &&
                        (typeof e.multiplicationFactor === 'undefined' ||
                          typeof e.multiplicationFactor === 'number')
                    )))))
        ))) &&
    (typeof obj.frequencyUnit === 'undefined' ||
      typeof obj.frequencyUnit === 'string') &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}
