// Generated by ts-to-zod
import { z } from 'zod'

import { BLOCK_TYPE } from '../../../types/next'

import { iBlockSchema } from './baseBlock.zod'

export const textInputBlockSchema = iBlockSchema.extend({
  type: z.literal(BLOCK_TYPE.TEXT_INPUT),
  settings: z.never().optional()
})
