// Generated by ts-to-zod
import { z } from 'zod'

import { LAYOUT_TYPE } from '../../../types/next'

import { layoutTypeSchema } from './enums.zod'
import { blockIdSchema } from './ids.zod'

export const iLayoutSchema = z.object({
  type: layoutTypeSchema,
  settings: z.record(z.unknown()).optional()
})

export const layoutZoneSchema = z.object({
  children: z.array(blockIdSchema)
})

export const stackLayoutSchema = iLayoutSchema.extend({
  type: z.literal(LAYOUT_TYPE.STACK),
  settings: z.never().optional(),
  content: layoutZoneSchema
})

export const twoColumnLayoutSchema = iLayoutSchema.extend({
  type: z.literal(LAYOUT_TYPE.TWO_COLUMN),
  settings: z.never().optional(),
  header: layoutZoneSchema,
  content: layoutZoneSchema,
  sidebar: layoutZoneSchema,
  footer: layoutZoneSchema
})

export const stepLayoutSchema = z.union([
  stackLayoutSchema,
  twoColumnLayoutSchema
])
