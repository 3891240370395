const SPACE_TAB_REGEX = /[ \t]+/g

/**
 * @todo Make this immutable
 */
export const trimObject = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      return
    }

    if (typeof value === 'string') {
      obj[key] = value.replace(SPACE_TAB_REGEX, ' ').trim()
    } else if (Array.isArray(value)) {
      obj[key] = value.map((item) =>
        typeof item === 'string'
          ? item.replace(SPACE_TAB_REGEX, ' ').trim()
          : item
      )
    } else if (typeof value === 'object') {
      obj[key] = trimObject(value as Record<string, unknown>)
    }
  })

  return obj
}
