import type { TFunction } from 'i18next'

import { getUnitTranslation } from '../../utils'
import type { BlockVariableConfig } from '../types'

export const simpleInputBlockProperties = ['value'] as const

export type SimpleInputBlockProperties =
  (typeof simpleInputBlockProperties)[number]

export const getVariablesFromSimpleInputBlock = (): BlockVariableConfig => {
  return {
    isAccessedByIndex: false,
    properties: [...simpleInputBlockProperties]
  }
}

export const getTextValuesFromSimpleInputBlock = ({
  state: blockState
}: {
  state: unknown
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<SimpleInputBlockProperties, string>> = {}

  if (Array.isArray(blockState)) {
    data.value = blockState.map((value) => value.toString()).join(', ') ?? ''
  } else if (typeof blockState === 'string') {
    data.value = blockState
  }

  return data
}

export const getNumberValuesFromSimpleInputBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (!blockState || typeof blockState !== 'object' || blockState === null) {
    return {}
  }

  const data: Partial<Record<SimpleInputBlockProperties, string>> = {}

  if ('numberInput' in blockState) {
    let result = `${blockState.numberInput}`

    if (
      'numberUnit' in blockState &&
      blockState.numberUnit &&
      blockState.numberUnit !== ''
    ) {
      const unit = getUnitTranslation(blockState.numberUnit as string, t)

      if (unit.length > 0) {
        result = `${result} ${unit}`
      }
    }

    data.value = result
  }

  return data
}
