import type { TFunction } from 'i18next'

import { toDateString, type UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  salutation?: string
  title?: string
  firstName?: string
  lastName?: string
  birthDate?: Date
  email?: string
  telephone?: string
  customerType?: string
  companyName?: string
  registryCourt?: string
  registerNumber?: string
}

const identificationProperties = [
  'salutation',
  'title',
  'firstName',
  'lastName'
] as const

const companyProperties = [
  'companyName',
  'registryCourt',
  'registerNumber'
] as const

const contactProperties = ['telephone', 'email'] as const

const generalProperties = ['customerType'] as const

const personalInformationDateProperties = ['birthDate'] as const

const personalInformationStringProperties = [
  ...identificationProperties,
  ...companyProperties,
  ...contactProperties,
  ...generalProperties
] as const

export const personalInformationBlockProperties = [
  ...generalProperties,
  ...identificationProperties,
  ...contactProperties,
  ...personalInformationDateProperties,
  ...companyProperties
] as const

export type PersonalInformationBlockProperties =
  (typeof personalInformationBlockProperties)[number]

export const getVariablesFromPersonalInformationBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: PersonalInformationBlockProperties[] = []

  personalInformationBlockProperties.forEach((property) => {
    if (uiSchema.options?.fields?.[property] !== undefined) {
      properties.push(property)
    }
  })

  return {
    isAccessedByIndex: false,
    properties
  }
}

export const getValuesFromPersonalInformationBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<PersonalInformationBlockProperties, string>> = {
    ...getValuesFromBlockState(
      blockState as Omit<BlockState, 'birthDate'>,
      personalInformationStringProperties,
      (value, property) =>
        property === 'salutation'
          ? t(`salutation.options.${value.replace(/\./g, '·')}`, value)
          : value
    ),
    ...getValuesFromBlockState(
      blockState as Partial<{ birthDate?: Date }>,
      personalInformationDateProperties,
      (value) => toDateString(value, true)
    )
  }

  return data
}
