// Generated by ts-to-zod
import { z } from 'zod'

import { BLOCK_TYPE } from '../../../types/next'

import { iBlockSchema, blockBaseSettingsSchema } from './baseBlock.zod'
import { blockIdSchema } from './ids.zod'

export const groupBlockSchema = iBlockSchema.extend({
  type: z.literal(BLOCK_TYPE.GROUP),
  required: z.literal(false),
  settings: blockBaseSettingsSchema,
  parent: z.never().optional(),
  children: z.array(blockIdSchema)
})
