export enum EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE {
  EXIT_FULL_SCREEN = 'EPILOT/EXIT_FULLSCREEN',
  ENTER_FULL_SCREEN = 'EPILOT/ENTER_FULLSCREEN',
  FORM_EVENT = 'EPILOT/FORM_EVENT',
  JOURNEY_LOADED = 'EPILOT/JOURNEY_LOADED',
  USER_EVENT_PAGE_VIEW = 'EPILOT/USER_EVENT/PAGE_VIEW',
  USER_EVENT_PROGRESS = 'EPILOT/USER_EVENT/PROGRESS',
  CLOSE_JOURNEY = 'EPILOT/CLOSE_JOURNEY',
  ASSETS_READY = 'EPILOT/JOURNEY_ASSETS_LOADED',
  /**
   * Used for ad-hoc token retrieval from the parent window, e.g., from Portals
   */
  GET_PARENT_TOKEN = 'EPILOT/GET_PARENT_TOKEN',
  /**
   * Used by the parent window to send the requested token. See `GET_PARENT_TOKEN`
   */
  PARENT_TOKEN_RECEIVED = 'EPILOT/PARENT_TOKEN_RECEIVED',
  /**
   * Legacy event type, maintained for compatibility
   *
   * @deprecated
   */
  LEGACY_EXIT_FULL_SCREEN = 'ExitFullScreen'
}

export type DispatchUserEventType =
  | EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PAGE_VIEW
  | EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PROGRESS
