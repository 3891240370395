import type {
  PriceItemWithBlockConfiguration,
  CompositePrice,
  ExternalCatalogData,
  Price,
  SelectedProductTile,
  ShoppingCartDataItem
} from '@epilot/journey-logic-commons'
import { defineCartData } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

export type SummaryPriceItem = PriceItemWithBlockConfiguration & {
  externalCatalogData: ExternalCatalogData | undefined
}

type UseGetProductBlockSummaryDataProps = {
  data: SelectedProductTile | SelectedProductTile[]
}

export const useGetProductBlockSummaryData = (
  props: UseGetProductBlockSummaryDataProps
): { summaryPriceItems: SummaryPriceItem[] } => {
  const summaryPriceItems = useMemo(() => {
    const items = Array.isArray(props.data) ? props.data : [props.data]

    const productsData = items.map<ShoppingCartDataItem>((item) => {
      const { product, quantity } = item
      const { selectedPriceId, selectionMetadata } = product ?? {}
      const {
        selectedProduct,
        selectedPrice,
        selectedExternalCatalogData,
        blockConfiguration
      } = selectionMetadata ?? {}

      return {
        _id: selectedPriceId ?? '',
        quantity,
        product: selectedProduct,
        price: selectedPrice as Price | CompositePrice,
        externalCatalogData: selectedExternalCatalogData,
        blockConfiguration
      }
    })

    const { aggregatedPrices } = defineCartData(productsData)

    const aggregatedItems =
      aggregatedPrices.items as PriceItemWithBlockConfiguration[]

    return (
      aggregatedItems?.map((item) => ({
        ...item,
        externalCatalogData: productsData.find(
          (cartItem) => cartItem._id === item._id
        )?.externalCatalogData
      })) ?? []
    )
  }, [props.data])

  return { summaryPriceItems }
}
