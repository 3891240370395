import type { EpilotTheme, EpilotTypography } from '@epilot/journey-elements'
import { DefaultEpilotTheme } from '@epilot/journey-elements'
import type {
  Journey,
  StepState,
  StepExtended,
  Step,
  ValueError
} from '@epilot/journey-logic-commons'
import { isBlockValid, isLauncherJourney } from '@epilot/journey-logic-commons'
import type { JsonFormsCore } from '@jsonforms/core'
import isEqual from 'fast-deep-equal/react'
import { useMemo } from 'react'

import { initAJV } from '../../utils/initAJV'

const defaultTheme = { ...structuredClone(DefaultEpilotTheme), spacing: 4 }

export const useJourneyThemeTransformed = (
  journey: Journey,
  activeJourneyTheme?: NonNullable<Journey['design']>['theme']
) =>
  useMemo<EpilotTheme>(() => {
    const isCurrentJourneyLauncher = journey && isLauncherJourney(journey.steps)

    const journeyTheme: EpilotTheme = {
      ...structuredClone(activeJourneyTheme ?? defaultTheme),
      breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1200, xl: 1536 } }
    }

    /**
     * If we're viewing step 1 of a linked journey, we need to ensure the fonts
     * from the linked journey theme are injected by CssBaseline
     */
    const shouldAppendActiveJourneyFonts =
      isCurrentJourneyLauncher &&
      (activeJourneyTheme?.typography as EpilotTypography)?.fontSource

    if (shouldAppendActiveJourneyFonts) {
      journeyTheme.typography = {
        ...(journeyTheme.typography as EpilotTypography),
        fontSource: [
          ...(journeyTheme.typography?.fontSource ?? []),
          ...((activeJourneyTheme as EpilotTheme)?.typography?.fontSource ?? [])
        ]
      }
    }

    return journeyTheme
  }, [journey, activeJourneyTheme])

export const decodeTheme = (theme: string) => {
  if (theme !== undefined && theme !== null && theme !== '') {
    const parsedTheme = JSON.parse(atob(theme))

    return parsedTheme
  }

  return
}

export const getValueErrors = (
  isJourneyLaucher: boolean,
  value: Pick<JsonFormsCore, 'data' | 'errors'>,
  currentStepIndex: number,
  newState: StepState,
  currentStep: Step
) => {
  // check each block value for _isValid
  let blockErrors: ValueError[] = []

  blockErrors = computeBlockErrors(
    { ...currentStep, stepIndex: currentStepIndex },
    newState
  )

  let launcherErrors = []

  if (isJourneyLaucher) {
    launcherErrors = value.data['Launcher block']?.errors
  }

  return [...(value.errors ?? []), ...blockErrors, ...(launcherErrors || [])]
}

const computeBlockErrors = (
  step: StepExtended,
  state: StepState
): ValueError[] => {
  if (!state) {
    return []
  }

  return Object.keys(state)
    .filter((blockName) => !isBlockValid(step, state, blockName))
    .map<ValueError>((blockName) => ({
      instancePath: `/${blockName}`,
      message: 'Not valid'
    }))
}

export const isNavigationToLinkedJourneyAllowed = (
  linkedJourney: Journey,
  state: StepState
): boolean => {
  const ajv = initAJV()
  const [initialStep] = linkedJourney.steps
  const schema = initialStep.schema

  return ajv.validate(schema, state)
}

export function getWhichBlockHasChanged(
  oldStat?: Record<string, unknown>,
  newStat?: Record<string, unknown>
) {
  if (!newStat) {
    return undefined
  }
  const newKeys = Object.keys(newStat)
  const oldKeys = oldStat ? Object.keys(oldStat) : []

  if (!oldStat) {
    return newKeys
  }

  return newKeys
    .filter((key) => !isEqual(newStat[key], oldStat[key]))
    .concat(oldKeys.filter((key) => !(key in newStat)))
}

/**
 * this function would clean up the required array from blocks mentioned there that are not referenced in the uischema
 * @param rawStep the step to clean up
 * @returns cleaned step
 */
export function protectAgainstRequiredNoUischema(
  uischema: StepExtended['uischema'],
  blockesRequired?: string[]
) {
  // const step = structuredClone(rawStep)
  // const blockesRequired = step.schema?.required
  let cleanedArray: string[] = []

  if (blockesRequired && blockesRequired.length > 0) {
    const uischemaStr = JSON.stringify(uischema)

    // filter any block which does not exists in uischema
    cleanedArray = blockesRequired.filter((blockName) =>
      uischemaStr.includes(`#/properties/${blockName}`)
    )
  }

  return cleanedArray
}
