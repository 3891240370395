import { CircularProgress } from '@epilot/concorde-elements'

import classes from './SpinnerPage.module.scss'

export const SpinnerPage = () => {
  return (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  )
}
