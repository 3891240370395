export enum JOURNEY_SCHEMA_VERSION {
  /**
   * Version 4
   * Introduced in {TODO: Add date}
   */
  V4 = 'v4'
}

/** TODO: Better describe each case */
export enum JOURNEY_TYPE {
  /** Regular journey */
  REGULAR = 'regular',
  /** Journey that launches other journeys */
  LAUNCHER = 'launcher',
  /** Post-qualification journey */
  POST_QUALIFICATION = 'post-qualification'
}

export enum JOURNEY_ACCESS_MODE {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export enum JOURNEY_STATUS {
  PUBLISHED = 'published',
  DRAFT = 'draft'
}

export enum JOURNEY_EMBED_MODE {
  /**
   * Indicates the Journey is embedded in a full-screen,
   * although that might be either in an iframe (i.e. within a dialog) or not.
   */
  FULL_SCREEN = 'full-screen',
  /**
   * Indicates the Journey is embedded in a page, which certainly means
   * it's in an iframe.
   */
  INLINE = 'inline'
}

export enum JOURNEY_PURPOSE {
  /** Refers to {@link RUNTIME_ENTITY.ORDER} */
  SELL_360 = 'sell-360-products',
  /** Refers to both {@link RUNTIME_ENTITY.ORDER} and {@link RUNTIME_ENTITY.OPPORTUNITY} */
  REQUEST_360 = 'request-360-products',
  /** Refers to {@link RUNTIME_ENTITY.OPPORTUNITY} */
  GENERAL_REQUESTS = 'general-requests',
  /** Refers to 'none' or empty runtime entities */
  OTHER = 'other'
}

// TODO: Rename values to lowercase while checking underlying services
export enum RUNTIME_ENTITY {
  /** Maps to {@link JOURNEY_PURPOSE.SELL_360} */
  ORDER = 'ORDER',
  /** Maps to {@link JOURNEY_PURPOSE.GENERAL_REQUESTS} */
  OPPORTUNITY = 'OPPORTUNITY'
}

// TODO: List all blocks that we currently support
export enum BLOCK_TYPE {
  GROUP = 'group',

  TEXT_INPUT = 'text-input',
  NUMBER_INPUT = 'number-input',
  DATE_INPUT = 'date-input',
  BINARY_INPUT = 'binary-input',
  SINGLE_CHOICE = 'single-choice',
  MULTIPLE_CHOICE = 'multiple-choice',

  PERSONAL_INFORMATION = 'personal-information',
  ADDRESS = 'address',
  SIGNATURE = 'signature',
  FILE_UPLOAD = 'file-upload',
  AVAILABILITY_CHECK = 'availability-check',

  PRODUCT = 'product',
  PRODUCT_CATEGORIES = 'product-categories',
  PAYMENT_METHOD = 'payment-method',
  SHOPPING_CART = 'shopping-cart',
  CALCULATOR = 'calculator',

  IMAGE = 'image',
  PARAGRAPH = 'paragraph',
  ACTION_BAR = 'action-bar',
  HYPERLINK = 'hyperlink',
  CONSENTS = 'consents',
  SUMMARY = 'summary',
  SUCCESS_CONFIRMATION = 'success-confirmation',

  CUSTOM = 'custom',
  PREVIOUS_PROVIDER = 'previous-provider',
  PV_ROOFTOP = 'pv-rooftop',
  METER_READING = 'meter-reading',
  ENTITY_FINDER = 'entity-finder',
  ATTRIBUTE_EDITOR = 'attribute-editor'
}

export enum REFERENCED_TYPE {
  STEP = 'step',
  BLOCK = 'block',
  /** NOTE: Currently not used, but proposed for future use in Logics */
  CONTEXT_PARAMETER = 'context-parameter'
}

export enum CONTEXT_PARAMETER_TYPE {
  TEXT = 'text',
  ACCOUNT = 'account-entity',
  CONTACT = 'contact-entity',
  CONTRACT = 'contract-entity',
  OPPORTUNITY = 'opportunity-entity',
  ORDER = 'order-entity',
  REQUEST = 'request'
}

export enum LANGUAGE {
  DE = 'de',
  FR = 'fr',
  EN = 'en'
}

export enum LogicAction {
  /**
   * Jumps to a specific Step
   *
   * Allows the user to specify to which Step they should jump to. Also allows the user
   * to control WHEN this should happen.
   */
  JUMP_TO_STEP = 'jump-to-step',
  /**
   * Skips a Step
   *
   * Allows skipping one or multiple Steps, regardless of their order.
   */
  SKIP_STEP = 'skip-step',
  /**
   * Determines whether a block should be displayed
   *
   * Allows the user to control the visibility of a given block, even if
   * that block was marked as required, effectively negating that restriction.
   */
  BLOCK_VISIBILITY = 'block-visibility',
  /**
   * Allows injecting data from one block to another.
   *
   * Depending on the case, it might also transform data and disable fields on the
   * destination block.
   */
  DATA_INJECTION = 'data-injection'
}

export enum LogicOperator {
  EXISTS = 'exists',
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  GREATER_THAN = 'greater-than',
  LESS_THAN = 'less-than',
  GREATER_THAN_OR_EQUAL = 'greater-than-or-equal',
  LESS_THAN_OR_EQUAL = 'less-than-or-equal',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not-contains',
  STARTS_WITH = 'starts-with',
  ENDS_WITH = 'ends-with',
  IS_EMPTY = 'is-empty',
  IS_NOT_EMPTY = 'is-not-empty'
}

export enum LAYOUT_TYPE {
  STACK = 'stack',
  TWO_COLUMN = 'two-column'
}

export enum JOURNEY_SAVING_PROGRESS_MODE {
  AUTO = 'auto',
  NONE = 'none',
  /** @deprecated not yet implemented */
  LOCAL = 'local',
  /** @deprecated not yet implemented */
  REMOTE = 'remote'
}
