import { isCompositePrice } from '@epilot/journey-logic-commons'
import type {
  ChartType,
  Price,
  PriceWithBlockMappings
} from '@epilot/journey-logic-commons'
import { PricingModel } from '@epilot/pricing'
import type Highcharts from 'highcharts'
import { type TFunction } from 'i18next'
import { unique } from 'radashi'

type ChartResolution = 'monthly' | 'daily' | 'hourly'

type ChartProps = {
  resolution: ChartResolution
  data: { date: number; price: number }[]
  t: TFunction
}

export const isRealtimePrice = (price: PriceWithBlockMappings) => {
  return [price, ...price.price_components].some(
    (price) =>
      price.pricing_model === PricingModel.dynamicTariff &&
      price.dynamic_tariff?.mode === 'day_ahead_market' &&
      price.dynamic_tariff.interval === 'hourly'
  )
}

export const getAvailableChartsFromPrice = (price: PriceWithBlockMappings) => {
  if (isCompositePrice(price)) {
    return unique(
      ((price.price_components ?? []) as Price[]).flatMap(getCharts)
    )
  }

  return getCharts(price)
}

const getCharts = (price: Price): ChartType[] => {
  if (price.pricing_model === PricingModel.dynamicTariff) {
    if (
      (price.dynamic_tariff?.mode === 'day_ahead_market' &&
        price.dynamic_tariff.interval === 'hourly') ||
      price.dynamic_tariff?.mode === 'manual'
    ) {
      return ['last_12_months', 'last_30_days', 'today']
    } else {
      return ['last_12_months']
    }
  }

  return []
}

export const buildChartOptions = (
  availableCharts: ChartType[],
  data: Record<ChartType, { date: number; price: number }[]>,
  t: TFunction
): Record<
  ChartType,
  { label: string; short_label?: string; options: Highcharts.Options }
> => {
  const options: Record<
    ChartType,
    { label: string; short_label?: string; options: Highcharts.Options }
  > = {
    previous_year: {
      label: t('price_chart.labels.previous_year', 'Previous Year'),
      short_label: t('price_chart.labels.previous_year_short', 'Year'),
      options: chartOptions({
        resolution: 'monthly',
        data: data.previous_year,
        t
      })
    },
    last_12_months: {
      label: t('price_chart.labels.last_12_month', 'Last 12 Months'),
      short_label: t('price_chart.labels.last_12_month_short', '12 Months'),
      options: chartOptions({
        resolution: 'monthly',
        data: data.previous_year,
        t
      })
    },
    previous_month: {
      label: t('price_chart.labels.previous_month', 'Previous Month'),
      short_label: t('price_chart.labels.previous_month_short', 'Month'),
      options: chartOptions({
        resolution: 'daily',
        data: data.previous_month,
        t
      })
    },
    last_30_days: {
      label: t('price_chart.labels.last_30_days', 'Last 30 Days'),
      short_label: t('price_chart.labels.last_30_days_short', '30 Days'),
      options: chartOptions({
        resolution: 'daily',
        data: data.previous_month,
        t
      })
    },
    yesterday: {
      label: t('price_chart.labels.yesterday', 'Yesterday'),
      options: chartOptions({
        resolution: 'hourly',
        data: data.yesterday,
        t
      })
    },
    today: {
      label: t('price_chart.labels.today', 'Today'),
      options: chartOptions({
        resolution: 'hourly',
        data: data.today,
        t
      })
    }
  }

  return availableCharts.reduce(
    (acc: Record<ChartType, any>, key) => {
      acc[key] = options[key]

      return acc
    },
    {} as Record<ChartType, any>
  )
}

const baseOptions = (
  average: number,
  isHourly: boolean
): Highcharts.Options => ({
  colors: ['var(--concorde-primary-color)', 'var(--concorde-secondary-color)'],
  chart: {
    style: {
      background: 'var(--concorde-surface-background)',
      fontFamily: 'var(--concorde-font-family)',
      fontSize: 'var(--concorde-text-base)'
    },
    animation: {
      duration: 500
    },
    spacingBottom: 20
  },
  title: {
    text: ''
  },
  credits: {
    text: 'Bundesnetzagentur | SMARD.de',
    href: '',
    style: {
      cursor: 'default'
    },
    position: {
      align: 'center',
      y: 0
    }
  },
  xAxis: {
    crosshair: false,
    type: 'datetime',
    dateTimeLabelFormats: {
      day: isHourly ? '%[HM]' : '%[eb]'
    }
  },
  yAxis: {
    labels: {
      format: '{value}'
    },
    title: {
      text: 'ct/kWh'
    },
    plotLines: [
      {
        width: 2,
        value: average,
        dashStyle: 'Dot',
        zIndex: 5
      }
    ]
  },
  plotOptions: {
    line: {
      step: 'left'
    },
    series: {
      marker: {
        enabled: false
      },
      animation: {
        duration: 1000
      }
    }
  },
  legend: {
    enabled: false
  }
})

const chartOptions = ({
  resolution,
  data,
  t
}: ChartProps): Highcharts.Options => {
  const average = data.reduce((acc, { price }) => acc + price, 0) / data.length

  return {
    ...baseOptions(average, resolution === 'hourly'),
    series: [
      {
        type: resolution === 'monthly' ? 'column' : 'line',
        name:
          resolution !== 'hourly'
            ? t('price_chart.average_marketprice', 'Average Market Price')
            : t('price_chart.marketprice', 'Market Price'),
        data: data.map((entry) => [entry.date, entry.price]),
        tooltip: {
          valueSuffix: ' ct/kWh',
          valueDecimals: 3
        }
      }
    ]
  }
}
