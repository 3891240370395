import type { Step } from '../utils'
import { isLayoutUiSchema, isNestedUiSchema } from '../utils'

/**
 * WARNING: Expensive operation, use it wisely and cache the end-result as much as you can
 *
 * TODO: Infer off of new data structure, which shouldn't be expensive
 */
export const isLauncherJourney = (steps: Step[] = []): boolean => {
  const uiSchemaElements = steps?.flatMap((step) => {
    const uischema = step.uischema

    if (isNestedUiSchema(uischema)) {
      return uischema?.elements?.flatMap((element) => {
        return element
      })
    } else if (isLayoutUiSchema(uischema)) {
      return uischema.elements
    } else {
      return uischema
    }
  })

  const isLauncher = uiSchemaElements.some(
    (element) => element?.type === 'JourneyLauncherControl'
  )

  return isLauncher
}
