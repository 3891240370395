import type { RendererReturnValue, UiSchema } from './sharedTypes'

export type InputCalculatorOptionDevice = {
  readonly value: string
  readonly factor?: InputCalculatorFactor
  readonly id?: string
  readonly preSelect?: boolean
}

export type InputCalculatorFactor = {
  readonly preset?: FactorPreset
  readonly custom_mappings?: FactorPresetValues[]
}

export enum FactorPreset {
  Gas = 'gas',
  Power = 'power',
  WaterHeater = 'water_heater',
  Custom = 'custom'
}

export type FactorPresetValues = {
  readonly quantity: number
  readonly unitaryDemand?: number
  readonly multiplicationFactor?: number
}

export type HydratedDeviceOption = {
  name: string
  type: string | undefined
  factorMap: FactorPresetValues[] | undefined
}

export type InputCalculatorFormProps = InputCalculatorControlOptions & {
  path: string
  data: InputCalculatorControlData | undefined
  hasError: boolean
  uischema: UiSchema
  isRequired: boolean
  handleChange: (
    path: string,
    value: RendererReturnValue<InputCalculatorFormValues>
  ) => void
  id: string
}

export type InputCalculatorFormValues = {
  numberInput?: string
  numberUnit?: string
  frequencyUnit?: string
  devices?: InputCalculatorDevice[]
  _isValid?: boolean
}

/** @see {isInputCalculatorControlOptions} ts-auto-guard:type-guard */
export type InputCalculatorControlOptions = {
  deviceUnit?: string
  deviceOptions?: InputCalculatorOptionDevice[]
  frequencyUnit?: string
  required?: boolean
  allowOther?: boolean
  digitsAfterDecimalPoint?: number
}

/** @see {isInputCalculatorControlData} ts-auto-guard:type-guard */
export type InputCalculatorControlData =
  | InputCalculatorFormValues
  | undefined
  | null

export type InputCalculatorDevice = {
  name: string | null
  otherName?: string | null
  quantity: string
  unitaryConsumption: string
  consumption?: string
}
