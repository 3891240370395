import { IS_LOCAL } from './config'

/**
 * Determines if debug is enabled based on the environment or URL params.
 */
export const isDebugEnabled = (): boolean => {
  return (
    IS_LOCAL ||
    new URLSearchParams(window.location.search).get('debug') === 'true'
  )
}

/**
 * Logs a debug message if debugging is enabled, otherwise does nothing.
 */
export const debug = (...args: Parameters<typeof console.debug>) => {
  if (isDebugEnabled()) {
    // eslint-disable-next-line no-console
    console.debug(...args)
  }
}
