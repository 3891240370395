import type { Client, Components } from '@epilot/customer-portal-client'
import { getClient } from '@epilot/customer-portal-client'
import { journeyStorage } from '@epilot/journey-logic-commons'
import type { AxiosRequestHeaders } from 'axios'

import { getPortalToken } from '../../utils/hooks/usePortalCheck'

import type { ExtendedCustomerPortalClient } from './types'

export type ContactEntity = Components.Schemas.Contact

let cachedClient: ExtendedCustomerPortalClient

export const getCustomerPortalClient = (apiUrl: string) => {
  if (!cachedClient) {
    cachedClient = configureClient(getClient(), apiUrl)
  }

  return cachedClient
}

const configureClient = (client: Client, apiUrl: string) => {
  client.defaults.baseURL = apiUrl

  const handlers = (client.interceptors.request as any)['handlers']

  if (handlers.length > 3) {
    // eslint-disable-next-line no-console
    console.error('Refused to add more interceptors to the Entity client', {
      baseURL: client.defaults.baseURL,
      interceptors: client.interceptors.request
    })

    throw new Error('Implementation error: Too many interceptors')
  }

  client.interceptors.request.use(async (request) => {
    const token = await getPortalToken()
    const portalId = journeyStorage.getItem('PORTAL_ID_LS_KEY')

    const isNotConfigRequest = !request.url?.endsWith('/public/config')

    if (!request.headers) {
      request.headers = {} as AxiosRequestHeaders
    }

    if (portalId && isNotConfigRequest) {
      request.headers['x-portal-id'] = portalId
    }

    if (token) {
      request.headers['authorization'] = `Bearer ${token}`
    }

    return request
  })

  const extendedClient = client as ExtendedCustomerPortalClient

  /**
   * This is a hack to normalize the API between this client and the Entity client.
   * We shouldn't be doing that, but rather handle this "complexity" explicitly in the code.
   *
   * TODO: Remove this unnecessary extension
   */
  extendedClient.searchEntities = client.searchPortalUserEntities

  return extendedClient
}
