import { CircularProgress, IconButton, Tooltip } from '@epilot/journey-elements'

import { MaterialSymbol } from '../../blocks-renderers'

import type { UserSessionIconMUIProps } from './types'

export function UserSessionIconMUI({
  iconColor,
  tooltipLabel,
  showProgress
}: UserSessionIconMUIProps) {
  return (
    <Tooltip title={tooltipLabel}>
      <span>
        <IconButton disabled style={{ color: iconColor }}>
          {showProgress ? (
            <CircularProgress size={12} />
          ) : (
            <MaterialSymbol color="default" name="cloud" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  )
}
