import { env as envConfig } from './env'

// TODO: Improve types to infer return type based on the env name
export const env = <R = string>(name: keyof typeof envConfig, fallback?: R) => {
  return (envConfig[name] ?? fallback) as unknown as R
}

export const VERSION = env('VITE_ENV_VERSION', 'local')

/** Which STAGE we're currently running in */
export const STAGE = env('REACT_APP_STAGE')

/** Whether we're running in a local environment */
export const IS_LOCAL = STAGE === 'local'

/** @deprecated seems not to be used, should be removed if confirmed */
export const RULE_SOURCE_SEPARATOR = '/'

/** When will the desktop stepper switch into mobile one */
export const STEPPER_DESKTOP_LIMIT = 8

/** Datadog id and client token */
export const DATADOG_APPLICATION_ID = 'df1ff5a4-412d-4da3-b4d6-99b2f797fc41'
export const DATADOG_CLIENT_TOKEN = 'pubcbfbb4b2a92ed722ad8c1e40b2880553'

export const isLocalOriginEventAllowed = (origin: string) => {
  return (
    origin === 'http://localhost:9000' &&
    ['dev', 'local'].includes(env('REACT_APP_STAGE'))
  )
}

/**
 * Measures the time spent between the initial execution of the App bundle
 * and the first time the Journey App renders a Step content to the end-user.
 *
 * This is meant to track how much time the user is waiting for that.
 */
export const TIME_TO_FIRST_RENDER_METRIC = 'timeToFirstRender'
