export enum JourneyFeatureFlags {
  DIGITAL_SIGNATURE = 'journey-digital-signature-block',
  JOURNEY_DATE_REWORK = 'journey-date-rework',
  JOURNEY_ANALYTICS = 'journey-analytics',
  PRODUCT_NUMBER_INJECTION = 'journey-product-number-injection',
  JOURNEY_CONTEXT_MENTON_FF = 'journey-context-mention',
  ACTIVE_JOURNEY = 'journey-publish',
  CONCORDE_USER_SUMMARY = 'journey-concorde-user-summary',
  CONCORDE_PI_BLOCK = 'journey-pi-block',
  CONCORDE_AVAILABILITY_CHECK = 'journey-availability-check-block',
  CONCORDE_ADDRESS_BLOCK = 'journey-concorde-address-block',
  CONCORDE_NAVIGATOR = 'journey-pv-rooftop-navigator',
  CONCORDE_BOOLEAN = 'journey-boolean-input',
  USER_PROGRESS = 'journey-user-progress',
  CONCORDE_NUMBER_INPUT = 'journey-number-input-block',
  CONCORDE_SINGLE_CHOICE = 'journey-single-choice-block',
  CONCORDE_MULTIPLE_CHOICE = 'journey-multiple-choice-block',
  CONCORDE_PREVIOUS_PROVIDER = 'journey-concorde-previous-provider',
  CONCORDE_PAYMENT_METHOD = 'journey-concorde-payment-method',
  CONCORDE_PRODUCT_CATEGORIES = 'journey-concorde-product-categories',
  ANIMATION_EFFECTS = 'journey-animation-effects',
  INCLUDE_PRODUCT_BLOCKS_IN_SUMMARY = 'journey-builder-product-blocks-in-summary',
  DYNAMIC_TARIFFS = 'journey-market-data-chart',
  CONCORDE_SHOPPING_CART = 'journey-concorde-shopping-cart',
  CONCORDE_TRANSPARENT_PRODUCT_TILE = 'journey-transparent-product-tile',
  CONCORDE_UPLOAD_BLOCK = 'journey-upload-block',
  CONCORDE_LAYOUTS = 'journey-layouts-without-mui',
  CONCORDE_INPUT_CALCULATOR = 'journey-input-calculator',
  CONCORDE_PDF_SUMMARY = 'journey-pdf-summary',
  CUSTOM_CSS = 'journey-custom-css'
}
