import { NotFoundError } from '@epilot/journey-elements'
import { memo, type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

type Props = Omit<ComponentProps<typeof NotFoundError>, 'i18n'>

export const ExpiredJourneyTokenError = memo((props: Props) => {
  const { t } = useTranslation()

  return (
    <NotFoundError
      i18n={{
        heading: t('This link has expired.'),
        content: t('Please contact support for a new link.'),
        close: t('Close')
      }}
      {...props}
    />
  )
})

ExpiredJourneyTokenError.displayName = 'ExpiredJourneyTokenError'
