import {
  JOURNEY_EMBED_MODE,
  LANGUAGE
} from '@epilot/journey-logic-commons/src/types/next'

/**
 * This function parses a keyed map of parameters extracing UUIDs or the actual values
 * for each entry.
 *
 * This is helpful to support "broken" UUIDs in Journey App params or context parameters.
 *
 * See {@link parseUuidOrValue} for more details.
 */
export const parseUuidOrValueParams = <
  T extends Record<string, unknown> = Record<string, unknown>,
  P extends Record<string, unknown> = Record<string, unknown>
>(
  params: P
): T => {
  const sanitizedParams: T = {} as T

  for (const key in params) {
    ;(sanitizedParams as Record<string, unknown>)[key] = parseUuidOrValue(
      params[key]
    )
  }

  return sanitizedParams
}

/**
 * This function extracts UUIDs from a given object of parameters.
 * Example: data-123e4567-e89b-12d3-a456-426614174000-extra --> 123e4567-e89b-12d3-a456-426614174000
 */
export const parseUuidOrValue = <T = string | unknown>(value: T) => {
  if (typeof value !== 'string') return value

  const match = value.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
  )

  // If a UUID is found, use it; otherwise, use the original value
  return match ? match[0] : value
}

export const parseBoolean = (value: unknown, fallback = undefined) => {
  return value === 'true' ? true : value === 'false' ? false : fallback
}

export const parseMode = (value: unknown, fallback: any = undefined) => {
  if (Object.values(JOURNEY_EMBED_MODE).includes(value as never)) {
    return value as JOURNEY_EMBED_MODE
  }

  return fallback
}

export const parseLanguage = (value: unknown, fallback: any = undefined) => {
  if (Object.values(LANGUAGE).includes(value as never)) {
    return value as LANGUAGE
  }

  return fallback
}
