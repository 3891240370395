import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const usePriceHistoryChartStyles = makeStyles<Theme & EpilotTheme>(
  (theme) => {
    return createStyles({
      chartContainer: {
        width: '100%',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: `min(${theme.shape?.borderRadius}px, 20px)`
      },
      chartButtonGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(3) // using theme spacing for consistency
      },
      chartPlaceholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '444px'
      }
    })
  }
)
