import { isEmpty } from 'radashi'

export const FALLBACK_KEY = 'journey_data_fallback'

export const shadowFormEventListener = (
  e: CustomEvent<{ stepIndex: number; newState: Record<string, unknown> }>
) => {
  const { stepIndex, newState } = e.detail

  try {
    if (!window[FALLBACK_KEY]) {
      window[FALLBACK_KEY] = {}
    }

    for (const [blockName, value] of Object.entries(newState)) {
      if (
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        !isEmpty(value)
      ) {
        const inputName = `step-${stepIndex}-${blockName}`

        window[FALLBACK_KEY][inputName] = structuredClone(value)
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      new Error(`Could not update shadow form data: ${JSON.stringify(error)}`)
    )
  }
}

export const createShadowFormElement = () => {
  window.addEventListener(
    'journey-state-update',
    shadowFormEventListener as EventListener
  )
}

export const getShadowFormDataJSON = (submissionPayload: unknown) => {
  try {
    const value = JSON.stringify({
      ...window[FALLBACK_KEY],
      referrer: document.referrer,
      submissionPayload
    })

    window[FALLBACK_KEY] = {}

    return value
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      new Error(`Could not get shadow form data: ${JSON.stringify(error)}`)
    )

    return '{}'
  }
}
