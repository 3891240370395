import { Tooltip } from '@epilot/concorde-elements'
import { CircularProgress, IconButton } from '@epilot/journey-elements'

import { MaterialSymbol } from '../../blocks-renderers'

import type { UserSessionIconConcordeProps } from './types'
import classes from './UserSessionIconConcorde.module.scss'

export function UserSessionIconConcorde({
  iconColor,
  tooltipLabel,
  showProgress
}: UserSessionIconConcordeProps) {
  return (
    <Tooltip className={classes.tooltip} title={tooltipLabel}>
      <span>
        <IconButton disabled style={{ color: iconColor }}>
          {showProgress ? (
            <CircularProgress size={12} />
          ) : (
            <MaterialSymbol color="default" name="cloud" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  )
}
