import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  createStyles,
  Grid,
  makeStyles
} from '@epilot/journey-elements'
import { type CashbackAmount, formatPrice } from '@epilot/journey-logic-commons'
import type { Currency } from '@epilot/pricing'
import { useTranslation } from 'react-i18next'

const useProductDetailsCashbackStyles = makeStyles<Theme>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    cashbackValue: {
      textAlign: 'right'
    }
  })
)

type ProductDetailsCashbackProps = {
  cashback: CashbackAmount
  currency: Currency
  showTrailingDecimalZeros?: boolean
}

export const ProductDetailsCashback = ({
  cashback,
  currency,
  showTrailingDecimalZeros
}: ProductDetailsCashbackProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const classes = useProductDetailsCashbackStyles()

  if (
    typeof cashback.amount_total === 'undefined' ||
    !cashback.cashback_period
  ) {
    return null
  }

  const formattedPrice = formatPrice(
    -cashback.amount_total,
    currency,
    showTrailingDecimalZeros
  )

  const formattedCashbackPeriod = t(
    `cashback_period.${cashback.cashback_period}`
  )

  return (
    <div className={classes.wrapper}>
      <Grid item xs={8}>
        <BodyText color="textPrimary">
          <strong>{t('coupon.cashback')}</strong>
        </BodyText>
      </Grid>
      <Grid className="flex" item justifyContent="flex-end" xs={4}>
        <BodyText className={classes.cashbackValue} color="textPrimary">
          <strong>
            {formattedPrice} {formattedCashbackPeriod}
          </strong>
        </BodyText>
      </Grid>
    </div>
  )
}
