import type { ThemeOptions } from '@material-ui/core'
import { darken, lighten } from '@material-ui/core/styles'
import deepmerge from 'deepmerge'

import { DefaultEpilotTheme } from './DefaultEpilotTheme'
import fonts from './fonts'
import type { EpilotPalette, EpilotTheme } from './types'

export function parseThemeTemplate(params: EpilotTheme) {
  return {
    contentMaxWidth: `${
      params.props?.content_max_width ||
      DefaultEpilotTheme.props?.content_max_width
    }px`,
    clientLogoUrl:
      params.props?.client_logo_url ||
      DefaultEpilotTheme.props?.client_logo_url,
    clientLogoStyle: {
      height: '32px'
    }
  }
}

export function parseMuiTheme(
  params: EpilotTheme,
  classPrefix = ''
): ThemeOptions {
  const prefix = classPrefix ? `${classPrefix}-` : ''

  const disableRipple =
    typeof params.props?.enable_ripple === 'boolean'
      ? !params.props?.enable_ripple
      : !DefaultEpilotTheme.props?.enable_ripple

  const borderRadius =
    params.shape?.borderRadius !== undefined
      ? params.shape?.borderRadius
      : DefaultEpilotTheme.shape?.borderRadius

  const primaryColor =
    params.palette?.primary || DefaultEpilotTheme.palette?.primary

  const secondaryColor =
    params.palette?.secondary || DefaultEpilotTheme.palette?.secondary

  const successColor =
    params.palette?.success || DefaultEpilotTheme.palette?.success

  const infoColor = params.palette?.info || DefaultEpilotTheme.palette?.info

  const defaultBackground =
    params.palette?.background?.default ||
    DefaultEpilotTheme.palette?.background?.default

  const warningColor =
    params.palette?.warning || DefaultEpilotTheme.palette?.warning

  const primaryDark = primaryColor?.dark
    ? primaryColor?.dark
    : primaryColor?.main
      ? darken(primaryColor?.main, 0.1)
      : null
  const secondaryDark = secondaryColor?.dark
    ? secondaryColor?.dark
    : secondaryColor?.main
      ? darken(secondaryColor?.main, 0.1)
      : null
  const primaryLight = primaryColor?.light
    ? primaryColor?.light
    : primaryColor?.main
      ? lighten(primaryColor?.main, 0.1)
      : null
  const secondaryLight = secondaryColor?.light
    ? secondaryColor?.light
    : secondaryColor?.main
      ? lighten(secondaryColor?.main, 0.1)
      : null
  const errorColor = params.palette?.error || DefaultEpilotTheme.palette?.error

  const accent1Color =
    params.palette?.accent1 || DefaultEpilotTheme.palette?.accent1

  const accent2Color =
    params.palette?.accent2 || DefaultEpilotTheme.palette?.accent2

  const tertiaryColor =
    params.palette?.tertiary || DefaultEpilotTheme.palette?.tertiary

  const defaultColor =
    params.palette?.default || DefaultEpilotTheme.palette?.default

  const grey20 =
    params.palette?.grey?.[20] || DefaultEpilotTheme.palette?.grey?.[20]
  const grey30 =
    params.palette?.grey?.[30] || DefaultEpilotTheme.palette?.grey?.[30]
  const grey40 =
    params.palette?.grey?.[40] || DefaultEpilotTheme.palette?.grey?.[40]
  const grey50 =
    params.palette?.grey?.[50] || DefaultEpilotTheme.palette?.grey?.[50]
  const grey60 =
    params.palette?.grey?.[60] || DefaultEpilotTheme.palette?.grey?.[60]
  const grey70 =
    params.palette?.grey?.[70] || DefaultEpilotTheme.palette?.grey?.[70]
  const grey100 =
    params.palette?.grey?.[100] || DefaultEpilotTheme.palette?.grey?.[100]
  const grey200 =
    params.palette?.grey?.[200] || DefaultEpilotTheme.palette?.grey?.[200]

  const grey500 =
    params.palette?.grey?.[500] || DefaultEpilotTheme.palette?.grey?.[500]

  const textPalette = params.palette?.text || DefaultEpilotTheme.palette?.text

  const actionHoverColor =
    params.palette?.action?.hover || DefaultEpilotTheme.palette?.action?.hover

  const dividerColor =
    params.palette?.divider || DefaultEpilotTheme.palette?.divider

  const extFonts = params.typography?.fontSource
    ? fonts.concat(params.typography?.fontSource)
    : fonts

  const elevation1 = params.shadows?.[1] || DefaultEpilotTheme.shadows?.[1]

  const elevation2 = params.shadows?.[2] || DefaultEpilotTheme.shadows?.[2]

  const elevation3 = params.shadows?.[3] || DefaultEpilotTheme.shadows?.[3]

  const elevation8 = params.shadows?.[8] || DefaultEpilotTheme.shadows?.[8]

  const overrides = deepmerge(
    {
      MuiTypography: {
        root: {
          textTransform: 'none'
        }
      },
      MuiInputBase: {
        input: {
          height: 'auto',
          borderRadius: 'inherit',

          '&::placeholder': {
            color: grey200,
            opacity: 1
          },

          '&$disabled': {
            '&::placeholder': {
              color: grey100
            }
          }
        }
      },
      MuiInputAdornment: {
        root: {
          [`& .${prefix}MuiIconButton-root`]: {
            padding: 0
          }
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `1px solid ${grey60}`
          },

          '&:after': {
            borderBottom: `1px solid ${primaryColor?.[400]}`
          },

          '&:hover:not(.Mui-disabled)': {
            '&:before': {
              borderBottom: `1px solid ${grey60}`
            }
          },

          '&$disabled': {
            '&:before': {
              borderBottomStyle: 'solid'
            }
          }
        }
      },
      MuiSelect: {
        root: {
          textAlign: 'start'
        },
        icon: {
          color: secondaryColor?.main
        },
        select: {
          '&:focus': {
            borderRadius: 'inherit'
          }
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: '1px'
        }
      },
      MuiInputLabel: {
        root: {
          color: `${grey500} !important`,
          '&$disabled': {
            color: `${grey100} !important`
          }
        },
        outlined: {
          display: 'none'
        }
      },
      MuiPaper: {
        elevation1: {
          boxShadow: elevation1
        },
        elevation2: {
          boxShadow: elevation2
        },
        elevation3: {
          boxShadow: elevation3
        },
        // Elevation8 affects Popover default boxShadow
        elevation8: {
          boxShadow: elevation8
        }
      },
      MuiChip: {
        root: {
          borderRadius: 2,
          fontSize: 12,
          fontWeight: 600,
          height: 24,
          backgroundColor: grey50,
          lineHeight: 'normal',
          color: textPalette?.primary,
          transition:
            'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,filter 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        colorPrimary: {
          color: 'white'
        },
        colorSecondary: {
          color: 'white'
        },
        outlined: {
          backgroundColor: 'transparent',
          '&:hover': {
            border: `1px solid ${primaryColor?.main}`
          },
          [`& .${prefix}MuiIcon-root, .${prefix}MuiSvgIcon-root`]: {
            color: secondaryColor?.main
          }
        },
        clickable: {
          '&:hover, &:active, &:focus': {
            backgroundColor: grey50,
            filter: 'brightness(92%)'
          }
        },
        clickableColorPrimary: {
          '&:hover, &:active, &:focus': {
            background: primaryColor,
            filter: 'brightness(92%)'
          }
        },
        clickableColorSecondary: {
          '&:hover, &:active, &:focus': {
            background: secondaryColor,
            filter: 'brightness(92%)'
          }
        },
        deletable: {
          '&:hover, &:active, &:focus': {
            backgroundColor: grey50
          }
        },
        icon: {
          fontSize: '1.2rem !important'
        },
        deleteIcon: {
          fontSize: '1.2rem !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      MuiAccordionDetails: {
        root: {
          backgroundColor: grey20,
          borderTop: `1px solid ${grey50}`
        }
      },
      MuiAccordionSummary: {
        root: {
          color: textPalette?.primary,
          fontSize: 14,
          fontWeight: 600,
          minHeight: 48,
          '&$expanded': {
            minHeight: 48
          }
        },
        content: {
          '&$expanded': {
            margin: 'auto'
          }
        },
        expanded: {}
      },
      MuiAccordion: {
        root: {
          marginTop: '-1px !important',
          '&$expanded': {
            margin: 'auto',
            '&:before': {
              top: -1,
              left: 0,
              right: 0,
              height: 1,
              content: '',
              opacity: 1,
              position: 'absolute',
              transition:
                'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              backgroundColor: grey50
            }
          },
          '&.Mui-disabled': {
            backgroundColor: grey40
          }
        }
      },
      MuiRadio: {
        colorPrimary: {
          '&:hover': {
            color: primaryColor?.main,
            backgroundColor: 'transparent'
          }
        },
        colorSecondary: {
          '&:hover': {
            color: accent1Color?.main,
            backgroundColor: 'transparent'
          }
        }
      },
      MuiCheckbox: {
        colorPrimary: {
          '&:hover': {
            color: primaryColor?.main,
            backgroundColor: 'transparent'
          }
        },
        colorSecondary: {
          '&:hover': {
            color: accent1Color?.main,
            backgroundColor: 'transparent'
          }
        }
      },
      MuiTabPanel: {
        root: {
          padding: 0,
          [`& .${prefix}MuiPaper-root`]: {
            borderRadius: `0px ${borderRadius}px ${borderRadius}px ${borderRadius}px`
          }
        }
      },
      MuiTabs: {
        root: {
          minHeight: 40,
          overflow: 'visible'
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid ${grey60}`,
          whiteSpace: 'pre',
          height: 48
        },
        head: {
          fontWeight: 600,
          height: 56,
          border: 0
        },
        stickyHeader: {
          fontWeight: 600,
          backgroundColor: grey30,
          left: 'unset'
        }
      },
      MuiTableSortLabel: {
        root: {
          '&:hover': {
            color: 'inherit'
          }
        }
      },
      MuiTableRow: {
        root: {
          '&$selected': {
            backgroundColor: `${grey20} !important`
          }
        },
        hover: {
          '&:hover': {
            backgroundColor: `${grey20} !important`
          }
        }
      },
      MuiTableHead: {
        root: {
          backgroundColor: grey30
        }
      },
      MuiButton: {
        root: {
          fontWeight: 600,
          '&$disabled': {
            color: grey100
          },
          height: 32,
          fontSize: 14
        },
        sizeSmall: {
          height: 24,
          fontSize: 12
        },
        sizeLarge: {
          height: 48,
          fontSize: 16
        },
        contained: {
          '&$disabled': {
            color: grey100,
            backgroundColor: grey40
          }
        },
        text: {
          color: textPalette?.primary,

          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: primaryColor?.[600]
          },

          '&:active': {
            color: primaryColor?.[700]
          },

          [`&.${classPrefix}Mui-focusVisible`]: {
            border: `1px solid white`,
            boxShadow: `0 0 0 2px ${
              primaryColor?.[400] || primaryLight
            } !important`
          },

          [`&.${classPrefix}Mui-focusVisible.${classPrefix}MuiButton-textSizeSmall`]:
            {
              boxShadow: `0 0 0 1px ${
                primaryColor?.[400] || primaryLight
              } !important`
            }
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        outlined: {
          color: textPalette?.primary,
          backgroundColor: defaultBackground,
          borderColor: grey70,

          '&:active': {
            borderColor: primaryColor?.[400],
            boxShadow: `0px 0px 0px 1px ${primaryColor?.[400]} !important`
          },

          [`& .${prefix}MuiButton-startIcon`]: {
            color: accent1Color?.main
          },

          [`& .${prefix}MuiButton-endIcon`]: {
            color: accent1Color?.main
          },

          [`&.${classPrefix}Mui-focusVisible`]: {
            border: `1px solid white`,
            boxShadow: `0 0 0 2px ${
              primaryColor?.[400] || primaryLight
            } !important`
          },

          [`&.${classPrefix}Mui-focusVisible.${classPrefix}MuiButton-outlinedSizeSmall`]:
            {
              boxShadow: `0 0 0 1px ${
                primaryColor?.[400] || primaryLight
              } !important`
            }
        },
        outlinedPrimary: {
          color: textPalette?.primary,

          '&:hover': {
            border: `1px solid ${primaryDark}`,
            backgroundColor: defaultBackground
          },

          '&:active': {
            borderColor: primaryColor?.[400],
            boxShadow: `0px 0px 0px 1px ${primaryColor?.[400]} !important`
          },

          [`& .${prefix}MuiButton-startIcon`]: {
            color: textPalette?.primary
          },

          [`& .${prefix}MuiButton-endIcon`]: {
            color: textPalette?.primary
          }
        },
        outlinedSecondary: {
          color: textPalette?.primary,

          '&:hover': {
            border: `1px solid ${secondaryDark}`,
            backgroundColor: defaultBackground
          }
        },
        containedPrimary: {
          [`&.${classPrefix}Mui-focusVisible`]: {
            backgroundColor: `${primaryColor?.[400]} !important`,
            border: `1px solid white`,
            boxShadow: `0 0 0 2px ${
              primaryColor?.[400] || primaryLight
            } !important`
          },

          [`&.${classPrefix}Mui-focusVisible.${classPrefix}MuiButton-containedSizeSmall`]:
            {
              boxShadow: `0 0 0 1px ${
                primaryColor?.[400] || primaryLight
              } !important`
            }
        },
        containedSecondary: {
          [`&.${classPrefix}Mui-focusVisible`]: {
            backgroundColor: `${secondaryColor?.main} !important`,
            border: `1px solid white`,
            boxShadow: `0 0 0 2px ${
              secondaryColor?.[400] || secondaryLight
            } !important`
          },

          [`&.${classPrefix}Mui-focusVisible.${classPrefix}MuiButton-containedSizeSmall`]:
            {
              boxShadow: `0 0 0 1px ${
                secondaryColor?.[400] || secondaryLight
              } !important`
            }
        }
      },
      MuiButtonGroup: {
        groupedContainedPrimary: {
          '&:not(:last-child)': {
            borderColor: defaultBackground
          }
        },
        groupedOutlined: {
          '&:active': {
            zIndex: 1,
            borderColor: `${primaryColor?.[400]} !important`
          }
        },
        groupedTextPrimary: {
          border: `1px solid ${DefaultEpilotTheme.palette?.grey[70]} !important`,

          '&:not(:last-child)': {
            marginRight: -1
          }
        }
      },
      MuiDialogContentText: {
        root: {
          color: textPalette?.primary
        }
      },
      MuiDialogTitle: {
        root: {
          padding: 24,
          paddingBottom: 4,

          [`& .${prefix}MuiTypography-root`]: {
            fontWeight: 600
          }
        }
      },
      MuiDialogActions: {
        root: {
          padding: 24,
          paddingTop: 16
        }
      },
      MuiDialog: {
        paper: {
          boxShadow:
            '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)'
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(23, 43, 77 ,0.3)'
        }
      },
      MuiToggleButton: {
        root: {
          fontWeight: 600,
          backgroundColor: defaultBackground,
          color: textPalette?.primary,
          borderColor: `${grey70} !important`,

          '&:hover': {
            backgroundColor: defaultBackground
          },

          '&$selected': {
            backgroundColor: `${primaryColor?.main} !important`,
            borderColor: `${primaryColor?.main} !important`,
            borderRight: `1px solid ${primaryColor?.main} !important`,

            '&:hover': {
              backgroundColor: primaryColor?.main
            },

            [`& .${prefix}MuiToggleButton-label`]: {
              color: defaultBackground
            }
          },

          '&$disabled': {
            backgroundColor: grey40,

            [`& .${prefix}MuiToggleButton-label`]: {
              color: grey100
            }
          },
          height: 32,
          fontSize: 14
        },

        sizeSmall: {
          height: 24,
          fontSize: 12
        },
        sizeLarge: {
          height: 40,
          fontSize: 16
        }
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
          marginRight: 0
        }
      },
      MuiSwitch: {
        root: {
          width: 48,
          height: 24,
          padding: 0,
          display: 'flex',
          margin: 8
        },
        switchBase: {
          padding: 2,
          color: DefaultEpilotTheme?.palette?.background.default,

          '&:hover': {
            backgroundColor: 'inherit'
          },

          '&$checked': {
            transform: 'translateX(24px)',
            color: `${DefaultEpilotTheme?.palette?.background.default} !important`,

            '& + $track': {
              opacity: 1,
              borderColor: DefaultEpilotTheme?.palette?.action.disabled,
              backgroundColor: DefaultEpilotTheme?.palette?.action.disabled
            }
          }
        },
        colorPrimary: {
          '&$disabled': {
            color: `${DefaultEpilotTheme?.palette?.background.default} !important`,

            '& + $track': {
              opacity: 0.8,
              borderColor: DefaultEpilotTheme?.palette?.action.disabled,
              backgroundColor: DefaultEpilotTheme?.palette?.action.disabled
            },

            '&$checked': {
              '& + $track': {
                opacity: 0.5,
                borderColor: DefaultEpilotTheme?.palette?.primary.main,
                backgroundColor: DefaultEpilotTheme?.palette?.primary.main
              }
            }
          },

          '&$checked': {
            '& + $track': {
              borderColor: DefaultEpilotTheme?.palette?.primary.main
            }
          }
        },
        colorSecondary: {
          '&$disabled': {
            color: `${DefaultEpilotTheme?.palette?.background.default} !important`,

            '& + $track': {
              opacity: 0.8,
              borderColor: DefaultEpilotTheme?.palette?.action.disabled,
              backgroundColor: DefaultEpilotTheme?.palette?.action.disabled
            },

            '&$checked': {
              '& + $track': {
                opacity: 0.5,
                borderColor: DefaultEpilotTheme?.palette?.secondary.main,
                backgroundColor: DefaultEpilotTheme?.palette?.secondary.main
              }
            }
          },

          '&$checked': {
            '& + $track': {
              borderColor: DefaultEpilotTheme?.palette?.secondary.main
            }
          }
        },
        thumb: {
          width: 20,
          height: 20,
          boxShadow: 'none'
        },
        track: {
          border: `1px solid ${DefaultEpilotTheme?.palette?.action.disabled}`,
          borderRadius: 12,
          opacity: 1,
          backgroundColor: DefaultEpilotTheme?.palette?.action.disabled
        },
        sizeSmall: {
          width: 28,
          height: 16,
          padding: 0,

          [`& .${prefix}MuiSwitch-switchBase`]: {
            padding: '2px !important',

            '&$checked': {
              transform: 'translateX(12px) !important'
            },

            [`& .${prefix}MuiSwitch-thumb`]: {
              width: 12,
              height: 12
            }
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: primaryColor?.[800],
          padding: 8,
          fontSize: 14,
          lineHeight: '18px'
        },
        arrow: {
          color: primaryColor?.[800]
        }
      },
      MuiFilledInput: {
        root: {
          border: `1px solid ${grey70}`,
          backgroundColor: grey20,
          borderRadius: borderRadius,

          '&:hover': {
            backgroundColor: grey20
          },

          '&$focused': {
            backgroundColor: grey20,
            border: `1px solid ${primaryColor?.[400]}`
          },

          '&.Mui-error': {
            borderColor: errorColor?.main
          },

          '&$disabled': {
            backgroundColor: grey40
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: defaultBackground,

          '& input': {
            paddingTop: 10.5,
            paddingBottom: 10.5
          },

          [`& .${prefix}MuiOutlinedInput-notchedOutline`]: {
            borderColor: grey70
          },

          '&:hover': {
            backgroundColor: grey20,

            [`& .${prefix}MuiOutlinedInput-notchedOutline`]: {
              borderColor: grey70
            }
          },

          '&$focused': {
            backgroundColor: '#ffffff',

            [`& .${prefix}MuiOutlinedInput-notchedOutline`]: {
              borderColor: `${primaryColor?.[400]} !important`,
              borderWidth: 1
            }
          },

          '&$disabled': {
            backgroundColor: grey40,

            [`& .${prefix}MuiOutlinedInput-notchedOutline`]: {
              borderColor: grey70
            }
          }
        },
        inputMarginDense: {
          '&$input': {
            paddingTop: 6.5,
            paddingBottom: 6.5
          }
        },
        notchedOutline: {
          '& legend': {
            maxWidth: 0
          }
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          minHeight: 32
        },
        option: {
          '&:hover': {
            backgroundColor: actionHoverColor
          },
          "&[aria-selected='true']": {
            backgroundColor: actionHoverColor,
            color: `${primaryColor?.main} !important`
          }
        },
        paper: {
          boxShadow: elevation8
        }
      },
      MuiCssBaseline: {
        '@global': {
          '@font-face': extFonts,
          '.material-icons': {
            fontFamily: "'Material Icons'",
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '24px',
            lineHeight: '1',
            letterSpacing: 'normal',
            textTransform: 'none',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            wordWrap: 'normal',
            direction: 'ltr',
            webkitFontFeatureSettings: "'liga'",
            webkitFontSmoothing: 'antialiased'
          },
          '.Mui-selected': {
            color: primaryColor?.main,
            backgroundColor: `${actionHoverColor} !important`
          },
          '.Mui-focusVisible': {
            backgroundColor: `${defaultBackground} !important`
          },
          input: {
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }
        },

        ...params.muiOverrides?.MuiCssBaseline
      }
    },
    params.muiOverrides ?? {}
  )

  return {
    typography: {
      fontWeightRegular:
        params.typography?.fontWeightRegular ||
        DefaultEpilotTheme.typography?.fontWeightRegular,

      fontWeightBold:
        params.typography?.fontWeightBold ||
        DefaultEpilotTheme.typography?.fontWeightBold,

      fontSize:
        params.typography?.fontSize || DefaultEpilotTheme.typography?.fontSize,

      body1: params.typography?.body1 || DefaultEpilotTheme.typography?.body1,

      body2: params.typography?.body2 || DefaultEpilotTheme.typography?.body2,

      caption:
        params.typography?.caption || DefaultEpilotTheme.typography?.caption,

      subtitle1:
        params.typography?.subtitle1 ||
        DefaultEpilotTheme.typography?.subtitle1,

      subtitle2:
        params.typography?.subtitle2 ||
        DefaultEpilotTheme.typography?.subtitle2,

      fontFamily: `${
        params.typography?.fontFamily ||
        DefaultEpilotTheme.typography?.fontFamily
      } !important`,

      button:
        params.typography?.button || DefaultEpilotTheme.typography?.button,

      h1: params.typography?.h1 || DefaultEpilotTheme.typography?.h1,

      h2: params.typography?.h2 || DefaultEpilotTheme.typography?.h2,

      h3: params.typography?.h3 || DefaultEpilotTheme.typography?.h3,

      h4: params.typography?.h4 || DefaultEpilotTheme.typography?.h4,

      h5: params.typography?.h5 || DefaultEpilotTheme.typography?.h5,

      h6: params.typography?.h6 || DefaultEpilotTheme.typography?.h6
    },
    shadows: DefaultEpilotTheme.shadows,
    palette: {
      primary: {
        ...primaryColor,
        dark: primaryDark
      },
      secondary: {
        ...secondaryColor,
        dark: secondaryDark
      },
      success: {
        ...successColor,
        dark: successColor?.dark
          ? successColor?.dark
          : successColor?.main
            ? darken(successColor?.main, 0.1)
            : null
      },
      default: {
        ...defaultColor,
        dark: defaultColor?.dark
          ? defaultColor?.dark
          : defaultColor?.main
            ? darken(defaultColor?.main, 0.1)
            : null
      },
      info: {
        ...infoColor,
        dark: infoColor?.dark
          ? infoColor?.dark
          : infoColor?.main
            ? darken(infoColor?.main, 0.1)
            : null
      },

      warning: {
        ...warningColor,
        dark: warningColor?.dark
          ? warningColor?.dark
          : warningColor?.main
            ? darken(warningColor?.main, 0.1)
            : null
      },

      error: {
        ...errorColor,
        dark: errorColor?.dark
          ? errorColor?.dark
          : errorColor?.main
            ? darken(errorColor?.main, 0.1)
            : null
      },

      accent1: {
        ...accent1Color,
        dark: accent1Color?.dark
          ? accent1Color?.dark
          : accent1Color?.main
            ? darken(accent1Color?.main, 0.1)
            : null
      },

      accent2: {
        ...accent2Color,
        dark: accent2Color?.dark
          ? accent2Color?.dark
          : accent2Color?.main
            ? darken(accent2Color?.main, 0.1)
            : null
      },

      tertiary: {
        ...tertiaryColor,
        dark: tertiaryColor?.dark
          ? tertiaryColor?.dark
          : tertiaryColor?.main
            ? darken(tertiaryColor?.main, 0.1)
            : null
      },

      grey: {
        '10':
          params.palette?.grey?.[10] || DefaultEpilotTheme.palette?.grey?.[10],
        '20': grey20,
        '30': grey30,
        '40':
          params.palette?.grey?.[40] || DefaultEpilotTheme.palette?.grey?.[40],
        '50': grey50,
        '70': grey70,
        '100':
          params.palette?.grey?.[100] ||
          DefaultEpilotTheme.palette?.grey?.[100],
        '200':
          params.palette?.grey?.[200] ||
          DefaultEpilotTheme.palette?.grey?.[200],
        '300':
          params.palette?.grey?.[300] ||
          DefaultEpilotTheme.palette?.grey?.[300],
        '400':
          params.palette?.grey?.[400] ||
          DefaultEpilotTheme.palette?.grey?.[400],
        '500':
          params.palette?.grey?.[500] ||
          DefaultEpilotTheme.palette?.grey?.[500],
        '600':
          params.palette?.grey?.[600] ||
          DefaultEpilotTheme.palette?.grey?.[600],
        '700':
          params.palette?.grey?.[700] ||
          DefaultEpilotTheme.palette?.grey?.[700],
        '800':
          params.palette?.grey?.[800] ||
          DefaultEpilotTheme.palette?.grey?.[800],
        '900':
          params.palette?.grey?.[900] ||
          DefaultEpilotTheme.palette?.grey?.[900],
        A100:
          params.palette?.grey?.A100 || DefaultEpilotTheme.palette?.grey?.A100,
        A200:
          params.palette?.grey?.A200 || DefaultEpilotTheme.palette?.grey?.A200,
        A400:
          params.palette?.grey?.A400 || DefaultEpilotTheme.palette?.grey?.A400,
        A700:
          params.palette?.grey?.A700 || DefaultEpilotTheme.palette?.grey?.A700
      },

      action: {
        hover: actionHoverColor,
        selected:
          params.palette?.action?.selected ||
          DefaultEpilotTheme.palette?.action?.selected,
        disabled:
          params.palette?.action?.disabled ||
          DefaultEpilotTheme.palette?.action?.disabled,

        active:
          params.palette?.action?.active ||
          DefaultEpilotTheme.palette?.action?.active,

        focus:
          params.palette?.action?.focus ||
          DefaultEpilotTheme.palette?.action?.focus
      },

      background:
        params.palette?.background || DefaultEpilotTheme.palette?.background,

      text: {
        ...textPalette
      },

      divider: dividerColor
    } as EpilotPalette,

    overrides: overrides,

    shape: {
      borderRadius: borderRadius
    },

    // DEFAULT PROPS
    props: {
      MuiInputLabel: {
        shrink: true
      },
      MuiButtonGroup: {
        disableRipple: disableRipple,
        ...params.muiProps?.MuiButtonGroup
      },
      MuiButton: {
        disableElevation: true
      },
      MuiSwitch: {
        color: 'primary'
      },
      MuiButtonBase: {
        disableRipple: disableRipple
      },
      MuiRadio: {
        disableRipple: disableRipple,
        ...params.muiProps?.MuiRadio
      },
      MuiCheckbox: {
        color: 'primary'
      },
      MuiIconButton: {
        disableRipple: disableRipple,
        ...params.muiProps?.MuiIconButton
      },
      MuiList: {
        disablePadding: true,
        ...params.muiProps?.MuiList
      },
      MuiTooltip: {
        arrow: true,
        ...params.muiProps?.MuiTooltip
      },
      MuiFilledInput: {
        disableUnderline: true
      },
      MuiTextField: {
        variant: 'filled',
        InputLabelProps: {
          shrink: true,
          style: {
            lineHeight: '115%',
            ...params.muiProps?.MuiTextField?.InputLabelProps?.style
          }
        }
      },
      MuiSelect: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          style: {
            marginTop: 4
          }
        }
      },
      MuiAppBar: {
        color: 'primary'
      },
      ...params.muiProps
    },

    spacing: params.spacing || DefaultEpilotTheme.spacing,

    breakpoints: {
      ...params?.breakpoints
    }
  }
}
