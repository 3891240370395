import type { BLOCK_TYPE } from '../enums'
import type { StepId } from '../ids'

import type { BlockBaseSettings, IBlock } from './base'

export enum ActionBarActionType {
  NAVIGATE_ONLY = 'navigate-only',
  SUBMIT_AND_NAVIGATE = 'submit-and-navigate'
}

export interface ActionBarBlock extends IBlock {
  type: BLOCK_TYPE.ACTION_BAR
  required: false // can't ever be required
  settings: BlockBaseSettings & {
    /**
     * Determine whether the "next" button should be shown
     * and how it should behave.
     */
    next:
      | {
          display: false
          label?: never
          action?: never
          nextStep?: never
        }
      | {
          display: true
          label: string
          /** Whether we should just navigate the user or also submit */
          action: ActionBarActionType
          /** To which Step the user should be taken to */
          nextStep: StepId
        }
    /**
     * Determine whether the "back" button should be shown
     * and how it should behave.
     */
    back:
      | {
          display: false
          label?: never
        }
      | {
          display: true
          label: string
        }
  }
}
