import { computePricingDetails } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

export const useComputePricingDetails: typeof computePricingDetails = ({
  price,
  quantity = 1,
  overrides,
  externalCatalogData
}) => {
  return useMemo(() => {
    return computePricingDetails({
      price,
      quantity,
      overrides,
      externalCatalogData
    })
  }, [price, quantity, overrides, externalCatalogData])
}
