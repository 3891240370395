import { replaceSpaceWithUnderscore } from './replaceSpaceWithUnderscore'

export const ID_DELIMITER = '---'

export const appendStepBlockId = (id: string, fieldName?: string) => {
  if (!fieldName) return id

  const transformedFieldName = replaceSpaceWithUnderscore(fieldName)

  return `${id}${ID_DELIMITER}${transformedFieldName}`
}
