export const isTransparent = (color: string | undefined) => {
  if (!color) return false

  // Check for 'transparent' keyword
  if (color.includes('transparent')) return true

  // Check for rgba(…) with alpha = 0
  const rgbaMatch =
    /rgba?\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*0\s*\)/i.test(color)

  if (rgbaMatch) return true

  // Check for hex with alpha = 0 (e.g., #RRGGBBAA where AA = 00)
  const hexMatch = color.match(/^#(?:[0-9a-fA-F]{8}|[0-9a-fA-F]{4})$/)

  if (hexMatch) {
    const alphaHex =
      color.length === 9
        ? color.slice(7, 9) // For #RRGGBBAA
        : color.slice(4, 5).repeat(2) // For #RGBA

    return parseInt(alphaHex, 16) === 0
  }

  return false
}
