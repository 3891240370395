import { Divider } from '@epilot/journey-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import type { SelectedProductTile, Source } from '@epilot/journey-logic-commons'

import type { SummaryData } from '../types'

import { ProductBlockSummaryItem } from './ProductBlockSummaryItem'
import { useProductBlockSummaryDataStyles } from './styles'
import { useGetProductBlockSummaryData } from './useGetProductBlockSummaryData'

type ProductBlockSummaryDataProps = {
  data: SelectedProductTile | SelectedProductTile[]
  source: Source | undefined
}

export const ProductBlockSummaryData = ({
  data,
  source
}: ProductBlockSummaryDataProps): SummaryData => {
  const generalConfiguration = {
    displayUnitaryAverage:
      source?.uischema.options?.displayUnitaryAverage ?? true,
    showTrailingDecimalZeros:
      Boolean(source?.uischema.options?.showTrailingDecimalZeros) ?? false,
    summaryBlockId: `product_summary_${source?.stepIndex || 0}_${toDashCase(source?.name || '')}`
  }

  const { summaryPriceItems } = useGetProductBlockSummaryData({ data })

  const classes = useProductBlockSummaryDataStyles()

  return [
    [
      <div
        className={classes.summaryContainer}
        key={generalConfiguration.summaryBlockId}
      >
        {summaryPriceItems.map((item, index) => (
          <>
            <ProductBlockSummaryItem
              classes={classes}
              generalConfiguration={generalConfiguration}
              item={item}
              key={item._id}
            />
            {index < summaryPriceItems.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </>
        ))}
      </div>
    ]
  ]
}
