import {
  type Price,
  type Tax,
  type PriceWithBlockMappings,
  isCompositePrice
} from '@epilot/journey-logic-commons'
import { sort, unique } from 'radashi'

export type TaxWithTaxInclusivityBehaviour = Tax & { inclusive: boolean }

/**
 * @private Only needed for isPriceTaxATaxArray
 */
const deduplicatedTaxesWithInclusivityBehaviour = (
  taxes: TaxWithTaxInclusivityBehaviour[]
): TaxWithTaxInclusivityBehaviour[] =>
  unique(taxes, (tax) => `${tax._id}${String(tax.inclusive)}`)

/**
 * @private Only needed for isPriceTaxATaxArray
 */
const isPriceTaxATaxArray = (tax: Price['tax']): tax is Tax[] =>
  !!tax && !('$relation' in tax)

export const extractPriceTaxes = (
  price: PriceWithBlockMappings
): TaxWithTaxInclusivityBehaviour[] => {
  let taxes: TaxWithTaxInclusivityBehaviour[]

  if (isCompositePrice(price)) {
    taxes = ((price.price_components ?? []) as Price[])
      .map(extractTaxesFromPrice)
      .flatMap((taxes) => taxes)
      .filter((tax) => tax)
  } else {
    taxes = extractTaxesFromPrice(price)
  }

  const deduplicatedTaxes = deduplicatedTaxesWithInclusivityBehaviour(taxes)
  const orderedTaxes = sort(deduplicatedTaxes, (tax) => tax.rate)

  return orderedTaxes
}

const extractTaxesFromPrice = (
  price: Price
): TaxWithTaxInclusivityBehaviour[] => {
  const taxes = price.tax ?? []

  if (!isPriceTaxATaxArray(taxes)) {
    return []
  }

  return taxes.map<TaxWithTaxInclusivityBehaviour>((tax) => ({
    ...tax,
    inclusive: !!price.is_tax_inclusive
  }))
}
