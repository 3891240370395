import type { RumGlobal } from '@datadog/browser-rum'
import type {
  HistoryLocationState,
  HistoryStack,
  HistoryStackState,
  Journey,
  Step,
  JourneySession
} from '@epilot/journey-logic-commons'
import { EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE } from '@epilot/journey-logic-commons'
import areEqual from 'fast-deep-equal'
import type { To } from 'history'
import { createMemoryHistory } from 'history'
import { useCallback, useEffect, useState } from 'react'

import { usePrevious } from '../usePrevious'
import { useUserProgress } from '../useUserProgress'

import { dispatchUserEvent } from './dispatchUserEvent'
import { prepareTrackingData } from './prepareTrackingData'
import { getIndex, getIsTrackingDisabled, mergeStack, toPath } from './utils'

let history = createMemoryHistory()

/**
 * Wrapped history.push method dash-casing passed path
 */
const push: HistoryStackState['push'] = (
  to: To,
  state: HistoryLocationState
) => {
  return history.push(toPath(to), state)
}

/**
 * Wrapped history.replace method dash-casing passed path
 */
const replace: HistoryStackState['replace'] = (
  to: To,
  state: HistoryLocationState
) => {
  return history.replace(toPath(to), state)
}

/**
 * Wrapped history.go method taking the wanted index, instead of the delta
 */
const go = (desiredStepIndex: number) => {
  const historyStack = (history.location?.state as HistoryLocationState)?.stack
  const currentIndex = history?.index

  if (typeof currentIndex !== 'number') {
    // eslint-disable-next-line no-console
    console.error(
      'useHistoryStack -> Could not retrieve current index from history. No action executed'
    )

    return
  }

  // NOTE: calculating the delta can NOT be simply: delta = (currentIndex - desiredStepIndex) * -1
  // due of branching, the desired step index may not reflect the actual index in the histpry stack
  // also note that currentIndex != currentSTEPindex

  const desiredStepIndexInStack = historyStack.findIndex(
    (his) => his.stepIndex === desiredStepIndex
  )
  const delta = (currentIndex - desiredStepIndexInStack) * -1

  if (!delta) {
    // delta = 0 -> stay on step, no history change required
    return
  }

  return history.go(delta)
}

/**
 * Hook to provide navigation. Internally uses browser history API with the differences:
 * - Does not expose forward method
 * - All methods exposed take a string, and internally transforms it to dash-case in lowercase
 * - Expose a stack history.
 */
export const useHistoryStack = (
  initialValues: {
    stepName: string
    stepIndex: number
  },
  scrollToThePageTop: () => void,
  journey?: Journey,
  initialHistoryStack?: HistoryStack[],
  options?: {
    scrollIntoView?: boolean
    forceInitialize?: boolean
    isTrackingDisabled?: boolean
    isUserProgressEnabled?: boolean
  },
  datadogRum?: RumGlobal
): HistoryStackState => {
  const [stack, setStack] = useState<HistoryStack[]>(
    Array.isArray(initialHistoryStack) && initialHistoryStack.length > 0
      ? initialHistoryStack
      : []
  )

  const { saveProgress } = useUserProgress({ journey })

  const initialHistoryStackPrev = usePrevious(initialHistoryStack)

  useEffect(() => {
    if (
      initialHistoryStack &&
      initialHistoryStack.length > 0 &&
      !areEqual(initialHistoryStackPrev, initialHistoryStack)
    ) {
      setStack(initialHistoryStack)
    }
  }, [initialHistoryStack, initialHistoryStackPrev])

  const {
    scrollIntoView,
    forceInitialize = false,
    isTrackingDisabled,
    isUserProgressEnabled
  } = options || {}

  const initialValuesPrev = usePrevious(initialValues)
  const initialIsTrackingDisabled = usePrevious(isTrackingDisabled)

  const historyListener = useCallback(
    ({ location, action }) => {
      // do not execute if journey hasnt loaded yet
      if (!journey?.journeyId && !forceInitialize) return

      const index = getIndex(location)
      const isTrackingDisabled = getIsTrackingDisabled(location)

      if (typeof index !== 'number') {
        console.error(
          'useHistoryStack -> invalid state value passed. Expected index to be of type number'
        )

        return
      }
      // Log Data to DataDog
      datadogRum?.startView && datadogRum?.startView(location.pathname)

      // Store progress
      const locationState = location.state as HistoryLocationState
      const stepsState = locationState?.userValues

      if (!isTrackingDisabled) {
        prepareTrackingData(locationState.userValues, locationState.steps).then(
          (data: any) => {
            if (!journey?.journeyId) return // mostly to cerce TS

            dispatchUserEvent(
              EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PAGE_VIEW,
              journey.journeyId,
              {
                journeyName: journey.name,
                path: `epilot-journey/${journey?.journeyId}/step-${index + 1}-${
                  location.pathname
                }`,
                userValues: data || {}
              }
            )
          }
        )
      }

      if (action === 'PUSH' && scrollIntoView) {
        // Scroll to the top on loading NEW step
        scrollToThePageTop()
      }

      // if item found, and there are items later in the list, remove them
      setStack((stackCurrent) => {
        const newStack = mergeStack(
          locationState.stack || stackCurrent,
          location,
          index
        )

        isUserProgressEnabled && saveProgress(index, stepsState || [], newStack)

        return newStack
      })
    },
    [
      journey?.journeyId,
      journey?.name,
      forceInitialize,
      datadogRum,
      scrollIntoView,
      scrollToThePageTop,
      isUserProgressEnabled,
      saveProgress
    ]
  )

  // sets up event listener reacting to location changes in order to modify stack
  useEffect(() => {
    const unlisten = history.listen(historyListener)

    return () => unlisten()
  }, [historyListener])

  // set initial value
  useEffect(() => {
    if (
      (initialValues && !areEqual(initialValues, initialValuesPrev)) ||
      isTrackingDisabled !== initialIsTrackingDisabled
    ) {
      replace(initialValues.stepName, {
        stepIndex: initialValues.stepIndex,
        isTrackingDisabled
      })
    }
  }, [
    initialValues,
    initialValuesPrev,
    isTrackingDisabled,
    initialIsTrackingDisabled
  ])

  const initSession = (session: JourneySession, journeySteps: Step[]) => {
    // construct the paths the user has skipped to be added to the history so that the user can go back to a previous step as if it was part of the history
    const paths: Partial<Location>[] = session.historyStack.map((s) => ({
      pathname: toPath(journeySteps[s.stepIndex].name) as string,
      state: {
        stepIndex: s.stepIndex,
        stack: session.historyStack,
        steps: journeySteps
      }
    }))

    // reinit the memory history
    history = createMemoryHistory({
      initialEntries: paths
    })
    // make a single call to the listner to update the stack
    historyListener({ location: history.location, action: 'PUSH' })
  }

  return {
    ...history,
    push,
    replace,
    go,
    stack,
    currentIndex: getIndex(history.location) || 0,
    initSession
  }
}
