// Generated by ts-to-zod
import { z } from 'zod'

import { BLOCK_TYPE } from '../../../types/next'

import { iBlockSchema, blockBaseSettingsSchema } from './baseBlock.zod'
import { commonFieldSettingsSchema } from './personalInformationBlock.zod'

export const addressBlockSchema = iBlockSchema.extend({
  type: z.literal(BLOCK_TYPE.ADDRESS),
  settings: blockBaseSettingsSchema.and(
    z.object({
      api: z.string(),
      initialValue: z.unknown(),
      fields: z.object({
        zipCity: commonFieldSettingsSchema,
        street: commonFieldSettingsSchema,
        houseNumber: commonFieldSettingsSchema,
        extention: commonFieldSettingsSchema
      }),
      mapIntegration: z.object({
        enabled: z.boolean(),
        repositionAllowed: z.boolean()
      })
    })
  )
})
