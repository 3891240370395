import {
  areProductDownloadsHydrated,
  areProductImagesHydrated
} from '@epilot/journey-logic-commons'
import type {
  Price,
  PriceWithBlockMappings,
  Product
} from '@epilot/journey-logic-commons'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { useMemo } from 'react'

import { ProductDetailsContent } from '../../../../components/ProductDetails/components/ProductDetailsContent'
import { extractPaymentConditionItems } from '../../../../components/ProductDetails/utils/product-details-modal-utils'
import { useComputePricingDetails } from '../../../../utils/pricing'

import type { SummaryPriceItem } from './useGetProductBlockSummaryData'

export const ProductBlockSummaryItem = ({
  item,
  generalConfiguration,
  classes
}: {
  item: SummaryPriceItem
  classes: ClassNameMap<string>
  generalConfiguration: {
    displayUnitaryAverage: boolean
    showTrailingDecimalZeros: boolean
    summaryBlockId: string
  }
}) => {
  const { summaryBlockId, displayUnitaryAverage, showTrailingDecimalZeros } =
    generalConfiguration
  const product = item._product as Product
  const price = item._price as PriceWithBlockMappings
  const externalCatalogData = item.externalCatalogData

  const { pricingDetails } = useComputePricingDetails({
    price: item,
    externalCatalogData: externalCatalogData,
    quantity: item.quantity || 1
  })

  const downloads = useMemo(
    () =>
      areProductDownloadsHydrated(item._product)
        ? item._product.product_downloads.filter(
            (download) => download.access_control !== 'private'
          )
        : [],
    [item._product]
  )

  const conditions = useMemo(
    () =>
      extractPaymentConditionItems([
        price,
        ...((price?.price_components as Price[]) ?? [])
      ]),
    [price]
  )

  const shouldDisplayImageGallery = useMemo(
    () =>
      areProductImagesHydrated(product) &&
      product.product_images.some(
        (image) => image.access_control !== 'private'
      ),
    [product]
  )

  return (
    <div className={classes.summaryItem}>
      <ProductDetailsContent
        classes={classes}
        conditions={conditions}
        data={{
          product,
          price,
          id: summaryBlockId,
          pricingDetails,
          externalCatalogData,
          displayUnitaryAverage,
          showTrailingDecimalZeros
        }}
        downloads={downloads}
        shouldDisplayImageGallery={shouldDisplayImageGallery}
        shouldDisplayPriceChart={false}
      />
    </div>
  )
}
