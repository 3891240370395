/* eslint-disable @typescript-eslint/no-empty-function */
import type { DesignTokens } from '@epilot/journey-logic-commons'
import { createContext, useState } from 'react'

type DesignBuilderContext = {
  theme: string
  setTheme: (theme: string) => void
  logo: string
  setLogo: (logo: string) => void
  designTokens?: DesignTokens
  setDesignTokens: (designTokens?: DesignTokens) => void
}

const defaultContext: DesignBuilderContext = {
  theme: '',
  setTheme: () => {},
  logo: '',
  setLogo: () => {},
  designTokens: undefined,
  setDesignTokens: () => {}
}

export const DesignBuilderContext =
  createContext<DesignBuilderContext>(defaultContext)

export const DesignBuilderContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('')
  const [logo, setLogo] = useState('')
  const [designTokens, setDesignTokens] = useState<DesignTokens>()

  return (
    <DesignBuilderContext.Provider
      value={{
        theme,
        setTheme,
        logo,
        setLogo,
        designTokens,
        setDesignTokens
      }}
    >
      {children}
    </DesignBuilderContext.Provider>
  )
}

export default DesignBuilderContextProvider
