import { handleFirstUserEngagementWithJourney } from './service'
import type { JourneyAnalyticsPlugin } from './types'

export const addAnalyticsEventListeners = (
  analytics: JourneyAnalyticsPlugin
) => {
  document.addEventListener('mouseover', handleFirstUserEngagementWithJourney, {
    once: true
  })
  window.addEventListener('beforeunload', analytics?.endSession)
}

export const removeAnalyticsEventListeners = (
  analytics: JourneyAnalyticsPlugin
) => {
  window.removeEventListener('beforeunload', analytics?.endSession)
}
