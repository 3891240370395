import type { Price, CompositePrice } from '@epilot/journey-logic-commons'
import { max } from 'radashi'

import type {
  BillingDurationUnit,
  PaymentConditionItem,
  PaymentConditionItemBase,
  PaymentConditionType
} from '../components/ProductDetailsPaymentConditions/types'

type PaymentConditionItemWithOptionalUnit = PaymentConditionItemBase & {
  unit?: BillingDurationUnit | null
}

/**
 * Normalizes a time frequency to days, so that different time frequencies can be compared.
 */
const normalizeFrequency = ({ amount, unit }: PaymentConditionItem): number => {
  switch (unit) {
    case 'weeks':
      return amount * 7
    case 'months':
      return amount * 30
    case 'years':
      return amount * 365
    default:
      return 0
  }
}

const isPaymentConditionItem = (
  item: PaymentConditionItemWithOptionalUnit
): item is PaymentConditionItem =>
  Boolean(
    typeof item.amount === 'number' && !Number.isNaN(item.amount) && item.unit
  )

const extractConditionAmount = (value: unknown): number =>
  typeof value === 'number' ? value : Number(value || undefined)

const isTruthy = <T>(
  value: T
): value is Exclude<T, undefined | null | false | '' | 0> => Boolean(value)

export const extractPaymentConditionItems = (
  priceComponents: (Price | CompositePrice)[]
): PaymentConditionItem[] => {
  const billingDurationItems = priceComponents
    .map<PaymentConditionItemWithOptionalUnit>((priceComponent) => ({
      type: 'billing_duration',
      amount: extractConditionAmount(priceComponent.billing_duration_amount),
      unit: priceComponent.billing_duration_unit
    }))
    .filter(isPaymentConditionItem)

  const noticeTimeItems = priceComponents
    .map<PaymentConditionItemWithOptionalUnit>((priceComponent) => ({
      type: 'notice_time',
      amount: extractConditionAmount(priceComponent.notice_time_amount),
      unit: priceComponent.notice_time_unit
    }))
    .filter(isPaymentConditionItem)

  const terminationTimeItems = priceComponents
    .map<PaymentConditionItemWithOptionalUnit>((priceComponent) => ({
      type: 'termination_time',
      amount: extractConditionAmount(priceComponent.termination_time_amount),
      unit: priceComponent.termination_time_unit
    }))
    .filter(isPaymentConditionItem)

  const renewalDurationItems = priceComponents
    .map<PaymentConditionItemWithOptionalUnit>((priceComponent) => ({
      type: 'renewal_duration',
      amount: extractConditionAmount(priceComponent.renewal_duration_amount),
      unit: priceComponent.renewal_duration_unit
    }))
    .filter(isPaymentConditionItem)

  return [
    max(billingDurationItems, normalizeFrequency),
    max(noticeTimeItems, normalizeFrequency),
    max(terminationTimeItems, normalizeFrequency),
    max(renewalDurationItems, normalizeFrequency)
  ].filter(isTruthy)
}

export const PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS: Record<
  PaymentConditionType,
  string
> = {
  billing_duration: 'product_details.payment_conditions.billing_duration',
  notice_time: 'product_details.payment_conditions.notice_time',
  termination_time: 'product_details.payment_conditions.termination_time',
  renewal_duration: 'product_details.payment_conditions.renewal_duration'
}

export const PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS: Record<
  PaymentConditionType,
  string
> = {
  billing_duration: 'Billing Duration',
  notice_time: 'Notice Time',
  termination_time: 'Termination Time',
  renewal_duration: 'Renewal Duration'
}
