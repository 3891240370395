import type { AutocompleteProps } from '@material-ui/lab'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'

import { useAutocompleteStyles } from './styles'

export const AutocompleteX = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { paper } = useAutocompleteStyles()

  return (
    <MuiAutocomplete
      {...props}
      classes={{
        ...props.classes,
        paper: `${props.classes?.paper || ''} ${paper}`
      }}
    />
  )
}
