export type AuthUser = {
  email: string
  // other
  [key: string]: unknown
}

// TODO: Do we need unknown?
export enum AuthStatus {
  /**
   * Auth logic and context are not yet ready
   * You likely wants to show a loading indicator during this state.
   */
  CONFIGURING = 'configuring',
  /** User is authenticated */
  AUTHENTICATED = 'authenticated',
  /** User is not authenticated */
  UNAUTHENTICATED = 'unauthenticated'
}

export enum AuthStep {
  IDLE = 'idle',
  SIGN_IN = 'sign in',
  VERIFY_SIGN_IN = 'verify sign in',
  SIGN_OUT = 'sign out',
  AUTHENTICATED = 'authenticated'
}

export type AuthenticatedUserProps = {
  /** Current auth step */
  authStep: AuthStep
  /** Current auth status */
  authStatus: AuthStatus
  /** Signed in user, if any */
  user?: AuthUser
  /** Any error returned by the service API call */
  error?: Error
  /** Whether service API calls are in progress */
  isPending: boolean
  signIn: (email: string) => Promise<unknown>
  verifySignIn: (session: unknown, code: string) => Promise<unknown>
  signOut: () => void
}
