import type { TFunction } from 'i18next'

import type { BlockVariableConfig } from '../types'

export const booleanInputBlockProperties = ['isSelected'] as const

export type BooleanInputBlockProperties =
  (typeof booleanInputBlockProperties)[number]

export const getVariablesFromBooleanInputBlock = (): BlockVariableConfig => {
  return {
    isAccessedByIndex: false,
    properties: [...booleanInputBlockProperties]
  }
}

export const getValuesFromBooleanInputBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (blockState === undefined || blockState === null) {
    return {}
  }

  const data: Partial<Record<BooleanInputBlockProperties, string>> = {}

  if (typeof blockState === 'boolean') {
    data.isSelected = t(blockState ? 'Yes' : 'No')
  }

  return data
}
