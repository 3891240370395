import { BodyText, Grid, SmallText } from '@epilot/journey-elements'
import { toDateString } from '@epilot/journey-logic-commons'
import { parseISO } from 'date-fns'

export type AttributeItemProps = {
  label: string
  value?: string
  slug?: string
  displayMode?: 'simple' | 'table'
}

const isDate = (value: string) => {
  const valueDate = parseISO(value)

  return valueDate.toString() !== 'Invalid Date'
}

export const SimpleAttributeItem = ({
  label,
  value,
  displayMode
}: AttributeItemProps) => {
  return displayMode === 'simple' ? (
    <BodyText>
      {label}: {value}
    </BodyText>
  ) : (
    <Grid item sm={3} xs={12}>
      <span>
        <SmallText>{(label || '').toUpperCase()}</SmallText>
      </span>
      <span>
        <BodyText>
          {value
            ? isDate(value)
              ? toDateString(value, true, document.documentElement.lang)
              : value
            : '-'}
        </BodyText>
      </span>
    </Grid>
  )
}
