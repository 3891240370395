import type { Components } from '@epilot/journey-client'
import type {
  WithRequiredProperty,
  StepState,
  BlockDisplaySetting,
  ContextData
} from '@epilot/journey-logic-commons'
import type { JOURNEY_EMBED_MODE } from '@epilot/journey-logic-commons/src/types/next'

export type DataInjectionOptions = {
  initialStepIndex?: number
  initialState?: StepState[]
  blocksDisplaySettings?: BlockDisplaySetting[]
}

export type Settings = WithRequiredProperty<
  Components.Schemas.JourneyCreationRequest,
  'settings'
>['settings']

export type EmbedOptions = Required<
  WithRequiredProperty<Settings, 'embedOptions'>['embedOptions']
>

export enum MESSAGE_TYPES {
  EXIT_FULL_SCREEN = 'EPILOT/EXIT_FULLSCREEN',
  ENTER_FULL_SCREEN = 'EPILOT/ENTER_FULLSCREEN',
  INIT = 'EPILOT/INIT',
  UPDATE_JOURNEY = 'EPILOT/UPDATE_JOURNEY'
}

export type MessageData =
  | {
      payload: JourneyEmbedOptions
      status: 'effect'
      journeyId: string
      type: MESSAGE_TYPES.INIT
    }
  | {
      payload: JourneyEmbedOptions
      status: 'effect'
      journeyId: string
      type: MESSAGE_TYPES.UPDATE_JOURNEY
    }
  | {
      payload: Record<string, unknown>
      status: 'success'
      journeyId: string
      type: MESSAGE_TYPES
    }

/** @see {isJourneyEmbedOptions} ts-auto-guard:type-guard */
export type JourneyEmbedOptions = {
  mode: JOURNEY_EMBED_MODE | ''
  topBar?: EmbedOptions['topBar']
  scrollToTop?: EmbedOptions['scrollToTop']
  closeButton?: boolean
  lang?: string
  contextData?: ContextData
  queryParams?: Record<string, string>
  dataInjectionOptions?: DataInjectionOptions
  isFullScreenEntered?: boolean // used to determine if the journey is open when in fullscreen mode
  initialMessageEventReceived?: boolean
}

/** @see {isPartialJourneyEmbedOptions} ts-auto-guard:type-guard */
export type PartialJourneyEmbedOptions = Partial<JourneyEmbedOptions>
