// Generated by ts-to-zod
import { z } from 'zod'

import { LogicAction } from '../../../types/next'

import { type ComparisonValue, LogicTrigger } from './../../../types/next/logic'
import { sortableSchema } from './common.zod'
import { logicActionSchema, logicOperatorSchema } from './enums.zod'
import { blockIdSchema, logicIdSchema, stepIdSchema } from './ids.zod'
import {
  blockReferenceSchema,
  contextParameterReferenceSchema
} from './references.zod'

export const logicTriggerSchema = z.nativeEnum(LogicTrigger)

export const blockLogicReferenceSchema = blockReferenceSchema.extend({
  meaning: z.literal('data source')
})

export const comparisonValueSchema: z.ZodSchema<ComparisonValue> = z.lazy(() =>
  z
    .union([
      z.string(),
      z.number(),
      z.boolean(),
      z.undefined(),
      z.record(comparisonValueSchema)
    ])
    .nullable()
)

export const contextParameterLogicReferenceSchema =
  contextParameterReferenceSchema.extend({
    meaning: z.literal('data source')
  })

export const logicReferenceSchema = z.union([
  blockLogicReferenceSchema,
  contextParameterLogicReferenceSchema
])

export const clauseSchema = z.object({
  operator: logicOperatorSchema,
  a: logicReferenceSchema,
  b: z.union([logicReferenceSchema, comparisonValueSchema])
})

export const iLogicSchema = sortableSchema.extend({
  id: logicIdSchema,
  action: logicActionSchema,
  triggeredOn: logicTriggerSchema,
  triggeredBy: blockLogicReferenceSchema.optional(),
  conditions: z.array(z.array(clauseSchema)),
  settings: z.record(z.unknown()).optional()
})

export const skipStepLogicSchema = iLogicSchema.extend({
  action: z.literal(LogicAction.SKIP_STEP),
  triggeredOn: z.union([
    z.literal(LogicTrigger.BLOCK_STATE_CHANGED),
    z.literal(LogicTrigger.NEXT_CLICKED)
  ]),
  triggeredBy: blockLogicReferenceSchema,
  settings: z.object({
    targetSteps: z.array(stepIdSchema)
  })
})

export const blockVisibilityLogicSchema = iLogicSchema.extend({
  action: z.literal(LogicAction.BLOCK_VISIBILITY),
  triggeredOn: z.literal(LogicTrigger.BLOCK_STATE_CHANGED),
  triggeredBy: blockLogicReferenceSchema,
  settings: z.object({
    targetBlock: blockIdSchema
  })
})

export const dataInjectionLogicSchema = iLogicSchema.extend({
  action: z.literal(LogicAction.DATA_INJECTION),
  triggeredOn: z.literal(LogicTrigger.BLOCK_STATE_CHANGED),
  triggeredBy: blockLogicReferenceSchema,
  settings: z.object({
    sourceBlock: blockIdSchema,
    targetBlock: blockIdSchema
  })
})

export const jumpToStepLogicSchema = iLogicSchema.extend({
  action: z.literal(LogicAction.JUMP_TO_STEP),
  triggeredOn: z.union([
    z.literal(LogicTrigger.BLOCK_STATE_CHANGED),
    z.literal(LogicTrigger.NEXT_CLICKED)
  ]),
  triggeredBy: blockLogicReferenceSchema,
  settings: z.object({
    targetStep: stepIdSchema
  })
})

export const logicSchema = z.union([
  jumpToStepLogicSchema,
  skipStepLogicSchema,
  blockVisibilityLogicSchema,
  dataInjectionLogicSchema
])
