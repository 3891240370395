import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Product } from '@epilot/journey-logic-commons'
import {
  getOptimizedImageURL,
  areProductImagesHydrated,
  appendStepBlockId,
  getPublicUrl
} from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'
import { useMemo } from 'react'

import { DefaultProductImage } from '../../../../../../components/Product'
import { useConfig } from '../../../../../../providers'
import type { DefaultConfigurationValues } from '../../../utils/tile-context-utils'

import type { ImageFile } from './ProductImage'
import { OPTIMAL_IMAGE_WIDTH, ProductImage } from './ProductImage'
import { ProductImageStepper } from './ProductImageStepper'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: 0
    }
  })
)

const ProductImageWrapper = (props: ComponentProps<'figure'>) => {
  const classes = useStyles()

  return <figure className={classes.container} {...props} />
}

const DEFAULT_IMAGES: Array<ImageFile> = []

type ProductImageRendererProps = {
  product: Product
  id: string
  config?: DefaultConfigurationValues
}

export const ProductImageRenderer = (props: ProductImageRendererProps) => {
  const {
    product,
    config,
    id,
    product: { name }
  } = props

  const { IMAGE_PREVIEW_API_URL } = useConfig()

  const shouldRender = config
    ? config.generalTileConfiguration.showImages
    : true

  const optimizedImages = useMemo(() => {
    /* Avoid computing the optimized image urls if nothing is going to get rendered */
    if (!areProductImagesHydrated(product) || !shouldRender) {
      return DEFAULT_IMAGES
    }

    /* Omit images with private access control, map them into optimized images */
    return product.product_images
      .filter(({ access_control }) => access_control !== 'private')
      .map<ImageFile>((image) => ({
        ...image,
        id: image._id,
        alt: image.filename,
        src: getOptimizedImageURL(
          IMAGE_PREVIEW_API_URL,
          getPublicUrl(image),
          image._id,
          OPTIMAL_IMAGE_WIDTH
        )
      }))
  }, [IMAGE_PREVIEW_API_URL, product, shouldRender])

  if (!shouldRender) {
    return null
  }

  if (!optimizedImages.length) {
    return <DefaultProductImage />
  }

  if (optimizedImages.length === 1) {
    const [image] = optimizedImages

    return (
      <ProductImageWrapper>
        <ProductImage image={image} />
      </ProductImageWrapper>
    )
  }

  const _id = appendStepBlockId(id, `${name} Image Stepper`)

  return (
    <ProductImageWrapper>
      <ProductImageStepper id={_id} images={optimizedImages} />
    </ProductImageWrapper>
  )
}
