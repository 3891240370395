import { FeatureFlagProvider as ConcordeFeatureFlagProvider } from '@epilot/concorde-elements'
import {
  initServices as initLogicCommonsServices,
  isLauncherJourney,
  JourneyFeatureFlags as FeatureFlags
} from '@epilot/journey-logic-commons'
import type {
  Journey,
  JourneyRenderFlags,
  JourneySession
} from '@epilot/journey-logic-commons'
import { JOURNEY_EMBED_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import {
  AuthenticatedUserProvider,
  ConfigProvider,
  FeatureFlagProvider,
  getPortalToken,
  JourneyContextProvider,
  OrganizationSettingsContextProvider,
  ReactQueryProvider,
  useHistoryStack,
  useUserProgress
} from '../src/blocks-renderers'

import { setJourneyToken } from './analytics/service'
import { ExpiredJourneyTokenError } from './components/ExpiredJourneyTokenError'
import { JourneyNotFoundError } from './components/JourneyNotFoundError'
import { JourneyPage } from './components/JourneyPage'
import { publishCloseJourneyMessage } from './components/JourneyPage/embedJourneyPublishers'
import { dispatchExitEvent } from './components/JourneyPage/eventsDispatchers'
import { SessionSelectorDialog } from './components/SessionSelectorDialog'
import { SpinnerPage } from './components/SpinnerPage'
import { useAnalytics } from './context/AnalyticsContext'
import { DesignBuilderContextProvider } from './context/DesignBuilderContext'
import { useGetJourney } from './hooks/useGetJourney'
import { useMessageHandler } from './hooks/useMessageHandler'
import { useTracking } from './hooks/useTracking'
import { instance18n, loadRemoteNamespace } from './locales/i18n'
import { getDatadogClient } from './services/datadog'
import { MAJOR_APP_ERROR } from './types'
import { env } from './utils/config'
import { debug as debugLog } from './utils/debug'
import { getInitialHistoryStack } from './utils/getInitialHistoryStack'
import { getParamsByContextValues } from './utils/getParamsByContextValues'
import { parseUuidOrValueParams } from './utils/parse'
import { scrollToThePageTop } from './utils/scrollToTop'
import { TRACE_KEYS, getTraceId } from './utils/trace'

const rendererConfig = {
  STAGE: env('REACT_APP_STAGE'),
  GOOGLE_MAPS_API_URL: env('REACT_APP_GOOGLE_MAPS_API_URL'),
  PRICING_API_URL: env('REACT_APP_PRICING_API_URL'),
  FILE_API_URL: env('REACT_APP_FILE_API_URL'),
  IMAGE_PREVIEW_API_URL: env('REACT_APP_IMAGE_PREVIEW_API_URL'),
  ADDRESS_API_URL: env('REACT_APP_ADDRESS_API_URL'),
  ADDRESS_SUGGESTIONS_API_URL: env('REACT_APP_ADDRESS_SUGGESTIONS_API_URL'),
  PREVIOUS_PROVIDER_URL: env('REACT_APP_PREVIOUS_PROVIDER_URL'),
  ENTITY_API_URL: env('REACT_APP_ENTITY_API_URL'),
  CUSTOMER_PORTAL_API_URL: env('REACT_APP_CUSTOMER_PORTAL_API_URL'),
  METERING_API_URL: env('REACT_APP_METERING_API_URL'),
  JOURNEYS_API_BASE_URL: env('REACT_APP_JOURNEYS_API_BASE_URL'),
  JOURNEYS_API_URL: env('REACT_APP_JOURNEYS_API_URL')
}

const sessionIdGetter = () => getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

export default function App() {
  const {
    journey,
    error,
    stepIndex: jbStepIndex,
    debug,
    isLoading,
    initialState,
    preview,
    isLinearJourney,
    journeyId,
    contextData: previewContextData,
    topBarParam,
    modeParam,
    setJourney,
    journeyLanguage: languageParam,
    isEmbedded,
    dataInjectionOptionsParam
  } = useGetJourney()

  // Allow tracking only if third-party cookies are enabled, otherwise not
  useTracking(journey?.settings?.thirdPartyCookies !== false)

  const { currentSessionId, setCurrentSessionId, getExistingSessions } =
    useUserProgress({
      journey
    })

  const { initializeAnalytics } = useAnalytics()

  useEffect(() => {
    initLogicCommonsServices({
      pricingServiceUrl: env('REACT_APP_JOURNEYS_PRICING_API'),
      addressSuggestionsServiceUrl: env('REACT_APP_ADDRESS_SUGGESTIONS_API'),
      orgId: journey?.organizationId || '',
      publicToken: journey?.settings?.publicToken || ''
    })
  }, [journey?.organizationId, journey?.settings?.publicToken])

  const {
    mode: modeMessage,
    topBar,
    scrollToTop,
    closeButton,
    lang,
    contextData,
    queryParams,
    dataInjectionOptions,
    isFullScreenEntered,
    initialMessageEventReceived
  } = useMessageHandler(journeyId || undefined) || {}

  const mode = modeMessage || modeParam

  const {
    initialState: injectionInitialState,
    initialStepIndex: injectionStepIndex,
    blocksDisplaySettings
  } = dataInjectionOptionsParam || dataInjectionOptions || {}

  const initialStepIndex = injectionStepIndex || jbStepIndex

  const [journeyInitialState, setJourneyInitialState] = useState(
    /**
     * This clone is to avoid issues where the initial state gets mutated later in the journey
     * which will cause the journey to remount due to forceRemountAfterInjection used as react key in JourneyContextProvider.
     * TODO: Let's clarify if this remount forcing is really needed or not.
     */
    structuredClone(injectionInitialState || initialState)
  )

  const [launcherJourney, setLauncherJourney] = useState<Journey | null>(null)

  const [sessionStartTime, setSessionStartTime] = useState<Date | null>(null)

  const isAnalyticsEnabled =
    journey?.featureFlags?.[FeatureFlags.JOURNEY_DATE_REWORK]

  const isUserProgressEnabled = Boolean(
    journey?.featureFlags?.[FeatureFlags.USER_PROGRESS]
  )

  useEffect(() => {
    setSessionStartTime(new Date())
  }, [])

  useEffect(() => {
    try {
      if (journey?.journeyId && !journeyFlags?.isPreview) {
        getPortalToken().then((privateToken) => {
          if (isAnalyticsEnabled) {
            const publicToken = journey?.settings?.publicToken || ''
            const token = privateToken || publicToken
            const embeddedIn = document?.referrer || ''

            setJourneyToken(token)
            initializeAnalytics({
              embeddedIn,
              isLauncherJourney: isLauncherJourney(journey?.steps),
              journeyId: journey?.journeyId,
              journeyName: journey?.name,
              journeyLoadTime: sessionStartTime
                ? (new Date().getTime() - sessionStartTime.getTime()) / 1000
                : 0,
              landingStepName: journey?.steps?.[0]?.name
            })
          }
        })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error initializing analytics', err)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey?.journeyId])

  const history = useHistoryStack(
    {
      stepName: journey?.steps?.[initialStepIndex]?.name || '',
      stepIndex: initialStepIndex || 0
    },
    scrollToThePageTop,
    journey as Journey,
    // when journey is using injection, it should not allow navigating back to a skip step
    getInitialHistoryStack(journey?.steps, injectionStepIndex),
    {
      scrollIntoView:
        typeof scrollToTop === 'undefined' || scrollToTop === true,
      forceInitialize: preview,
      isTrackingDisabled:
        mode === JOURNEY_EMBED_MODE.FULL_SCREEN && !isFullScreenEntered,
      isUserProgressEnabled: isUserProgressEnabled
    },
    getDatadogClient()
  )

  const [existingSessions, setExistingSessions] = useState<JourneySession[]>([])

  const onRestoreSession = (sessionId: string) => {
    setCurrentSessionId(sessionId, rendererConfig.JOURNEYS_API_BASE_URL)
    const session = existingSessions.find((s) => s.id === sessionId)

    if (session && journey) {
      setJourneyInitialState(session.stepsState)
      history.initSession(session, journey.steps)
    }
  }

  useEffect(() => {
    if (
      isUserProgressEnabled &&
      journey?.journeyId &&
      !preview &&
      !currentSessionId
    ) {
      getExistingSessions(rendererConfig.JOURNEYS_API_BASE_URL).then(
        (sessions) => {
          if (
            Array.isArray(sessions) &&
            sessions.length > 0 &&
            initialStepIndex === 0
          ) {
            setExistingSessions(sessions)
          } else {
            setCurrentSessionId(uuidV4(), rendererConfig.JOURNEYS_API_BASE_URL)
            setExistingSessions([])
          }
        }
      )
    }
  }, [
    getExistingSessions,
    preview,
    currentSessionId,
    initialStepIndex,
    journey,
    isUserProgressEnabled,
    setCurrentSessionId
  ])

  /* On initial load, if journey is launcher journey, store it */
  useEffect(() => {
    if (journey && !launcherJourney && isLauncherJourney(journey.steps)) {
      setLauncherJourney(journey)
    }
  }, [launcherJourney, journey])

  const journeyFlags: JourneyRenderFlags = {
    debug,
    isLinear: isLinearJourney,
    isPreview: preview,
    showCloseButton: closeButton,
    showTopBar:
      typeof topBar === 'boolean'
        ? topBar
        : typeof topBarParam === 'boolean'
          ? topBarParam
          : true,
    mode: mode,
    scrollToTop: scrollToTop || false,
    activeJourneyEnabled: !!journey?.featureFlags?.[FeatureFlags.ACTIVE_JOURNEY]
  }

  debugLog('Journey flags:', journeyFlags)

  useEffect(() => {
    instance18n.changeLanguage(lang || languageParam)

    loadRemoteNamespace(lang || languageParam)
  }, [languageParam, lang])

  const contextParameter = parseUuidOrValueParams({
    ...getParamsByContextValues(queryParams || {}, journey?.contextSchema),
    ...(contextData || previewContextData)
  })

  const contextValues = {
    ...contextParameter,
    embedded_in: document.referrer,
    journey_url_used: document.location.href
  }

  const forceRemountAfterInjection =
    injectionInitialState || currentSessionId
      ? JSON.stringify(injectionInitialState)
      : 'normalcontext'

  if (
    // not in preview mode
    !journeyFlags.isPreview &&
    // we finished loading
    !isLoading &&
    // journey was not found
    !journey
  ) {
    const ErrorComponent =
      error === MAJOR_APP_ERROR.EXPIRED_JOURNEY_TOKEN
        ? ExpiredJourneyTokenError
        : JourneyNotFoundError

    return (
      <I18nextProvider i18n={instance18n}>
        <ErrorComponent
          embedMode={mode}
          isEmbedded={isEmbedded}
          onClose={() => {
            dispatchExitEvent()
            publishCloseJourneyMessage({
              journeyId: journeyId ?? undefined,
              isDirty: false
            })
          }}
        />
      </I18nextProvider>
    )
  }

  const showSessionSelectorDialog =
    !currentSessionId &&
    !preview &&
    existingSessions.length > 0 &&
    history.currentIndex === 0

  // show spinner while loading or while initializing in preview mode
  if (isLoading || !journey) return <SpinnerPage />

  return (
    <FeatureFlagProvider featureFlags={journey.featureFlags}>
      <ConcordeFeatureFlagProvider featureFlags={journey.featureFlags}>
        <ConfigProvider config={rendererConfig}>
          <JourneyContextProvider
            blocksDisplaySettings={blocksDisplaySettings}
            contextValues={contextValues}
            history={history}
            initialState={journeyInitialState}
            isPreview={preview}
            journey={journey}
            key={forceRemountAfterInjection}
            sessionIdGetter={sessionIdGetter}
            shouldCheckContextValues={
              isEmbedded ? !!initialMessageEventReceived : true
            }
          >
            <OrganizationSettingsContextProvider
              settings={journey.settings?.organizationSettings}
            >
              <DesignBuilderContextProvider>
                <I18nextProvider i18n={instance18n}>
                  <ReactQueryProvider>
                    <AuthenticatedUserProvider>
                      <JourneyPage
                        flags={journeyFlags}
                        history={history}
                        initialStepValues={initialState}
                        journey={journey}
                        key={journey.journeyId}
                        launcherJourney={launcherJourney}
                        onJourneyChange={setJourney}
                      />
                      {isUserProgressEnabled && (
                        <SessionSelectorDialog
                          availableSessions={existingSessions}
                          onDiscard={() => {
                            setCurrentSessionId(
                              uuidV4(),
                              rendererConfig.JOURNEYS_API_BASE_URL
                            )
                            setExistingSessions([])
                          }}
                          onSessionSelected={(sessionId) => {
                            onRestoreSession(sessionId)
                          }}
                          open={showSessionSelectorDialog}
                          useNewDesign={journey.settings?.useNewDesign}
                        />
                      )}
                    </AuthenticatedUserProvider>
                  </ReactQueryProvider>
                </I18nextProvider>
              </DesignBuilderContextProvider>
            </OrganizationSettingsContextProvider>
          </JourneyContextProvider>
        </ConfigProvider>
      </ConcordeFeatureFlagProvider>
    </FeatureFlagProvider>
  )
}

App.displayName = 'App'
