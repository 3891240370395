import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  createStyles,
  makeStyles,
  clsx
} from '@epilot/journey-elements'
import type {
  PriceWithBlockMappings,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  computePriceDisplayInJourneys,
  getDisplayPrice,
  computePrice,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { PricingDetails } from '@epilot/pricing'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DottedLine } from './DottedLine'
import { ProductDetailsPriceInformationTotalRecurrence } from './ProductDetailsPriceInformationTotalRecurrence'

export type PriceTotalsProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  quantity: number
  item: PriceWithBlockMappings
  pricingDetails: PricingDetails
  showTrailingDecimalZeros?: boolean
  recurrenceDetails: RecurrenceAmount[]
}

export const PriceTotals = ({
  quantity,
  item,
  pricingDetails,
  showTrailingDecimalZeros,
  recurrenceDetails,
  className,
  ...props
}: PriceTotalsProps) => {
  const { t: productSelectionT } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })
  const classes = useStyles()

  const { amountTotal, amountTotalDecimal } = useMemo(
    () => computePrice({ pricingDetails }),
    [pricingDetails]
  )
  const priceDisplayInJourneys = useMemo(
    () => computePriceDisplayInJourneys(item, quantity),
    [item, quantity]
  )

  const amountToDisplay = shouldShowDecimals(amountTotal)
    ? amountTotalDecimal
    : amountTotal

  const hasOnlyOnetimeRecurrences = recurrenceDetails.every(
    (recurrence) => recurrence.type === 'one_time' || !recurrence.type
  )

  return (
    <div className={clsx(classes.wrapper, className)} {...props}>
      <header className={classes.header}>
        <BodyText color="textPrimary">
          <strong>
            {productSelectionT(
              'product_details.price_information.total.label',
              'Total'
            )}
          </strong>
        </BodyText>
      </header>
      <DottedLine />
      {priceDisplayInJourneys !== 'show_price' && (
        <BodyText color="textPrimary">
          <strong>
            {getDisplayPrice(
              productSelectionT,
              amountToDisplay,
              priceDisplayInJourneys,
              showTrailingDecimalZeros,
              item.unit_amount_currency
            )}
          </strong>
        </BodyText>
      )}
      {priceDisplayInJourneys === 'show_price' && (
        <ul className={classes.list}>
          {recurrenceDetails?.map((recurrence) => (
            <li key={recurrence.billing_period || recurrence.type}>
              <ProductDetailsPriceInformationTotalRecurrence
                currency={item.unit_amount_currency}
                priceDisplayInJourneys={priceDisplayInJourneys}
                quantity={quantity}
                recurrence={recurrence}
                shouldHideRecurrence={
                  hasOnlyOnetimeRecurrences &&
                  (recurrence.type === 'one_time' || !recurrence.type)
                }
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                t={productSelectionT}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      fontWeight: 'bold'
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: 0,
      margin: 0,
      listStyle: 'none'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)
