import type { Currency } from '@epilot/pricing'
import { DEFAULT_CURRENCY, formatAmount } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { formatPercentage } from '../../utils/blocks/shoppingCart'
import { shouldShowDecimals } from '../../utils/shouldShowDecimals'
import type { Coupon, PriceItem } from '../types'

import { getDisplayPrice, omitTrailingDoubleDecimalZeros } from './pricing'

const formatPercentageDiscount = (coupon: Coupon) =>
  `${coupon.percentage_value}%`

const formatFixedDiscount = (coupon: Coupon) =>
  omitTrailingDoubleDecimalZeros(
    formatAmount({
      amount: coupon.fixed_value ?? 0,
      currency:
        (coupon.fixed_value_currency as Currency | undefined) ??
        DEFAULT_CURRENCY,
      locale: navigator.language,
      enableSubunitDisplay: false
    })
  )

const formatCouponDiscountValue = (coupon: Coupon) => {
  switch (coupon.type) {
    case 'percentage':
      return formatPercentageDiscount(coupon)
    case 'fixed':
      return formatFixedDiscount(coupon)
    default:
      console.error(new Error(`Unknown coupon type: ${coupon.type}`))

      return ''
  }
}

const formatDiscountValue = (coupon: Coupon) =>
  `-${formatCouponDiscountValue(coupon)}`

const formatCashbackValue = (coupon: Coupon, t: TFunction) =>
  t('product.selection.coupon.cashback_badge', {
    defaultValue: `{{value}} {{cashback_name}}`,
    value: formatCouponDiscountValue(coupon),
    cashback_name: coupon.name ?? t('product.selection.coupon.cashback')
  })

export const formatCoupon = (coupon: Coupon, t: TFunction) => {
  switch (coupon.category) {
    case 'cashback':
      return formatCashbackValue(coupon, t)
    case 'discount':
    default:
      return formatDiscountValue(coupon)
  }
}

export const getLineDiscountDetails = (
  item: PriceItem,
  t: TFunction,
  showTrailingDecimalZeros?: boolean
) => {
  const formatPrice = (value: number | string) =>
    getDisplayPrice(
      t,
      value,
      undefined,
      showTrailingDecimalZeros,
      item.currency
    )

  const discountAmount = shouldShowDecimals(item.discount_amount)
    ? item.discount_amount_decimal
    : item.discount_amount

  const beforeDiscountAmountTotal = shouldShowDecimals(
    item.before_discount_amount_total
  )
    ? item.before_discount_amount_total_decimal
    : item.before_discount_amount_total

  const discountPercentage = item.discount_percentage

  return {
    discountAmountToDisplay:
      typeof discountPercentage !== 'undefined'
        ? formatPercentage(discountPercentage)
        : typeof discountAmount !== 'undefined'
          ? formatPrice(discountAmount)
          : undefined,
    beforeDiscountAmountTotalToDisplay:
      typeof beforeDiscountAmountTotal !== 'undefined'
        ? formatPrice(beforeDiscountAmountTotal)
        : undefined
  }
}
