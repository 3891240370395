import {
  Button,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@epilot/concorde-elements'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@epilot/journey-elements'
import { useTranslation } from 'react-i18next'

import type { SelectorDialogProps } from './types'

export function SessionSelectorDialogConcorde({
  open,
  setSelectedId,
  value,
  onDiscard,
  onSessionSelected,
  sessionsIds,
  sessionsLabel
}: SelectorDialogProps) {
  const { t } = useTranslation()

  return (
    <Dialog onEscapeKeyDown={onDiscard} open={open}>
      <DialogTitle>
        {t('user_progress.dialog_header', 'Session Picker')}
      </DialogTitle>

      <DialogContent>
        <p>
          <Typography color="textPrimary" variant="primary">
            {t('user_progress.dialog_subheader')}
          </Typography>
        </p>
        <RadioGroup orientation="vertical" value={value}>
          {sessionsIds.map((id, index) => (
            <Radio
              key={id}
              label={sessionsLabel[index]}
              onChange={() => setSelectedId(id)}
              value={id}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          label={t('user_progress.button_discard', 'Discard')}
          onClick={() => onDiscard && onDiscard()}
          variant="bare"
        />
        <Tooltip
          isLocal={true}
          title={!value ? t('user_progress.button_tooltip') : ''}
        >
          <span>
            <Button
              isDisabled={!value}
              label={t('user_progress.button_continue', 'Continue')}
              onClick={() => value && onSessionSelected?.(value)}
              variant="primary"
            />
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}
