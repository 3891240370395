import { debug } from 'console'

import { useEffect } from 'react'

import { withDatadogRetries } from '../services/datadog'

/**
 * This hook is used to allow tracking in Datadog depending on the setup.
 * We initially init DD with `trackingConsent: 'not-granted'` and then we set it to 'granted' if the feature flag is not set.
 */
export const useTracking = (
  /**
   * Whether coockies are enabled, or consent has been given.
   * If false, disables tracking tools like DataDog to not set non-essential cookies.
   *
   * @default false
   */
  enabled: boolean = false
) => {
  useEffect(() => {
    try {
      const consent = enabled ? 'granted' : 'not-granted'
      const cancel = withDatadogRetries((client) => {
        client.setTrackingConsent(consent)

        debug('[Datadog] Tracking consent set', { consent })
      })

      return cancel
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Datadog] Failed to set tracking consent', error)
    }
  }, [enabled])
}
