import type {
  PossibleEventPayloads,
  EventDetailType
} from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

export const useListener = <Payload extends PossibleEventPayloads>(
  eventName: string,
  listenerFunction: (e: CustomEvent<EventDetailType<Payload>>) => void
) => {
  useEffect(() => {
    document.addEventListener(eventName, listenerFunction as EventListener)

    return () =>
      document.removeEventListener(eventName, listenerFunction as EventListener)
  }, [eventName, listenerFunction])
}
