import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  createStyles,
  Icon,
  Link,
  makeStyles,
  StackSpacing
} from '@epilot/journey-elements'
import type { File, ProductDetailsData } from '@epilot/journey-logic-commons'
import {
  appendStepBlockId,
  clsx,
  getPublicUrl
} from '@epilot/journey-logic-commons'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4)
    },
    download: {
      display: 'inline-flex',
      gap: theme.spacing(4)
    },
    downloadText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(3)
    },
    icon: {
      fontSize: '24px !important'
    }
  })
)

type ProductDetailsDownloadsProps = {
  downloads: File[]
  data: ProductDetailsData
  className?: string
}

function getDownloadLinkId(id: string, title?: string) {
  return appendStepBlockId(id, `Product Downloads ${title}`)
}

export const ProductDetailsDownloads = ({
  downloads,
  data,
  className
}: ProductDetailsDownloadsProps) => {
  const { id } = data

  const classes = useStyles()

  return (
    <StackSpacing className={clsx(classes.container, className)} scale={1}>
      {downloads.map((download) => (
        <Link
          className={classes.download}
          href={getPublicUrl(download)}
          id={getDownloadLinkId(id, download._title)}
          key={download._id}
          target="_blank"
        >
          <Icon className={classes.icon} color="secondary" name="download" />
          <BodyText
            className={classes.downloadText}
            color="textSecondary"
            variant="subtitle2"
          >
            {download.filename}
          </BodyText>
        </Link>
      ))}
    </StackSpacing>
  )
}
