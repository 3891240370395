import type { Product, DehydratedEntityRelation, File } from './types'

export const areProductDownloadsHydrated = (
  product?: Product
): product is ProductWithHydratedDownloads => {
  const downloads = product?.product_downloads

  if (
    !product ||
    !downloads ||
    !Array.isArray(downloads) ||
    (downloads as DehydratedEntityRelation[]).length === 0
  ) {
    return false
  }

  const hasFilename = 'filename' in downloads[0]
  const hasPublicUrl =
    'public_url' in downloads[0] ||
    (downloads[0]?.versions?.[0] && 'public_url' in downloads[0].versions[0])

  return hasFilename && hasPublicUrl
}

type ProductWithHydratedDownloads = Omit<Product, 'product_downloads'> & {
  product_downloads: File[]
}
