import Warning from '@epilot360/icons/react/Warning'
import { createStyles, makeStyles } from '@material-ui/core'
import type { MouseEventHandler } from 'react'
import { memo } from 'react'

import { Button } from '../Button'
import { Typography, LargeText } from '../Typography'

import type { NotFoundErrorProps } from './types'

const useStyles = makeStyles<undefined, NotFoundErrorProps>(
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      height: (props) =>
        `calc(${props.embedMode === 'inline' ? '100%' : '100vh'} - 32px)`,
      padding: 16,
      fontFamily:
        'ProximaNova, Open Sans, Helvetica, Arial, sans-serif !important',
      color: 'rgb(0, 24, 73)',
      textAlign: 'center'
    },
    heading: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textWrap: 'wrap',
      gap: 8,
      fontSize: 32
    }
  })
)

export const NotFoundError = memo((props: NotFoundErrorProps) => {
  const classes = useStyles(props)

  // determines whether the journey can be closed
  const canClose = props.isEmbedded && Boolean(props.onClose)

  const onClose: MouseEventHandler = () => {
    try {
      canClose && props.onClose?.()
    } catch (originalError) {
      const error = new Error('Failed to close the journey')

      error.cause = originalError

      throw error
    }
  }

  // inline styles should be ok here, this component will block all other processes
  return (
    <div className={classes.container}>
      <Typography className={classes.heading} variant="h4">
        <Warning size={48} />
        {props.i18n?.heading ??
          "Oops! We can't find the page you're looking for."}
      </Typography>
      <LargeText>
        {props.i18n?.content ??
          'Looks like you have followed a broken link or entered a URL that does not exist on this site'}
      </LargeText>
      {canClose && (
        // shown only on full-screen mode, doesn't make sense for inline embeds
        <Button color="inherit" onClick={onClose} variant="outlined">
          {props.i18n?.close ?? 'Close'}
        </Button>
      )}
    </div>
  )
})

NotFoundError.displayName = 'NotFoundError'
