import type {
  BlockDisplaySetting,
  Journey,
  StepState
} from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

export enum JourneyStateType {
  GET_JOURNEY_CONFIG_SUCCESS = 'GET_JOURNEY_CONFIG_SUCCESS',
  SET_MULTIPLE_STATE = 'SET_MULTIPLE_STATE',
  SET_IS_LOADING = 'SET_IS_LOADING',
  GET_JOURNEY_CONFIG_ERROR = 'GET_JOURNEY_CONFIG_ERROR'
}

export type JourneyStateAction =
  | {
      type: JourneyStateType.GET_JOURNEY_CONFIG_SUCCESS
      journeyConfig: Journey | undefined
    }
  | {
      type: JourneyStateType.SET_MULTIPLE_STATE
      partialState: Partial<JourneyState>
    }
  | {
      type: JourneyStateType.SET_IS_LOADING
      isLoading: boolean
    }
  | {
      type: JourneyStateType.GET_JOURNEY_CONFIG_ERROR
      isLoading: boolean
    }

export type JourneyState = {
  journeyConfig: Journey | undefined
  debug: boolean
  isLoading: boolean
  stepIndex: string
  /**
   * initialState is only relevant within journey builder, and can be
   * disregarded when journey app is running in standalone mode
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState?: StepState[]
  blocksDisplaySettings?: BlockDisplaySetting[]
}

export const initialReducerState: JourneyState = {
  journeyConfig: undefined,
  debug: false,
  isLoading: true, // prevents flashing an error before the journey is loaded
  stepIndex: '0',
  initialState: undefined
}

export const reducer = (
  state: JourneyState,
  action: JourneyStateAction
): JourneyState => {
  switch (action.type) {
    case JourneyStateType.SET_MULTIPLE_STATE:
      return { ...state, ...action.partialState }
    case JourneyStateType.GET_JOURNEY_CONFIG_SUCCESS:
      return { ...state, journeyConfig: action.journeyConfig, isLoading: false }
    case JourneyStateType.SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading }
    case JourneyStateType.GET_JOURNEY_CONFIG_ERROR:
      return { ...state, isLoading: action.isLoading }
    default:
      /* Unreachable, as action must always be one of the above */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(`No such action type: '${(action as any).type}'`)
  }
}

export const setLoadingJourneyAction = (
  isLoading: boolean
): JourneyStateAction => ({
  type: JourneyStateType.SET_IS_LOADING,
  isLoading
})

export const setConfigJourneyErrorAction = (): JourneyStateAction => ({
  type: JourneyStateType.GET_JOURNEY_CONFIG_ERROR,
  isLoading: false
})

export const setMultipleJourneyStateAction = (
  partialState: Partial<JourneyState>
): JourneyStateAction => ({
  type: JourneyStateType.SET_MULTIPLE_STATE,
  partialState
})

export const setConfigJourneySuccessAction = (
  journeyConfig: Journey | undefined
): JourneyStateAction => ({
  type: JourneyStateType.GET_JOURNEY_CONFIG_SUCCESS,
  journeyConfig: journeyConfig
})

/**
 * Set the language to the html tag
 */
export const useDocumentLanguage = (journeyLanguage: string) => {
  useEffect(() => {
    if (journeyLanguage) {
      document.documentElement.lang = journeyLanguage
    }
  }, [journeyLanguage])
}
