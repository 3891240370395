import type { HistoryStack } from '@epilot/journey-logic-commons'
import { toDashCase } from '@epilot/journey-logic-commons'
import type { Location, To } from 'history'

/**
 * @todo Add check for arg being object and 'state' in arg,
 * so that arg can be typed as unknown
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIndex = (arg: any): number | undefined =>
  typeof arg?.state?.stepIndex === 'number' ? arg.state.stepIndex : undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIsTrackingDisabled = (arg: any): number | undefined =>
  typeof arg?.state?.isTrackingDisabled === 'boolean'
    ? arg.state.isTrackingDisabled
    : undefined

// converts a space separated To value into lowercase + dash-case
export const toPath = (to: To): To => {
  if (typeof to === 'string') {
    return toDashCase(to)
  } else if (to.pathname) {
    return { ...to, pathname: toDashCase(to.pathname) }
  } else {
    return to
  }
}

/**
 * modifies and returns the stack.
 * Adds to stack if item does not exist yet
 * Removes all items after item if found
 */
export const mergeStack = (
  stack: HistoryStack[],
  location: Location,
  index: number
) => {
  const currentIndex = stack.findIndex((value) => value.stepIndex === index)

  // if item found, and there are items later in the list, remove them
  if (currentIndex !== -1) {
    return stack.filter((_, index) => !(index > currentIndex))
  }

  return [...stack, { pathname: location.pathname, stepIndex: index }]
}
