import type { DesignTokens, Journey } from '@epilot/journey-logic-commons'
import { JourneyFeatureFlags as FeatureFlags } from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

import { useFlags } from '../../providers'

export const useCustomCSS = (journey: Journey, designTokens?: DesignTokens) => {
  // Enable custom css only if the flag is enabled and the journey has the new design
  const isCustomCSSEnabled =
    useFlags()[FeatureFlags.CUSTOM_CSS] && journey?.settings?.useNewDesign
  const id = journey?.journeyId || journey.name

  // Add custom css to the head
  useEffect(() => {
    if (!isCustomCSSEnabled || !designTokens?.custom_css || !id) {
      return
    }

    const cssString = designTokens.custom_css
    const styleId = `custom-css-${id}`
    let styleTag = document.getElementById(styleId)

    if (!styleTag) {
      styleTag = document.createElement('style')
      styleTag.id = styleId
      styleTag.innerHTML = cssString
      document.head.appendChild(styleTag)
    } else {
      styleTag.innerHTML = cssString
    }

    return () => {
      if (styleTag) {
        styleTag.remove()
      }
    }
  }, [designTokens?.custom_css, id, isCustomCSSEnabled])
}
