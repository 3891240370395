import type { PricingDetails } from '@epilot/pricing'
import {
  BillingPeriods,
  normalizeTimeFrequency,
  toDinero
} from '@epilot/pricing'

import type { BillingPeriod, RecurrenceAmount } from '../../utils/types'

function getEarliestRecurrence(recurringBillings: RecurrenceAmount[]) {
  for (const billingPeriod of BillingPeriods) {
    const earliestRecurrence = recurringBillings.find(
      (recurringBilling) => recurringBilling.billing_period === billingPeriod
    )

    if (earliestRecurrence) {
      return earliestRecurrence
    }
  }

  return null
}

const computeRecurringPriceTotal = (
  recurrence: RecurrenceAmount,
  billingPeriod: Exclude<BillingPeriod, 'one_time'>
): RecurrenceAmount => {
  const normalized = normalizeTimeFrequency(
    recurrence.amount_total_decimal,
    /**
     * @todo Handle scenario in which the billing period arrives as undefined
     */
    recurrence.billing_period!,
    billingPeriod,
    12
  )

  const normalizedAmountDecimal = toDinero(normalized.toString()).toFormat(
    '0.00'
  )
  const normalizedAmount = toDinero(normalized.toString())
    .convertPrecision(2)
    .getAmount()

  return {
    ...recurrence,
    amount_total_decimal: normalizedAmountDecimal,
    amount_total: normalizedAmount,
    billing_period: billingPeriod
  }
}

export const computePrice = ({
  pricingDetails,
  billingPeriod
}: {
  pricingDetails: PricingDetails
  billingPeriod?: Exclude<BillingPeriod, 'one_time'>
}) => {
  /* From first item */
  const firstItem = pricingDetails.items?.[0]

  const amountTotal = firstItem?.amount_total || 0
  const amountTotalDecimal = firstItem?.amount_total_decimal || '0'

  const discountAmount = firstItem?.discount_amount
  const discountAmountDecimal = firstItem?.discount_amount_decimal

  const beforeDiscountTotalAmount = firstItem?.before_discount_amount_total
  const beforeDiscountTotalAmountDecimal =
    firstItem?.before_discount_amount_total_decimal

  const cashbackPeriod =
    firstItem?.cashback_period ??
    pricingDetails.total_details?.breakdown?.cashbacks?.[0]?.cashback_period

  /* Recurrences */
  const recurrences = pricingDetails.total_details?.breakdown?.recurrences ?? []

  /* One time recurrence */
  const oneTimeRecurrence = recurrences.find(({ type }) => type === 'one_time')

  const oneTimeTotal = oneTimeRecurrence?.amount_total
  const oneTimeTotalDecimal = oneTimeRecurrence?.amount_total_decimal

  const oneTimeBeforeDiscountAmount =
    oneTimeRecurrence?.before_discount_amount_total
  const oneTimeBeforeDiscountAmountDecimal =
    oneTimeRecurrence?.before_discount_amount_total_decimal

  const oneTimeDiscount = oneTimeRecurrence?.discount_amount
  const oneTimeDiscountDecimal = oneTimeRecurrence?.discount_amount_decimal

  /**
   * @todo Consider whether we it's really necessary to use
   * the first item's after_cashback_amount_total as a fallback
   */
  const oneTimeAfterCashbackAmount =
    oneTimeRecurrence?.after_cashback_amount_total ??
    firstItem?.after_cashback_amount_total
  const oneTimeAfterCashbackAmountDecimal =
    oneTimeRecurrence?.after_cashback_amount_total_decimal ??
    firstItem?.after_cashback_amount_total_decimal

  /* Earliest recurrence */
  const earliestRecurrence = getEarliestRecurrence(
    recurrences.filter(({ type }) => type === 'recurring')
  )

  const computedRecurringPriceTotal =
    earliestRecurrence && billingPeriod
      ? computeRecurringPriceTotal(earliestRecurrence, billingPeriod)
      : null

  const earliestRecurrenceAfterCashbackAmount =
    earliestRecurrence?.after_cashback_amount_total
  const earliestRecurrenceAfterCashbackAmountDecimal =
    earliestRecurrence?.after_cashback_amount_total_decimal

  return {
    /* Extracted from first item   */
    discountAmount,
    discountAmountDecimal,
    amountTotal,
    amountTotalDecimal,
    beforeDiscountTotalAmount,
    beforeDiscountTotalAmountDecimal,
    cashbackPeriod,
    /* One time recurrence */
    oneTimeTotal,
    oneTimeTotalDecimal,
    oneTimeBeforeDiscountAmount,
    oneTimeBeforeDiscountAmountDecimal,
    oneTimeDiscount,
    oneTimeDiscountDecimal,
    oneTimeAfterCashbackAmount,
    oneTimeAfterCashbackAmountDecimal,
    /* Earliest recurrence */
    earliestRecurrence,
    computedRecurringPriceTotal,
    earliestRecurrenceAfterCashbackAmount,
    earliestRecurrenceAfterCashbackAmountDecimal
  }
}
