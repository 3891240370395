import { CONTROL_NAME } from '../../../utils/blocks/types'
import type { UpBlockHandler } from '../types'

import { up as upActionBar } from './actionBarControl'
import { up as upGroupLayout } from './groupLayoutControl'
import { up as upNumberInput } from './numberInputControl'
import { up as upPayment } from './paymentControl'
import { up as upPersonalInformation } from './personalInformationControl'
import { up as upShoppingCart } from './shoppingCartControl'
import { up as upSingleChoice } from './singleChoiceControl'
import { up as upSummary } from './summaryControl'

// TODO: Support all block types
// @ts-expect-error until all blocks are supported
const upHandlerMap: Record<CONTROL_NAME, UpBlockHandler> = {
  [CONTROL_NAME.ACTION_BAR_CONTROL]: upActionBar,
  [CONTROL_NAME.CONTROL]: upSingleChoice,
  [CONTROL_NAME.GROUP_LAYOUT]: upGroupLayout,
  [CONTROL_NAME.NUMBER_INPUT_CONTROL]: upNumberInput,
  [CONTROL_NAME.PAYMENT_CONTROL]: upPayment,
  [CONTROL_NAME.PERSONAL_INFORMATION_CONTROL]: upPersonalInformation,
  [CONTROL_NAME.SHOP_CART_CONTROL]: upShoppingCart,
  [CONTROL_NAME.SUMMARY_CONTROL]: upSummary
}

export const upBlockControl = (
  name: string,
  ...args: Parameters<UpBlockHandler>
) => {
  const up = upHandlerMap[name as CONTROL_NAME]

  if (typeof up !== 'function') {
    throw new Error(`Unsupported block type: ${name}`)
  }

  return up(...args)
}
