import { createStyles, makeStyles, clsx } from '@epilot/journey-elements'
import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'

export const useStyles = makeStyles<Theme & EpilotTheme>(() =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%',
      padding: 0,
      margin: 0,
      listStyle: 'none'
    }
  })
)

export const PriceComponentsList = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>) => {
  const commonClasses = useStyles()

  return <ul className={clsx(commonClasses.list, className)} {...props} />
}
