import type {
  DispatchUserEventType,
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE
} from '@epilot/journey-logic-commons'

export enum EventNames {
  INIT_PRODUCT_SELECTION = 'InitiateProductSelection',
  INIT_CHECKOUT = 'InitiateCheckout',
  JOURNEY_SUBMIT = 'JourneySubmit'
}

export const publishEmbeddedJourneyMessage = (
  type: EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE,
  journeyId: string | undefined,
  payload: Record<string, unknown> = {},
  /**
   * @deprecated Pass necessary information as part of the payload
   */
  additionalData: Record<string, unknown> = {}
) => {
  window.parent.postMessage(
    {
      type,
      journeyId,
      payload,
      ...additionalData
    },
    '*'
  )
}

type DispatchUserEventPayload = {
  /** The name of the associated Journey */
  journeyName?: string
  path?: string
  eventName?: `${EventNames}`
  eventData?: unknown
  userValues?: unknown
}

export const dispatchUserEvent = (
  type: DispatchUserEventType,
  journeyId: string,
  payload: DispatchUserEventPayload
) => publishEmbeddedJourneyMessage(type, journeyId, payload)
