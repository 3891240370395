import { v4 as uuidv4 } from 'uuid'

import type { AnalyticsEvent, BrowserDetails } from './types'

export const fetchBrowserDetails = async (): Promise<BrowserDetails> => {
  const userAgent = navigator.userAgent
  const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop'

  const osMap = [
    { name: 'Windows', pattern: /Win/i },
    { name: 'MacOS', pattern: /Mac/i },
    { name: 'Linux', pattern: /Linux/i },
    { name: 'Android', pattern: /Android/i },
    { name: 'iOS', pattern: /like Mac/i }
  ]

  const osType =
    osMap.find((os) => os.pattern.test(userAgent))?.name || 'Unknown OS'
  const browserTypeAndVersion = userAgent
  const screenResolution = `${screen.width}x${screen.height}`
  const viewportSize = `${window.innerWidth}x${window.innerHeight}`
  const colorDepth = screen.colorDepth
  const languagePreference = navigator.language

  return {
    deviceType,
    osType,
    browserTypeAndVersion,
    screenResolution,
    viewportSize,
    colorDepth,
    languagePreference
  }
}

export const addIdToEvents = (events: AnalyticsEvent[]) => {
  return events?.map((event) => {
    return {
      id: uuidv4(),
      ...event
    }
  })
}

export const sanitizeEvents = (events: AnalyticsEvent[]) => {
  return events.map((event) => {
    if (event.type === 'step_navigation') {
      const { fromStepNumber, fromStepName, toStepNumber, toStepName } =
        event.details

      event.details = {
        ...(fromStepName && fromStepNumber
          ? { fromStepNumber, fromStepName }
          : {}),
        ...(toStepNumber && toStepName ? { toStepNumber, toStepName } : {})
      }
    }

    return event
  })
}
