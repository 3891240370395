import { formatPriceUnit } from '@epilot/pricing'
import type { Price } from '@epilot/pricing-client'
import type { TFunction } from 'i18next'

import { formatNumberToLocaleString } from './pricing'

export function getFormattedQuantity(componentPrice: Price, t: TFunction) {
  if (
    !componentPrice?.blockMappingData ||
    typeof componentPrice.blockMappingData.numberInput !== 'number' ||
    componentPrice.blockMappingData.numberInput < 0
  ) {
    return
  }

  const input = componentPrice.blockMappingData.numberInput
  const unit = formatUnitWithSuffix(componentPrice?.unit, input, t)
  const frequency =
    componentPrice.type === 'recurring'
      ? componentPrice.blockMappingData.frequencyUnit
      : undefined

  return `${formatNumberToLocaleString(input, 2)} ${unit}${
    frequency ? ' ' + t(`recurring.${frequency}`) : ''
  }`
}

export function formatUnitWithSuffix(
  unit: string | undefined,
  input: number,
  t: TFunction
) {
  const formattedUnit = formatPriceUnit(unit)
  const unitSuffix = input > 1 ? '_plural' : ''

  return `${t(
    `selectvalues.Price.unit.${formattedUnit}${unitSuffix}`,
    formattedUnit
  )}`
}
