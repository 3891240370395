import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import {
  BodyText,
  Typography,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import type {
  BillingPeriod,
  Price,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import { DEFAULT_CURRENCY } from '@epilot/pricing'
import type { TFunction } from 'i18next'

type ProductDetailsPriceInformationTotalRecurrenceProps = {
  recurrence: RecurrenceAmount
  showTrailingDecimalZeros?: boolean
  t: TFunction
  quantity: number
  priceDisplayInJourneys: Price['price_display_in_journeys']
  currency?: string
  shouldHideRecurrence?: boolean
}

export const ProductDetailsPriceInformationTotalRecurrence = ({
  recurrence,
  priceDisplayInJourneys,
  showTrailingDecimalZeros,
  t,
  quantity,
  currency = DEFAULT_CURRENCY,
  shouldHideRecurrence
}: ProductDetailsPriceInformationTotalRecurrenceProps) => {
  const classes = useStyles()

  const amountToDisplay = shouldShowDecimals(recurrence.amount_total)
    ? recurrence.amount_total_decimal
    : recurrence.amount_total

  return (
    <BodyText className={classes.item} color="textPrimary">
      {quantity > 1 && (
        <Typography
          data-testid="product-details-recurrence-quantity"
          variant="caption"
        >
          {`(${quantity} * ${getDisplayPrice(
            t,
            recurrence.unit_amount_gross,
            priceDisplayInJourneys,
            showTrailingDecimalZeros,
            currency
          )})`}
        </Typography>
      )}{' '}
      {!shouldHideRecurrence && (
        <span className={classes.recurrence}>
          {t(
            getPriceType(
              recurrence.type as Price['type'],
              recurrence.billing_period as BillingPeriod
            )
          )}{' '}
        </span>
      )}
      <strong data-testid="product-details-recurrence-amount">
        {getDisplayPrice(
          t,
          amountToDisplay,
          priceDisplayInJourneys,
          showTrailingDecimalZeros,
          currency
        )}
      </strong>
    </BodyText>
  )
}

const useStyles = makeStyles<Theme & EpilotTheme>(() =>
  createStyles({
    item: {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'end',
      justifyContent: 'flex-end'
    },
    recurrence: {
      textTransform: 'lowercase'
    }
  })
)
