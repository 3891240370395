import { isEmpty } from 'radashi'

import { getLogicsSkipStep } from '../display/getLogicsSkipStep'
import type { Logic, Step } from '../utils'
import { blockController } from '../utils'

import { getLogicsNavigateFromStep } from './getLogicsNavigateFromStep'

/**
 * Checks if the steps contain any navigation logic, and any action bar contains a different target step than the logically next one.
 * If any of this is true, the journey is not linear anymore
 *
 * WARNING: Expensive operation, use it wisely and cache the end-result as much as you can
 *
 * TODO: Consider moving this logic to the server?
 */
export const isLinearJourney = (
  steps: Step[] = [],
  logics: Logic[] = [],
  options: { sourceControl?: string } = {}
): boolean => {
  const { sourceControl = 'ActionBarControl' } = options

  // check if action button contains targetStepId
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i]
    const nextStepByIndex: Step | undefined = steps[i + 1]
    const actionButtons = blockController.findBlocks(
      { ...step, stepIndex: i },
      { type: sourceControl }
    )
    let rewiredNavigation = false

    // check only if next step exists
    if (nextStepByIndex) {
      const nextStepIdByIndex = nextStepByIndex.stepId

      // go through each button on step and check if next step ids are rewired
      actionButtons.forEach((actionButton) => {
        // no rewiring if not defined
        const nextId = actionButton.uischema?.options?.ctaButton.targetStepId

        if (nextId && nextId !== nextStepIdByIndex) {
          rewiredNavigation = true
        }
      })
    }

    if (rewiredNavigation) {
      return false
    }
  }

  // check if logics contain navigation logic
  const navigationLogics = getLogicsNavigateFromStep(steps, logics)
  const skipStepLogics = getLogicsSkipStep(steps, logics)

  if (!isEmptyArray(navigationLogics) || !isEmptyArray(skipStepLogics))
    return false

  return true
}

const isEmptyArray = <T>(array: Array<T>): boolean => {
  return isEmpty(array) || (Array.isArray(array) && array.every(isEmpty))
}
