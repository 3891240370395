// Generated by ts-to-zod
import { z } from 'zod'

export const universallyUniqueIdSchema = z.string()

export const iSODateStringSchema = z.string()

export const sortableSchema = z.object({
  orderIndex: z.number()
})
