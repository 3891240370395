// Generated by ts-to-zod

import { universallyUniqueIdSchema } from './common.zod'

export const referenceIdSchema = universallyUniqueIdSchema

export const automationIdSchema = universallyUniqueIdSchema

export const mappingConfigIdSchema = universallyUniqueIdSchema

export const blockIdSchema = universallyUniqueIdSchema

export const organizationIdSchema = universallyUniqueIdSchema

export const organizationEntityIdSchema = universallyUniqueIdSchema

export const journeyIdSchema = universallyUniqueIdSchema

export const journeyEntityIdSchema = universallyUniqueIdSchema

export const userIdSchema = universallyUniqueIdSchema

export const stepIdSchema = universallyUniqueIdSchema

export const contextParameterIdSchema = universallyUniqueIdSchema

export const designIdSchema = universallyUniqueIdSchema

export const logicIdSchema = universallyUniqueIdSchema

export const templateIdSchema = universallyUniqueIdSchema
