import type { ConsentData, Source } from '@epilot/journey-logic-commons'
import {
  ConsentTopics,
  DoubleOptInTopics,
  journeyStorage
} from '@epilot/journey-logic-commons'

import type { BaseEntity } from '../blocks-renderers'
import { getEmailFromToken } from '../blocks-renderers'
import { getEmailOrPhoneFromContact } from '../listeners/submissionUtils'
import type { OptIn } from '../services/submission-service'

import { POSSIBLE_JOURNEY_ENTITIES } from './generateJourneyDataSources'
import type { DataSources } from './types'

type Consents = Record<string, ConsentData>

/**
 * This function generates the OptIns and Consents based on the journey state
 * OptIns stands for double opt in, it must be in the submission payload as opt_ins to be picked up later by the automation and passed to the double opt in service
 *     ex. the user opt in to use Marketing emails => this is true for this submission or any existing data from other submissions or data sources
 * Consents on the other hand are agreement for a single entity. It will be placed in the submission payload.entities[0].consents
 *     ex. the user agrees to the GTC => this is true ONLY for this submission => so it is local to the Opportunity entity or the Order entity
 * IMPORTANT: it is assumed in this function that the journey might have multiple consent blocks and multiple personal information blocks so it would look for them in the "locations" and "userLocations" of the sources
 * In the case of multiple PIs, usually in the userLocations they are already sorted by priority, so the first one is the customer/primary Contact. the others are there as fall back in case nothing was labelled as customer
 */
export function generateOptIns(
  dataSources: DataSources,
  journeyState: Record<string, unknown>[],
  loggedInContact?: BaseEntity
): {
  optins: OptIn[]
  consents: Consents
} {
  const loggedInContactEmail = getEmailOrPhoneFromContact(
    'email',
    loggedInContact
  )
  const loggedInContactPhone = getEmailOrPhoneFromContact(
    'phone',
    loggedInContact
  )

  const opts: OptIn[] = []
  const consents: Consents = {}
  const sources = dataSources[POSSIBLE_JOURNEY_ENTITIES.CONSENTS]

  if (sources && sources.locations && journeyState) {
    const locations = sources.locations as Source[]

    locations.forEach((loc: Source) => {
      const data = journeyState[loc.stepIndex][loc.name]
      let userEmail: string | undefined = ''
      let userPhone = ''

      for (const userLocation of sources.userLocations) {
        // if the email is available from a block, pick it and break the loop
        userEmail = (
          journeyState[userLocation.stepIndex][userLocation.name] as {
            email: string
          }
        )?.email

        if (userEmail) break
      }

      // if still the email is not available from a block, try to get it from the token
      // priority is given for the email coming from the logged in Contact
      if (!userEmail) {
        if (loggedInContactEmail) {
          userEmail = loggedInContactEmail
        } else {
          const token = journeyStorage.getItem('token')
          const emailFromToken = token && getEmailFromToken(token)

          if (emailFromToken) {
            userEmail = emailFromToken
          }
        }
      }

      for (const userLocation of sources.userLocations) {
        userPhone = (
          journeyState[userLocation.stepIndex][userLocation.name] as {
            telephone: string
          }
        )?.telephone

        if (userPhone) break
      }

      if (!userPhone && loggedInContactPhone) {
        userPhone = loggedInContactPhone
      }

      // each key is consent topic
      // accept only double optin topics here
      /**
       * @todo Should also check that data is not null
       * e.g. typeof data === 'object' && data !== null
       * After that, type castings for "data as NonNullable<typeof data>"
       * can be removed
       */
      typeof data === 'object' &&
        Object.keys(data as NonNullable<typeof data>).forEach((topic) => {
          const consent = (data as NonNullable<typeof data>)[
            topic
          ] as ConsentData

          // if topicValue is true and it is not yet part of the OptIns (it might be there are multiple blocks with the same topic = true)
          if (DoubleOptInTopics.includes(topic)) {
            // if no phone number is available, do not add phone call or sms marketing to the optins since we lack identifier
            // usually the telephone number is required for these topics
            if (
              [
                ConsentTopics.PHONE_CALL_MARKETING,
                ConsentTopics.SMS_MARKETING
              ].includes(topic) &&
              !userPhone
            ) {
              return
            }

            if (
              userEmail &&
              consent.agreed &&
              opts.findIndex((opt) => opt.topic === topic) === -1
            ) {
              opts.push({
                // type: "OPT_IN"|"OPT_OUT",
                topic: topic,
                meta: {
                  user: {
                    username: userEmail
                  },
                  text: consent.text,
                  time: consent.time
                },
                identifier: [
                  ConsentTopics.PHONE_CALL_MARKETING,
                  ConsentTopics.SMS_MARKETING
                ].includes(topic)
                  ? userPhone
                  : userEmail
              })
            }
          } else {
            consents[topic] = consent
          }
        })
    })
  }

  return { optins: opts, consents: consents }
}
