import type { PortalConfig } from '@epilot/customer-portal-client'
import { useQuery } from 'react-query'

import { useConfig } from '../providers'
import { getPortalConfig } from '../services/auth-service'

type PortalConfigProps = {
  organizationId: string | undefined
}

type PortalConfigValues = ReturnType<typeof useQuery<PortalConfig>>

export const usePortalConfig = ({
  organizationId
}: PortalConfigProps): PortalConfigValues => {
  const { CUSTOMER_PORTAL_API_URL } = useConfig()

  // TODO: Optimize settings to lazy load/retry but not overfetch
  return useQuery(
    ['use-portal-config', organizationId],
    async () => {
      if (!organizationId) {
        return Promise.reject('Expected organizationId to be set')
      }

      const response = await getPortalConfig(
        CUSTOMER_PORTAL_API_URL,
        organizationId
      )

      return response.data
    },
    {
      enabled: Boolean(organizationId)
    }
  )
}
