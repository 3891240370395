import {
  CONTROL_NAME,
  getStringSourceFromObjectSource
} from '@epilot/journey-logic-commons'
import type {
  StepState,
  Source,
  JourneyCartItem,
  JourneyContextValue,
  JourneyCartItemProduct,
  HistoryStackState
} from '@epilot/journey-logic-commons'

/**
 * @todo Add checks for 'selectedPriceId' and 'selectedProductId'
 */
const isValidJourneyCartItemProduct = (
  product: unknown
): product is JourneyCartItemProduct =>
  product !== null && typeof product === 'object'

/**
 * @todo Add check for 'quantity'
 */
const isValidJourneyCartItem = (item: unknown): item is JourneyCartItem =>
  item !== null &&
  typeof item === 'object' &&
  'product' in item &&
  /**
   * @todo Remove assertion after TypeScript version has been updated
   */
  isValidJourneyCartItemProduct((item as { product: unknown }).product)

/* Value set by JSONForms can be anything, ensure it conforms to JourneyCartItem format */
const extractJourneyCartItemsFromValue = (
  value: unknown
): JourneyCartItem | Array<JourneyCartItem> | null =>
  Array.isArray(value)
    ? value.filter(isValidJourneyCartItem)
    : isValidJourneyCartItem(value)
      ? value
      : null

type PreparedDataForContext = Pick<
  JourneyContextValue,
  | '_stepsHistoryStateArray'
  | '_stepsStateObject'
  | '_stepsHistoryStateObject'
  | '_allProductSelectors'
  | '_shoppingCart'
>

export const prepareDataForContext = (
  stepsState: StepState[],
  journeySources: Source[] = [],
  historyStack: HistoryStackState['stack']
) => {
  const historyIndexes = historyStack.map(({ stepIndex }) => stepIndex)

  return stepsState.reduce<PreparedDataForContext>(
    (acc, state, index) => {
      if (!state) return acc

      const isInHistory = historyIndexes.includes(index)

      // if in history stack, then add it to the state history array
      if (isInHistory) {
        acc._stepsHistoryStateArray.push(state)
      }

      Object.entries(state).forEach(([key, value]) => {
        const source = journeySources.find(
          (source) => source.name === key && source.stepIndex === index
        )

        if (!source) {
          /* This should not occur */
          return
        }

        const stringSource = getStringSourceFromObjectSource(source)

        const isProductSelector =
          source.controlNameId === CONTROL_NAME.PRODUCT_SELECTION_CONTROL

        // add the data to the object
        acc._stepsStateObject[stringSource] = value

        // if it is part of history add it to the object
        if (isInHistory) {
          acc._stepsHistoryStateObject[stringSource] = value
        }

        if (isProductSelector) {
          const extractedItems = extractJourneyCartItemsFromValue(value)

          if (extractedItems) {
            acc._allProductSelectors[stringSource] = extractedItems

            if (isInHistory) {
              acc._shoppingCart[stringSource] = extractedItems
            }
          }
        }
      })

      return acc
    },
    {
      _stepsHistoryStateArray: [],
      _stepsStateObject: {},
      _stepsHistoryStateObject: {},
      _allProductSelectors: {},
      _shoppingCart: {}
    }
  )
}
