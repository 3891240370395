import { computeAggregatedAndPriceTotals } from '@epilot/pricing'
import type { Price, PriceItem, Product } from '@epilot/pricing-client'
import type { TFunction } from 'i18next'

import { getPricingDetailsFormatted } from '../../utils'
import type { UiSchema } from '../../utils'
import type { BlockProperties, BlockVariableConfig } from '../types'

type BlockState = any

export const productSelectionBlockProperties = [
  'product_name',
  'product_description',
  'unit_amount',
  'amount_total',
  'amount_subtotal',
  'quantity',
  'billing_period',
  'unit'
] as const

export const getVariablesFromProductSelectionBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const prefix = uiSchema.options?.selectionType === 'many' ? 'index.' : '0.'
  const properties = productSelectionBlockProperties.map(
    (property) => prefix + property
  )

  return {
    isAccessedByIndex: true,
    length: 1,
    properties: properties as BlockProperties[]
  }
}

export type ProductSelectionBlockProperties =
  (typeof productSelectionBlockProperties)[number]

export const getValuesFromProductSelectionBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction<'translation', undefined>
}) => {
  if (!blockState) {
    return {}
  }

  const productT = (key: string, options?: any) =>
    t(`product.selection.${key}`, options)

  const blockStateArray = Array.isArray(blockState) ? blockState : [blockState]

  const data = [] as any

  blockStateArray.forEach((selectedProduct) => {
    const selectedProductData = {} as any

    productSelectionBlockProperties.forEach((property) => {
      const value = getPropertyValue(
        property,
        selectedProduct,
        productT as TFunction
      )

      if (!value) {
        return
      }

      selectedProductData[property] = value
    })

    data.push(selectedProductData)
  })

  return data as any[]
}

const getPropertyValue = (
  property: ProductSelectionBlockProperties,
  selectedProduct: BlockState,
  productT: TFunction
) => {
  const selectedProductMetadata: Product =
    selectedProduct.product.selectionMetadata.selectedProduct
  const selectedPriceMetadata: Price =
    selectedProduct.product.selectionMetadata.selectedPrice
  const blockMappings = selectedPriceMetadata.blockMappingData && [
    {
      frequency_unit: selectedPriceMetadata.blockMappingData.frequencyUnit,
      value: selectedPriceMetadata.blockMappingData.numberInput,
      price_id: selectedPriceMetadata._id
    }
  ]

  const totals = computeAggregatedAndPriceTotals([
    {
      ...selectedPriceMetadata,
      _price: selectedPriceMetadata,
      quantity: selectedProduct.quantity,
      ...(blockMappings?.length > 0 && {
        price_mappings: blockMappings
      })
    }
  ])
  const item = totals.items?.[0] as PriceItem
  const unit =
    item._price?.unit &&
    productT(`selectvalues.Price.unit.${item._price?.unit}`, item._price?.unit)

  const { unitAmount, amountTotal, amountSubtotal, billingPeriod, quantity } =
    getPricingDetailsFormatted(
      item,
      selectedProduct.quantity || 1,
      item._price?.blockMappingData?.numberInput || 1,
      productT,
      true
    )

  let value

  switch (property) {
    case 'product_name':
      value = selectedProductMetadata.name
      break
    case 'product_description':
      value = selectedProductMetadata.description
      break
    case 'unit_amount':
      value = unitAmount
      break
    case 'amount_total': {
      value = amountTotal
      break
    }
    case 'amount_subtotal': {
      value = amountSubtotal
      break
    }
    case 'quantity': {
      value = quantity
      break
    }
    case 'billing_period': {
      value = billingPeriod
      break
    }
    case 'unit':
      value = unit
      break
    default:
      value = (selectedProduct as BlockState)[property]
  }

  return value
}
