import { v4 as uuidv4 } from 'uuid'

import { env } from '../utils/config'

import type {
  AnalyticsEvent,
  CreateSessionPayload,
  LastStepNavigated,
  SessionDetails,
  StepInfo
} from './types'
import { addIdToEvents, fetchBrowserDetails, sanitizeEvents } from './utils'

const analyticsCategoryPayload = {
  category: 'journey',
  categoryDetails: {
    journeyId: ''
  }
}

let currentSession: SessionDetails
let journeyToken: string
let lastStepNavigated: LastStepNavigated
let sessionPayload: CreateSessionPayload | null = null
let sessionCreationEvents: AnalyticsEvent[] = []

export const setJourneyToken = (token: string) => {
  journeyToken = token
}

export const setLastStepNavigated = (stepInfo: StepInfo, journeyId: string) => {
  try {
    const { stepNumber, stepName } = stepInfo

    if (stepNumber && stepName) {
      lastStepNavigated = {
        [journeyId]: stepInfo
      }
    }
  } catch (err) {
    console.error(`Failed to set last step navigated: ${err}`)
  }
}

export const handleFirstUserEngagementWithJourney = async () => {
  if (sessionPayload) {
    const { journeyId } = sessionPayload

    await createSession(sessionPayload)
    addEvents(sessionCreationEvents)
    setLastStepNavigated(
      {
        stepNumber: sessionCreationEvents?.[1]?.details?.toStepNumber as number,
        stepName: sessionCreationEvents?.[1]?.details?.toStepName as string
      },
      journeyId
    )
  }
}

export const initializeSessionDetails = (
  createSessionPayload: CreateSessionPayload,
  sessionCreationEventsPayload: AnalyticsEvent[] = []
) => {
  sessionPayload = createSessionPayload
  sessionCreationEvents = sessionCreationEventsPayload
}

export const createSession = async (
  createSessionPayload: CreateSessionPayload
): Promise<void> => {
  try {
    // End existing session if it exists before creating a new one
    if (currentSession?.id) {
      await endSession()
    }
    const { embeddedIn, isLauncherJourney, journeyId, journeyName } =
      createSessionPayload

    analyticsCategoryPayload.categoryDetails = { journeyId }
    const browserDetails = await fetchBrowserDetails()

    const sessionDetails = {
      ...browserDetails,
      embeddedIn,
      isLauncherJourney,
      journeyName
    }

    const session = {
      id: uuidv4(),
      startTime: new Date().toISOString(),
      details: sessionDetails
    }

    const createSessionRequestBody = {
      ...analyticsCategoryPayload,
      session
    }

    fetch(`${env('REACT_APP_ANALYTICS_API_BASE_URL')}/v1/analytics/session`, {
      method: 'POST',
      body: JSON.stringify(createSessionRequestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${journeyToken}`
      },
      keepalive: true
    })

    currentSession = session
  } catch (err) {
    console.error(`Failed to create session: ${err}`)
  }
}

export const addEvents = (events: AnalyticsEvent[]) => {
  try {
    const { id } = currentSession

    events = addIdToEvents(events)
    events = sanitizeEvents(events)

    if (!id) {
      console.error('Cannot add events without a session')

      return
    }

    const addEventsRequestBody = {
      ...analyticsCategoryPayload,
      events
    }

    fetch(
      `${env(
        'REACT_APP_ANALYTICS_API_BASE_URL'
      )}/v1/analytics/session/${id}/events`,
      {
        method: 'POST',
        body: JSON.stringify(addEventsRequestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${journeyToken}`
        },
        keepalive: true
      }
    )
  } catch (err) {
    console.error(`Failed to queue events: ${err}`)
  }
}

export const endSession = async () => {
  try {
    const { id } = currentSession

    if (!id) {
      console.error('Cannot terminate session without an existing session')

      return
    }

    const addEventsRequestBody = {
      ...analyticsCategoryPayload,
      events: [
        {
          id: uuidv4(),
          type: 'journey_exit',
          details:
            lastStepNavigated?.[
              analyticsCategoryPayload?.categoryDetails?.journeyId
            ],
          timestamp: new Date().toISOString()
        }
      ]
    }

    const updateSessionRequestBody = {
      ...analyticsCategoryPayload,
      session: {
        ...currentSession,
        endTime: new Date().toISOString()
      }
    }

    fetch(
      `${env(
        'REACT_APP_ANALYTICS_API_BASE_URL'
      )}/v1/analytics/session/${id}/events`,
      {
        method: 'POST',
        body: JSON.stringify(addEventsRequestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${journeyToken}`
        },
        keepalive: true
      }
    )

    await fetch(
      `${env('REACT_APP_ANALYTICS_API_BASE_URL')}/v1/analytics/session/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(updateSessionRequestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${journeyToken}`
        },
        keepalive: true
      }
    )
  } catch (err) {
    console.error(`Failed to end session: ${err}`)
  }
}
