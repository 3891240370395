import { useMemo } from 'react'

import type { ExtendedCustomerPortalClient } from '../clients/customer-portal-client'
import { getCustomerPortalClient } from '../clients/customer-portal-client'
import type { EntityClient } from '../clients/entity-client'
import { getEntityClient } from '../clients/entity-client'
import { useConfig } from '../providers'
import { usePortalCheck } from '../utils'

type ReturnValues =
  | {
      client: EntityClient
      isPortal: false
    }
  | {
      client: ExtendedCustomerPortalClient
      isPortal: true
    }

// TODO: This should be refactored to explicitly coerce TS types according to its context.
// Alternatively, it would be better to explicitly use two distinct clients/hooks according
// to whether we're in ECP context.
export const useEntityOrCustomerPortalClient = (): ReturnValues => {
  const { isECPPortal } = usePortalCheck()
  const { CUSTOMER_PORTAL_API_URL, ENTITY_API_URL } = useConfig()

  return useMemo(() => {
    if (isECPPortal) {
      return {
        client: getCustomerPortalClient(CUSTOMER_PORTAL_API_URL),
        isPortal: true
      }
    }

    return {
      client: getEntityClient(ENTITY_API_URL),
      isPortal: false
    }
  }, [isECPPortal, CUSTOMER_PORTAL_API_URL, ENTITY_API_URL])
}
