import {
  format,
  isDate,
  isValid,
  getHours,
  getMinutes,
  getSeconds,
  parseISO
} from 'date-fns'

import { getLocaleDateString } from './getLocaleDateString'

/**
 * Converts a given date or date string to a formatted date string with time based on the user's locale.
 *
 * @param date - The date or date string to be formatted. Can be a Date object, a string in `DD/MM/YYYY` format, or undefined.
 * @param stripTime - If true, the time will always be stripped from the formatted date string.
 *                    If false, the time will always be included.
 *                    If undefined, the time will be included if different from 0:00:000.
 *                    Defaults to undefined.
 * @returns The formatted date string. Returns an empty string if the input is undefined or invalid.
 */
export const toDateString = (
  date: Date | string | undefined,
  stripTime?: boolean,
  lang?: string
): string => {
  if (date && date !== null) {
    let showTime = false
    let dateObject: Date

    if (typeof date === 'string') {
      if (date.length === 10) {
        stripTime = true
      }
      dateObject = parseISO(date)
    } else if (isDate(date)) {
      dateObject = date
    } else {
      console.error(
        'toDateString -> detected incorrect date value. Current value is',
        { date }
      )

      return ''
    }

    if (isValid(dateObject)) {
      if (
        (stripTime == undefined &&
          (getHours(dateObject) !== 0 ||
            getMinutes(dateObject) !== 0 ||
            getSeconds(dateObject) !== 0)) ||
        stripTime == false
      ) {
        showTime = true
      }

      const language = lang ? LANG_DICT[lang] : navigator.language

      const formatClient = getLocaleDateString(language, showTime)

      return format(dateObject, formatClient)
    } else {
      console.error(
        'toDateString -> detected incorrect date value. Current value is',
        { date }
      )
    }
  }

  return ''
}

const LANG_DICT: Record<string, string> = {
  en: 'en-US',
  de: 'de-DE'
}
