// Generated by ts-to-zod
import { z } from 'zod'

import { REFERENCED_TYPE } from '../../../types/next'

import {
  blockIdSchema,
  contextParameterIdSchema,
  referenceIdSchema
} from './ids.zod'

export const referenceSchema = z.object({
  id: referenceIdSchema,
  referenceType: z.string(),
  referenceId: z.string(),
  meaning: z.string()
})

export const blockReferenceSchema = referenceSchema.extend({
  referenceType: z.literal(REFERENCED_TYPE.BLOCK),
  referenceId: blockIdSchema
})

export const contextParameterReferenceSchema = referenceSchema.extend({
  referenceType: z.literal(REFERENCED_TYPE.CONTEXT_PARAMETER),
  referenceId: contextParameterIdSchema
})

export const referenceableSchema = z.object({
  references: z.record(referenceIdSchema, referenceSchema).optional()
})
