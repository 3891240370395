import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useProductBlockSummaryDataStyles = makeStyles<Theme & EpilotTheme>(
  (theme) =>
    createStyles({
      container: {
        display: 'flex',
        gap: theme.spacing(6),
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row'
        },
        marginTop: 0
      },
      imageContainer: {
        flex: 1,
        display: 'grid',
        '& img': {
          borderRadius: 4
        }
      },
      descriptionContainer: {
        flex: 2,
        '& ul': {
          display: 'none'
        }
      },
      heading: {
        display: 'none'
      },
      descriptionHeading: {
        marginBottom: theme.spacing(2)
      },
      detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          '& > div': {
            flex: 1
          }
        }
      },
      detailsContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        padding: 0,
        '& li, & div': {
          backgroundColor: 'inherit',
          padding: 0,
          margin: 0
        }
      },
      detailsContentNoInformation: {
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          '& > div': {
            flex: 1
          }
        }
      },
      paragraphContainer: {
        marginBottom: theme.spacing(4)
      },
      notesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
      },
      summaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        marginTop: theme.spacing(4)
      },
      summaryItem: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing(3)
      },
      divider: {
        width: '100%',
        borderStyle: 'dashed',
        borderWidth: '1px 0 0 0',
        borderColor: theme.palette?.text?.primary
      },
      downloadsContainer: {
        gap: theme.spacing(2),
        '& > a': {
          gap: theme.spacing(1)
        }
      }
    })
)
