import { toDateString } from '@epilot/journey-logic-commons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SessionSelectorDialogConcorde } from './SessionSelectorDialogConcorde'
import { SessionSelectorDialogMUI } from './SessionSelectorDialogMUI'
import type { SessionSelectorDialogProps } from './types'

export function SessionSelectorDialog({
  open,
  availableSessions,
  onSessionSelected,
  onDiscard,
  useNewDesign
}: SessionSelectorDialogProps) {
  const { t } = useTranslation()
  const { sessionsIds, sessionsLabel } = useMemo(() => {
    const ids = availableSessions.map((session) => session.id)
    const labels = availableSessions.map(
      (session) =>
        `${t('user_progress.session_from', 'Session From')} ${toDateString(session.date)}`
    )

    return { sessionsIds: ids, sessionsLabel: labels }
  }, [availableSessions, t])

  const [selectedId, setSelectedId] = useState<string | undefined>()

  return useNewDesign ? (
    <SessionSelectorDialogConcorde
      onDiscard={onDiscard}
      onSessionSelected={onSessionSelected}
      open={open}
      sessionsIds={sessionsIds}
      sessionsLabel={sessionsLabel}
      setSelectedId={setSelectedId}
      value={selectedId}
    />
  ) : (
    <SessionSelectorDialogMUI
      onDiscard={onDiscard}
      onSessionSelected={onSessionSelected}
      open={open}
      sessionsIds={sessionsIds}
      sessionsLabel={sessionsLabel}
      setSelectedId={setSelectedId}
      value={selectedId}
    />
  )
}
