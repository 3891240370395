export type BackOffHandler = (
  timeoutMs: number,
  retries: number,
  maxWaitMs?: number
) => number

/**
 * Constructs an exponential backoff strategy, returning the next timeout in ms
 * according to the number of retries.
 *
 * NOTE: Retries should always start at 1, otherwise will throw an error.
 */
export const backOffExponential: BackOffHandler = (
  timeoutMs,
  retries,
  maxWaitMs
) => {
  if (retries < 1) {
    throw new Error('Implementation error, retries must be greater than 0')
  }

  const backoff = timeoutMs * Math.pow(2, retries - 1) // i.e. 10, 20, 40, 80, etc.

  if (typeof maxWaitMs !== 'undefined') {
    return Math.min(maxWaitMs, backoff)
  }

  return backoff
}

/**
 * Constructs a Fibonacci backoff strategy, returning the next timeout in ms
 * according to the number of retries.
 *
 * NOTE: Retries should always start at 1, otherwise will throw an error.
 */
export const backOffFibonacci: BackOffHandler = (
  timeoutMs,
  retries,
  maxWaitMs
) => {
  if (retries < 1) {
    throw new Error('Implementation error, retries must be greater than 0')
  }

  // Calculate Fibonacci number for the retry attempt
  let prev = 1
  let current = 1

  for (let i = 3; i <= retries; i++) {
    const next = prev + current

    prev = current
    current = next
  }

  const backoff = timeoutMs * current // i.e. 10, 10, 20, 30, 50, 80, etc.

  if (typeof maxWaitMs !== 'undefined') {
    return Math.min(maxWaitMs, backoff)
  }

  return backoff
}
