import type { NavigateFromStepLogic } from '../navigation/getLogicsNavigateFromStep'
import type { AvailabilityUserFiltersValue } from '../services/address-suggestions-service'
import { availabilityCheck } from '../services/address-suggestions-service'
import type { ProductAvailabilityUserFiltersValue } from '../services/pricing-service'
import { productAvailabilityCheck } from '../services/pricing-service'

import { computeLiveValue } from './computeLiveValue'
import {
  parseLogicConditionValueFromInternalIntegration as parseInternalIntegrationValue,
  parseLogicConditionValue
} from './logicRegEx'
import { logicOperators } from './staticLists'
import type { LogicCondition, Journey, LiveValue } from './types'

import { AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY } from '.'

export const getLogicAvailabilityCheckParams = ({
  nextProductBlocksRelatedToStep
}: NavigateFromStepLogic) => ({
  availabilityCheckProductIds: nextProductBlocksRelatedToStep?.reduce(
    (prev, current) => {
      return prev.concat(
        current?.uischema?.options?.products
          .map((product: { productId: string }) => product?.productId)
          .filter(Boolean) || []
      )
    },
    []
  )
})

const getConditionValue = async (
  {
    conditionValue,
    conditionValueFromInternalIntegration: internalIntegrationConfigValue
  }: LogicCondition,
  liveValue: unknown | AvailabilityUserFiltersValue,
  context?: ConditionEvaluationContext
): Promise<string | { liveValue: boolean | string } | undefined> => {
  if (internalIntegrationConfigValue) {
    const internalFunctionName = parseInternalIntegrationValue(
      internalIntegrationConfigValue
    )

    switch (internalFunctionName) {
      case 'productAvailabilityCheck': {
        const hasProductsToCheckAvailability = Boolean(
          context?.conditionLogicConfig.nextProductBlocksRelatedToStep
        )

        if (!hasProductsToCheckAvailability || !context) {
          if (!context) {
            // eslint-disable-next-line no-console
            console.error(
              'Context is required to check product availability, but none was provided.'
            )
          }

          return { liveValue: false }
        }

        const { availabilityCheckProductIds } = getLogicAvailabilityCheckParams(
          context.conditionLogicConfig || ({} as NavigateFromStepLogic)
        )

        const availableProducts = await productAvailabilityCheck(
          availabilityCheckProductIds,
          liveValue as ProductAvailabilityUserFiltersValue
        )

        context.conditionLogicConfig.nextProductBlocksRelatedToStep.forEach(
          (step) => {
            context.journeyConfigMutator(
              step.stepId,
              step.name,
              AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY,
              { availableProducts }
            )
          }
        )

        const hasAvailableProducts = !!availableProducts?.length

        return { liveValue: hasAvailableProducts }
      }
      case 'availabilityCheck': {
        const fileId = parseLogicConditionValue(conditionValue)

        if (!fileId || !context) {
          if (!context) {
            // eslint-disable-next-line no-console
            console.error(
              'Context is required to check availability, but none was provided.'
            )
          }

          return { liveValue: false }
        }

        const isAddressAvailable = await availabilityCheck(
          fileId,
          liveValue as AvailabilityUserFiltersValue
        )

        return { liveValue: isAddressAvailable }
      }
      default:
        throw new Error('Unsupported integration: ' + internalFunctionName)
    }
  } else {
    return conditionValue?.endsWith('%%')
      ? parseLogicConditionValue(conditionValue)
      : conditionValue
  }
}

export interface ConditionEvaluationContext {
  conditionLogicConfig: NavigateFromStepLogic
  journeyState: Journey | undefined

  journeyConfigMutator: (
    targetStepId: string,
    targetBlockName: string,
    targetFieldName: string,
    dataValue: unknown
  ) => Journey | undefined
}

const isNumberInputValue = (
  value: LiveValue | undefined
): value is Record<string, unknown> => {
  return typeof value === 'object' && !Array.isArray(value)
}

/**
 * Attempts to evaluate whether a given condition is true or not based on the given initialValue and the condition
 * @param initialLiveValue if there is no initial value, it possibly defaults to false
 */
export async function isConditionTrue(
  condition: LogicCondition,
  initialLiveValue?: unknown,
  context?: ConditionEvaluationContext
) {
  const liveValue = computeLiveValue(condition, initialLiveValue)
  const conditionValue = await getConditionValue(condition, liveValue, context)

  return evaluateCondition(condition, liveValue, conditionValue)
}

const evaluateCondition = (
  condition: LogicCondition,
  liveValue: LiveValue | undefined,
  conditionValue: string | { liveValue: boolean | string } | undefined
) => {
  if (
    condition.conditionValueFromInternalIntegration &&
    /* Check if should bypass operator logic */
    typeof conditionValue === 'object' &&
    'liveValue' in conditionValue
  ) {
    return conditionValue.liveValue
  }

  let cantUseComma = false
  let separator = ','

  if (Array.isArray(liveValue)) {
    cantUseComma = liveValue.some((value) => {
      return typeof value === 'string' ? value.includes(',') : false
    })
  } else if (typeof liveValue === 'string') {
    cantUseComma = liveValue.includes(',')
  }

  if (String(conditionValue).includes('///') || cantUseComma) {
    separator = '///'
  }

  switch (condition.conditionOperator) {
    case logicOperators.Equal: {
      if (conditionValue === liveValue) {
        return true
      }

      if (conditionValue === `${liveValue}`) {
        return true
      }

      if (Array.isArray(liveValue) && typeof conditionValue === 'string') {
        // only match if exactly one item was selected
        return liveValue.length === 1 && liveValue.includes(conditionValue)
      }

      if (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) === Number(conditionValue)
      ) {
        return true
      }

      return false
    }

    case logicOperators.Greater: {
      return (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) > Number(conditionValue)
      )
    }

    case logicOperators.GreaterOrEqual: {
      return (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) >= Number(conditionValue)
      )
    }

    case logicOperators.Less: {
      return (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) < Number(conditionValue)
      )
    }

    case logicOperators.LessOrEqual: {
      return (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) <= Number(conditionValue)
      )
    }

    case logicOperators.Between: {
      const separatedCondition = String(conditionValue).split(separator)

      return (
        isNumberInputValue(liveValue) &&
        Number(liveValue.numberInput) >= Number(separatedCondition[0]) &&
        Number(liveValue.numberInput) <= Number(separatedCondition[1])
      )
    }

    case logicOperators.NotEqual: {
      if (Array.isArray(liveValue) && typeof conditionValue === 'string') {
        return liveValue.length === 1 && !liveValue.includes(conditionValue)
      }

      return conditionValue !== liveValue
    }

    case logicOperators.NotEmpty: {
      return typeof liveValue === 'boolean' ? true : liveValue ? true : false
    }
    case logicOperators.IsEmpty: {
      return typeof liveValue === 'boolean' ? false : !liveValue
    }
    case logicOperators.In: {
      return conditionValue && liveValue
        ? inOperator(liveValue, String(conditionValue).split(separator))
        : false
    }
    case logicOperators.NotIn: {
      return conditionValue
        ? notInOperator(liveValue, String(conditionValue).split(separator))
        : false
    }
    case logicOperators.ContAll: {
      return conditionValue && liveValue
        ? containsAllOperator(
            liveValue,
            String(conditionValue).split(separator)
          )
        : false
    }
    case logicOperators.ExactMatch: {
      return conditionValue && liveValue
        ? exactMatchOperator(liveValue, String(conditionValue).split(separator))
        : false
    }
    case logicOperators.ContEither: {
      return conditionValue && liveValue
        ? containsEitherOperator(
            liveValue,
            String(conditionValue).split(separator)
          )
        : false
    }
    case logicOperators.ContNot: {
      return conditionValue
        ? containsNotOperator(
            liveValue,
            String(conditionValue).split(separator)
          )
        : true
    }
    default:
      // eslint-disable-next-line no-console
      console.error('Unexpected logic operator.')

      return false
  }
}

// Returns true if every value in condition value is present on the liveValue
const containsAllOperator = (
  liveValue: LiveValue,
  conditionValue: string[]
) => {
  if (!Array.isArray(liveValue)) {
    // eslint-disable-next-line no-console
    console.error('contains All must receive an array as liveValue')

    return false
  }

  for (const value of conditionValue) {
    if (!liveValue.includes(value)) {
      return false
    }
  }

  return true
}

// Returns true if every value in condition value is not present on the liveValue
const containsNotOperator = (
  liveValue: LiveValue | undefined,
  conditionValue: string[]
) => {
  if (typeof liveValue === 'boolean') {
    return !liveValue
  }

  if (!Array.isArray(liveValue)) {
    // eslint-disable-next-line no-console
    console.error('contains Not must receive an array as liveValue')

    return false
  }

  for (const value of conditionValue) {
    if (liveValue.includes(value)) {
      return false
    }
  }

  return true
}

// Returns true if liveValue is exactly the same values as condition
const exactMatchOperator = (liveValue: LiveValue, conditionValue: string[]) => {
  if (!Array.isArray(liveValue)) {
    // eslint-disable-next-line no-console
    console.error('exactMatch operator must receive an array as liveValue')

    return false
  }

  if (liveValue.length !== conditionValue.length) {
    return false
  }

  const sortedLiveValue = liveValue.slice().sort()
  const sortedConditionValue = conditionValue.slice().sort()

  for (let i = 0; i < sortedLiveValue.length; i++) {
    if (sortedLiveValue[i] !== sortedConditionValue[i]) {
      return false
    }
  }

  return true
}

// Returns true if any value in condition value is present on the liveValue
const containsEitherOperator = (
  liveValue: LiveValue,
  conditionValue: string[]
) => {
  if (!Array.isArray(liveValue)) {
    // eslint-disable-next-line no-console
    console.error('orOperator must receive an array as liveValue')

    return false
  }

  for (const value of conditionValue) {
    if (liveValue.includes(value)) {
      return true
    }
  }

  return false
}

// Returns true if the liveValue is contained in conditionValue
const inOperator = (liveValue: LiveValue, conditionValue: string[]) => {
  if (typeof liveValue !== 'string') {
    // eslint-disable-next-line no-console
    console.error('In Operator must receive an string as liveValue')

    return false
  }

  return isValueInCondition(liveValue, conditionValue)
}

// Returns true if the liveValue is NOT contained in conditionValue
const notInOperator = (
  liveValue: LiveValue | undefined,
  conditionValue: string[]
) => {
  if (typeof liveValue === 'boolean') {
    return !liveValue
  }

  if (typeof liveValue !== 'string') {
    // eslint-disable-next-line no-console
    console.error(
      'Not In Operator must receive a string or boolean as liveValue'
    )

    return false
  }

  return !isValueInCondition(liveValue, conditionValue)
}

const isValueInCondition = (
  liveValue: string,
  conditionValue: string[]
): boolean => {
  // First, check for exact match
  if (conditionValue.includes(liveValue)) {
    return true
  }

  // Then, check for number ranges
  const numericLiveValue = Number(liveValue)

  if (!isNaN(numericLiveValue)) {
    for (const value of conditionValue) {
      const range = value?.split('-')

      if (range?.length === 2) {
        const start = Number(range[0])
        const end = Number(range[1])

        if (
          !isNaN(start) &&
          !isNaN(end) &&
          numericLiveValue >= start &&
          numericLiveValue <= end
        ) {
          return true
        }
      }
    }
  }

  return false // If we've reached here, no match was found
}
