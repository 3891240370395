import { Icon } from '@epilot/journey-elements'
import type {
  Design,
  EmbedOptions,
  HistoryStack,
  Journey,
  StepState,
  ValueError
} from '@epilot/journey-logic-commons'
import { getOptimizedFileIdURL } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { useJourneyContext } from '../../../blocks-renderers'
import useUpdateTheme from '../../../hooks/useUpdateTheme'
import { STEPPER_DESKTOP_LIMIT, env } from '../../../utils/config'
import { IsMobile, IsTablet } from '../../../utils/tools'
import { DisplayJson } from '../../DisplayJson'
import type { JourneyStepperProps } from '../../JourneyStepper'
import { JourneyStepper } from '../../JourneyStepper'
import { TopBar } from '../../TopBar'

import classes from './LinearJourney.module.scss'
import type { StepComponentProps } from './StepComponent'
import { StepComponent } from './StepComponent'

export type LinearJourneyProps = {
  activeJourneyEnabled: boolean
  journey: Journey
  currentStepIndex: number
  debug?: boolean
  onNavigateToStep: JourneyStepperProps['onChangeStepIndex']
  onGoBack: () => void
  onCloseButtonClicked: () => void
  onChange: StepComponentProps['onChange']
  stepsState: StepState[]
  stepsErrors: ValueError[][]
  showTopBar?: boolean
  showCloseButton?: boolean
  isLinearJourney?: boolean
  submitJourneySuccess?: boolean
  remainingSteps?: number
  stack: HistoryStack[]
  mode: EmbedOptions['mode']
  isParentLauncherJourney?: boolean
  isPreview?: boolean
}

export const LinearJourney = ({
  activeJourneyEnabled,
  journey,
  currentStepIndex,
  debug,
  onNavigateToStep,
  onGoBack,
  onCloseButtonClicked,
  onChange,
  stepsState,
  showTopBar = true,
  isLinearJourney,
  remainingSteps,
  stack,
  submitJourneySuccess,
  mode,
  showCloseButton,
  stepsErrors,
  isParentLauncherJourney,
  isPreview
}: LinearJourneyProps) => {
  const {
    context: { isLauncherJourney }
  } = useJourneyContext()

  const { logo: designBuiderLogoUrl } = useUpdateTheme()
  const { t } = useTranslation()

  // Stepper type
  const mobile = IsMobile()
  const tablet = IsTablet()

  const currentStep = journey.steps[currentStepIndex]

  const isTopBarEnabled = mode === 'inline' ? showTopBar : true

  const isProgressBar =
    isLinearJourney === false ||
    (isLinearJourney && journey.steps.length > STEPPER_DESKTOP_LIMIT)

  const isTopBarBackButton = isProgressBar || (!mobile && !tablet)

  // use the logo that is passed from Design Builder if it exists
  // otherwise use the logo from the journey
  const logoUrl = designBuiderLogoUrl
    ? designBuiderLogoUrl
    : getLogoUrl(journey.design)

  const data = stepsState[currentStepIndex] ?? {}

  const showInactiveJourneyWarning =
    activeJourneyEnabled && journey?.settings?.isActive === false && !isPreview

  return (
    <div
      className={
        showInactiveJourneyWarning ? classes.inactiveStatusContainer : ''
      }
    >
      {!isLauncherJourney && (
        <TopBar
          isMobile={mobile || tablet}
          isProgressBar={isProgressBar}
          isTopBarEnabled={isTopBarEnabled}
          journey={journey}
          logoUrl={logoUrl}
          onBack={
            !submitJourneySuccess && isTopBarBackButton && currentStepIndex > 0
              ? onGoBack
              : undefined
          }
          onClose={
            mode === 'inline' &&
            !isParentLauncherJourney &&
            showCloseButton !== true
              ? undefined
              : onCloseButtonClicked
          }
          stepIndex={currentStepIndex}
        />
      )}

      {showInactiveJourneyWarning && (
        <div className={classes.inactiveStatusInfo}>
          <Icon customColor="black" name="warning" />
          &nbsp;&nbsp;
          <span>
            {t(
              'inactiveJourneyStatusWarningMessage',
              'Inactive journey, activate it in the journey builder to use it outside a test environment'
            )}
          </span>
        </div>
      )}

      {currentStep?.showStepper && !isLauncherJourney && (
        <JourneyStepper
          currentStepIndex={currentStepIndex}
          isMobile={mobile}
          isProgressBar={isProgressBar}
          isTablet={tablet}
          onChangeStepIndex={onNavigateToStep}
          remainingSteps={remainingSteps}
          stack={stack}
          steps={journey.steps}
        />
      )}

      {currentStep && (
        <StepComponent
          data={data}
          debug={debug}
          isLauncherJourney={!!isLauncherJourney}
          key={currentStep.stepId} // ref: https://e-pilot.atlassian.net/browse/STABLE360-3452
          onChange={onChange}
          step={{ ...currentStep, stepIndex: currentStepIndex }}
        />
      )}

      {debug && (
        <>
          <DisplayJson data={stepsState[currentStepIndex]} />
          <DisplayJson data={stepsErrors[currentStepIndex]} />
        </>
      )}
    </div>
  )
}

LinearJourney.displayName = 'LinearJourney'

const LOGO_HEIGHT = '30'

function getLogoUrl(design?: Design) {
  if (!design) {
    return ''
  }

  if (design.orgId && design.fileId) {
    return getOptimizedFileIdURL(
      env('REACT_APP_IMAGE_PREVIEW_API_URL'),
      design.orgId,
      design.fileId,
      undefined,
      LOGO_HEIGHT
    )
  }

  return design.logoUrl
}
