import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  createStyles,
  Icon,
  List,
  ListItem,
  ListItemContent,
  ListItemPrefix,
  makeStyles,
  useTheme
} from '@epilot/journey-elements'
import type { Feature, Product } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%'
    },
    title: {
      fontWeight: 600
    }
  })
)

type ProductDetailsFeaturesProps = {
  product: Product
}

export const ProductDetailsFeatures = (props: ProductDetailsFeaturesProps) => {
  const { product } = props
  const classes = useStyles()

  const features = product.feature ?? []

  return (
    <List className={classes.container}>
      {features.map((feature, index) => (
        <ProductFeature
          feature={feature}
          id={`product-details-feature-${index}`}
          key={feature.feature}
        />
      ))}
    </List>
  )
}

const useFeatureCheckStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      minWidth: '32px',
      marginTop: '4px'
    }
  })
)

type ProductFeatureProps = ComponentProps<typeof ListItem> & {
  feature: Feature
}

const ProductFeature = (props: ProductFeatureProps) => {
  const { id, feature } = props
  const theme = useTheme()
  const classes = useFeatureCheckStyles()
  const labelId = `${id}-description`

  return (
    <ListItem alignItems="flex-start" aria-labelledby={labelId} disableGutters>
      <ListItemPrefix className={classes.icon}>
        <Icon
          customColor={theme.palette.primary.main}
          fontSize="small"
          name="check"
        />
      </ListItemPrefix>
      <ListItemContent>
        <BodyText id={labelId}>{feature.feature}</BodyText>
      </ListItemContent>
    </ListItem>
  )
}
