import type {
  BlockLocatorDetails,
  JourneyContextValue,
  OptionalPriceComponentBlockMapping,
  OptionalPriceComponentBlockMappingMetadata
} from '@epilot/journey-logic-commons'
import { MAPPING_OPERATOR } from '@epilot/journey-logic-commons'

type GetOmittedPriceComponentsOptions = {
  optionalPriceComponentMappings: ReadonlyArray<OptionalPriceComponentBlockMapping>
  stepsStateArray: JourneyContextValue['_stepsStateArray']
  steps: JourneyContextValue['journey']['steps']
}

const DEFAULT_OMITTED_PRICE_COMPONENTS_PER_STEP: JourneyContextValue['omittedPriceComponentsPerStep'] =
  {}

type GetOmittedPriceComponentsPerStepOptions = {
  stepsStateArray: JourneyContextValue['_stepsStateArray']
  steps: JourneyContextValue['journey']['steps']
  productSelectionBlocksWithOptionalMappings: BlockLocatorDetails[]
}

const getBlockPath = ({
  stepNumber,
  name,
  controlNameId
}: BlockLocatorDetails) =>
  controlNameId ? `${stepNumber}/${name}/${controlNameId}` : null

export const getOmittedPriceComponentsPerStep = ({
  stepsStateArray,
  steps,
  productSelectionBlocksWithOptionalMappings
}: GetOmittedPriceComponentsPerStepOptions) => {
  const map = productSelectionBlocksWithOptionalMappings.reduce<
    JourneyContextValue['omittedPriceComponentsPerStep']
  >((acc, block) => {
    const optionalPriceComponentMappings = block.uischema.options
      ?.optionalPriceComponentMappings as ReadonlyArray<OptionalPriceComponentBlockMapping>

    const omittedPriceComponents = getOmittedPriceComponents({
      optionalPriceComponentMappings,
      stepsStateArray,
      steps
    })

    const path = getBlockPath(block)

    /**
     * Prevent case in which BlockLocatorDetails arrives without a controlNameId
     * (which should not happen for product selection controls, but the types are not strict enough)
     */
    if (!path) {
      return acc
    }

    return { ...acc, [path]: omittedPriceComponents }
  }, {})

  /**
   * Ensure that if there are no omitted price components for a step,
   * we always return the same object (referentially equal)
   * to avoid unnecessary re-renders or re-runs of dependent hooks
   */
  if (!Object.keys(map).length) {
    return DEFAULT_OMITTED_PRICE_COMPONENTS_PER_STEP
  }

  return map
}

const getOmittedPriceComponents = ({
  optionalPriceComponentMappings,
  stepsStateArray,
  steps
}: GetOmittedPriceComponentsOptions) =>
  optionalPriceComponentMappings.reduce<
    ReadonlyArray<OptionalPriceComponentBlockMappingMetadata>
  >((acc, mapping) => {
    const stepIndex = steps.findIndex((step) => step.stepId === mapping.stepId)

    const value = stepsStateArray[stepIndex]?.[mapping.blockName!]

    /* If condition is met, price component should not be omitted */
    if (evaluateMappingCondition(mapping, value)) {
      return acc
    }

    return [...acc, mapping.metadata]
  }, [])

const evaluateMappingCondition = (
  mapping: OptionalPriceComponentBlockMapping,
  value: unknown
) => {
  if (mapping.value === null) {
    /* This means configuration for optional price component was not completed, so component is included */
    return true
  }

  switch (mapping.operator) {
    case MAPPING_OPERATOR.EQUALS:
      return value === mapping.value
    default:
      console.error(
        `Missing implementation for evaluation of optional price conditions for operator ${mapping.operator}`
      )

      return true
  }
}
