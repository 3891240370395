/**
 * These should be the units from AvailableUnits in
 * packages/blocks-configurators/configurators/NumberInputConfigurator/utils.ts
 */
export const standardUnits = [
  'l',
  'm',
  'm2',
  'cubic-meter',
  'cubic-meter-h',
  'l',
  'ls',
  'a',
  'kva',
  'w',
  'kw',
  'kwh',
  'wp',
  'kwp',
  'percent',
  'v',
  '%',
  '€',
  'euro',
  'c',
  '°c',
  'rm',
  't',
  'kg',
  '°',
  'hz',
  'kvar',
  'kv',
  '1min',
  '1/min',
  'mh',
  'ka',
  'kvas',
  'kva/s'
]
