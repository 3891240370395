import { CircularProgress } from '@epilot/journey-elements'

import { usePriceHistoryChartStyles } from './styles'

export const ChartPlaceholder = () => {
  const classes = usePriceHistoryChartStyles()

  return (
    <div className={classes.chartPlaceholder}>
      <CircularProgress />
    </div>
  )
}
