import type { PortalConfig } from '@epilot/customer-portal-client'
import {
  AuthStatus,
  AuthStep
} from '@epilot/journey-logic-commons/src/types/app'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { usePortalConfig } from '../../hooks/usePortalConfig'
import { useJourneyContext } from '../../providers/JourneyContext'
import {
  configure,
  continueSignIn,
  startSignIn
} from '../../services/auth-service'
import { getPortalToken, usePortalCheck } from '../../utils'

import type {
  AuthenticatedUserContextValues,
  AuthenticatedUserContextProps
} from './types'

const AuthenticatedUserContext = createContext<AuthenticatedUserContextValues>({
  authStep: AuthStep.IDLE,
  authStatus: AuthStatus.CONFIGURING,
  isPending: false,
  signIn: () => Promise.resolve(),
  verifySignIn: () => Promise.resolve(),
  signOut: () => void 0
})

export const useAuthenticatedUser = () => {
  const context = useContext(AuthenticatedUserContext)

  if (!context) {
    throw new Error(
      'useAuthenticatedUser must be used within a <AuthenticatedUserProvider />'
    )
  }

  return context
}

export const AuthenticatedUserProvider = (
  props: AuthenticatedUserContextProps
) => {
  const { updateContext, context } = useJourneyContext()
  const organizationId = context.journey?.organizationId

  // TODO: Rename to isPortalToken / useCheckToken
  const { isECPPortal } = usePortalCheck()

  const configQuery = usePortalConfig({ organizationId })
  const [authStep, setAuthStep] = useState<AuthStep>(AuthStep.IDLE)
  const [authStatus, setAuthStatus] = useState<AuthStatus>(
    AuthStatus.CONFIGURING
  )
  // const [lastError, setLastError] = useState<string>()

  const setup = useCallback((config: PortalConfig['cognito_details']) => {
    if (!config?.cognito_user_pool_id || !config?.cognito_user_pool_client_id) {
      return // mostly to cerce TS
    }

    configure(config.cognito_user_pool_id, config.cognito_user_pool_client_id)
  }, [])

  const signIn = useCallback<AuthenticatedUserContextValues['signIn']>(
    async (email) => {
      setAuthStep(AuthStep.SIGN_IN)

      return startSignIn(email.toLowerCase().trim())
    },
    []
  )

  const verifySignIn = useCallback<
    AuthenticatedUserContextValues['verifySignIn']
  >(async (session, code) => {
    setAuthStep(AuthStep.VERIFY_SIGN_IN)

    const res = await continueSignIn(session, code)

    // TODO: can we rely on checking the username? Can we rely on only one state?
    if (res?.username) {
      setAuthStatus(AuthStatus.AUTHENTICATED)
      updateContext((prev) => ({ ...prev, isAuthenticated: true }))
    }

    return res
  }, [])

  const signOut = useCallback<
    AuthenticatedUserContextValues['signOut']
  >(async () => {
    console.log('🚀 ~ called')
  }, [])

  // Check if the user is already authenticated
  useEffect(() => {
    async function checkAuth() {
      if (isECPPortal) {
        const authToken = await getPortalToken()

        if (authToken) {
          setAuthStatus(AuthStatus.AUTHENTICATED)
          updateContext((prev) => ({ ...prev, isAuthenticated: true }))

          return
        }
      }

      setAuthStatus(AuthStatus.UNAUTHENTICATED)
    }

    if (authStatus === AuthStatus.CONFIGURING) {
      checkAuth()
    }
  }, [updateContext, authStatus, isECPPortal])

  useEffect(() => {
    if (
      authStatus === AuthStatus.UNAUTHENTICATED &&
      configQuery.data?.cognito_details
    ) {
      setup(configQuery.data?.cognito_details)
    }
  }, [configQuery.data?.cognito_details, setup, authStatus])

  const values = useMemo<AuthenticatedUserContextValues>(
    () => ({
      authStep,
      authStatus,
      isPending: configQuery.isLoading,
      signIn,
      verifySignIn,
      signOut
    }),
    [authStep, authStatus, configQuery.isLoading, signIn, verifySignIn, signOut]
  )

  return (
    <AuthenticatedUserContext.Provider value={values}>
      {props.children}
    </AuthenticatedUserContext.Provider>
  )
}
