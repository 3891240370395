import type { Currency } from '@epilot/pricing'
import { computeAggregatedAndPriceTotals } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import {
  getDisplayPrice,
  getExternalFeesMappings,
  getPriceMappings,
  getPricingDetailsFormatted,
  priceItemIsComposite
} from '../../utils'
import type {
  CompositePriceItem,
  JourneyContextValue,
  Price,
  CompositePrice,
  PriceItem,
  RecurrenceAmount,
  UiSchema
} from '../../utils'
import type { BlockVariableConfig } from '../types'

export const shoppingCartBlockProperties = ['~~table_order_items'] as const
export type ShoppingCartBlockProperties =
  (typeof shoppingCartBlockProperties)[number]

export const getVariablesFromShoppingCartBlock = (
  _uiSchema: UiSchema
): BlockVariableConfig => {
  return {
    isAccessedByIndex: true,
    length: 1,
    properties: ['~~table_order_items']
  }
}

export const getValuesFromShoppingCartBlock = ({
  context,
  t
}: {
  context: JourneyContextValue
  t: TFunction
}) => {
  if (!context) {
    return {
      order: {}
    }
  }

  const productT = (key: string, options?: { [k: string]: string }) => {
    return t(`product.selection.${key}`, {
      ...options,
      defaultValue: key
    })
  }
  const { _shoppingCart } = context || {}

  const shoppingCartItems = Object.values(_shoppingCart ?? {})
    .flatMap((value) => value)
    .filter(Boolean)
    .map((item) => {
      const price = item.product?.selectionMetadata?.selectedPrice

      const blockMappings = getPriceMappings(price)
      const externalFeesMappings = getExternalFeesMappings(price)

      return {
        ...price,
        is_composite_price: price?.is_composite_price,
        price_components: price?.price_components,
        quantity: item.quantity || 1,
        _price: price,
        _product: item.product?.selectionMetadata?.selectedProduct,
        _coupons: item.product.selectionMetadata?.selectedCoupons,
        blockConfiguration: item.product?.selectionMetadata?.blockConfiguration,
        ...(blockMappings.length && { price_mappings: blockMappings }),
        ...(externalFeesMappings.length && {
          external_fees_mappings: externalFeesMappings
        })
      }
    })

  const estimatedRecurrences = shoppingCartItems.reduce(
    (acc: Record<string, boolean>, item: Price | CompositePrice) => {
      if (item.is_composite_price) {
        item.price_components?.forEach((component: Price) => {
          const recurrence =
            component.type === 'recurring'
              ? component.billing_period
              : component.type

          if (recurrence !== undefined) {
            acc[recurrence] =
              acc[recurrence] ||
              component.price_display_in_journeys === 'estimated_price'
          }
        })
      } else {
        const recurrence =
          item.type === 'recurring' ? item.billing_period : item.type

        if (recurrence !== undefined) {
          acc[recurrence] =
            acc[recurrence] ||
            item.price_display_in_journeys === 'estimated_price'
        }
      }

      return acc
    },
    {}
  )

  const otherTotals = computeAggregatedAndPriceTotals(shoppingCartItems)

  const createProductItem = (
    item: PriceItem | CompositePriceItem,
    isComponent: boolean
  ) => {
    const { unitAmountWithUnit, amountTotal, quantity, tax, billingPeriod } =
      getPricingDetailsFormatted(
        item,
        item.quantity || 1,
        item._price?.blockMappingData?.numberInput || 1,
        productT as TFunction,
        true
      )

    return {
      ...item,
      name: item._product?.name,
      tiers_details: [],
      is_composite_component: isComponent,
      price: {
        unit_amount: unitAmountWithUnit,
        amount_total: amountTotal,
        quantity,
        tax_rate: tax || '',
        description: item.description,
        billing_period: billingPeriod,
        type: !priceItemIsComposite(item) ? item.type : ''
      },
      ...(priceItemIsComposite(item) && {
        total_details: {
          breakdown: {
            recurrences: item.total_details?.breakdown?.recurrences
              ?.sort((a: RecurrenceAmount, b: RecurrenceAmount) => {
                if (a.type === 'one_time' && b.type === 'recurring') return -1 // one_time first
                if (a.type === 'recurring' && b.type === 'one_time') return 1 // recurring second

                return 0 // no change if types are the same
              })
              .map((recurrenceAmount: RecurrenceAmount) => {
                return {
                  ...recurrenceAmount,
                  billing_period:
                    recurrenceAmount.type === 'recurring' &&
                    productT(`recurring.${recurrenceAmount.billing_period}`),
                  amount_total: getDisplayPrice(
                    t,
                    recurrenceAmount.amount_total,
                    item._price?.price_display_in_journeys,
                    true,
                    item.currency as Currency
                  )
                }
              })
          }
        }
      })
    }
  }

  return {
    order: {
      products: otherTotals.items?.flatMap((item) => {
        if (!item) return []

        if (priceItemIsComposite(item)) {
          const itemComponents = item.item_components || []

          return [item, ...itemComponents].map((component) => {
            return createProductItem(component, !component.is_composite_price)
          })
        }

        return createProductItem(item, false)
      }),
      total_details: {
        recurrences: otherTotals.total_details?.breakdown?.recurrences?.map(
          (recurrence) => {
            const interval = recurrence.billing_period || 'one_time'

            return {
              ...recurrence,
              amount_total: getDisplayPrice(
                t,
                recurrence.amount_total,
                'show_price',
                true,
                otherTotals.currency as Currency
              ),
              billing_period: estimatedRecurrences[interval]
                ? productT(`recurring.${interval}WithMask`, {
                    mask: productT('estimated_price')
                  })
                : productT(`recurring.${interval}`)
            }
          }
        )
      }
    }
  }
}
