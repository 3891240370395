import type { TFunction } from 'i18next'

import { standardUnits } from './standardUnits'

export const getUnitTranslation = (unit: string | undefined, t: TFunction) => {
  // this is complex because not all units labels are pre defined by epilot
  return t(
    unit && unit !== 'generic'
      ? standardUnits.includes(unit)
        ? `units.${unit}`
        : unit
      : ''
  )
}
