import type { BlockProperties } from '../types'

export function getValuesFromBlockState<
  Properties extends readonly Partial<BlockProperties>[],
  T
>(
  blockState: Partial<Record<BlockProperties, T>>,
  properties: Properties,
  transform?: (value: T, property: BlockProperties) => string
): Partial<Record<Properties[number], string>> {
  transform = transform ?? ((value) => String(value))

  if (!blockState) {
    return {}
  }

  const data: Partial<Record<BlockProperties, string>> = {}

  properties.forEach((property) => {
    const value =
      blockState[property] !== undefined
        ? transform(blockState[property], property)
        : undefined

    if (!value) {
      return
    }

    data[property] = value
  })

  return data
}
