import { createStyles, makeStyles, clsx } from '@epilot/journey-elements'
import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import type { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'

const useMarkdownRendererStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    content: {
      '& ul, & ol': {
        paddingLeft: theme.spacing(4)
      },
      '& ul, & ol, & p': {
        marginTop: 0,
        marginBottom: theme.spacing(4)
      },
      '& ul:last-child, & ol:last-child, & p:last-child': {
        marginBottom: 0
      }
    },
    breakSpaces: {
      whiteSpace: 'break-spaces'
    }
  })
)

interface MarkdownRendererProps extends ComponentProps<typeof ReactMarkdown> {
  breakSpaces?: boolean
}

export const MarkdownRenderer = ({
  className,
  breakSpaces = true,
  ...props
}: MarkdownRendererProps) => {
  const classes = useMarkdownRendererStyles()

  return (
    <ReactMarkdown
      className={clsx(
        classes.content,
        { [classes.breakSpaces]: breakSpaces },
        className
      )}
      /* Ensure h1 is not abused */
      components={{ h1: 'h2' }}
      {...props}
    />
  )
}
