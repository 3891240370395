import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type {
  CompositePriceItem,
  ExternalCatalogData,
  Price,
  PriceItem
} from '@epilot/journey-logic-commons'
import {
  computePriceDisplayInJourneys,
  sortRecurrences
} from '@epilot/journey-logic-commons'
import type { Currency, PricingDetails } from '@epilot/pricing'
import { useMemo } from 'react'

import { useComputePricingDetails } from '../../../../utils'
import {
  PriceComponentInfo,
  PriceComponentsList,
  PriceTotals
} from '../../../Product'
import { ProductDetailsCashback } from '../ProductDetailsCashback'

import { ProductDetailsPriceInformationTaxes } from './ProductDetailsPriceInformationTaxes'
import { extractPriceTaxes } from './utils'

type ProductDetailsPriceInformationProps = {
  price: PriceItem | CompositePriceItem
  pricingDetails: PricingDetails
  blockMappings: Price['price_mappings']
  externalCatalogData?: ExternalCatalogData
  showTrailingDecimalZeros?: boolean
  displayUnitaryAverage?: boolean
}

export const ProductDetailsPriceInformation = (
  props: ProductDetailsPriceInformationProps
) => {
  const {
    price,
    pricingDetails,
    blockMappings,
    externalCatalogData,
    showTrailingDecimalZeros = false,
    displayUnitaryAverage = true
  } = props

  const quantity = pricingDetails?.items?.[0]?.quantity ?? 1
  const priceDisplayInJourneys = useMemo(
    () => computePriceDisplayInJourneys(price, quantity),
    [price, quantity]
  )

  const classes = useStyles()

  const parentQuantity = Math.max(quantity, 1)
  const { items: priceItems = [] } = pricingDetails

  const {
    pricingDetails: { total_details }
  } = useComputePricingDetails({
    price,
    externalCatalogData,
    quantity: parentQuantity,
    overrides: {
      blockMappings
    }
  })

  const sortedRecurrenceDetails = useMemo(
    () => sortRecurrences(total_details?.breakdown?.recurrences ?? []),
    [total_details?.breakdown?.recurrences]
  )

  /* In case every component uses the same tax, the indexes should be omitted */
  const displayTaxIndexes = useMemo(
    () =>
      !priceItems?.every((item) => {
        /* If it's a simple price, we should always omit the tax indexes */
        if (!item.is_composite_price || !('item_components' in item))
          return true

        const [firstComponentTaxIds, ...otherComponentTaxIds] =
          item.item_components?.map(
            (component) =>
              component.taxes
                ?.map(({ tax: { _id } = {} }) => _id)
                .filter((v): v is string => !!v) ?? []
          ) ?? []

        /* Check if every component uses the same taxes */
        return otherComponentTaxIds.every(
          (ids) =>
            ids.length === firstComponentTaxIds.length &&
            ids.every((tax) => firstComponentTaxIds.includes(tax))
        )
      }),
    [priceItems]
  )

  const taxes = useMemo(() => extractPriceTaxes(price), [price])

  const displayItemList = Boolean(
    (priceDisplayInJourneys === 'show_price' ||
      priceDisplayInJourneys === 'show_as_starting_price') &&
      priceItems.length
  )

  const hasOnlyOnetimeRecurrences = useMemo(
    () =>
      priceItems.every(
        (item) =>
          (isCompositePriceItem(item) &&
            item.item_components?.every(
              (component) =>
                component._price?.type === 'one_time' || !component._price?.type
            )) ||
          (!isCompositePriceItem(item) &&
            (item._price?.type === 'one_time' || !item._price?.type))
      ),
    [priceItems]
  )

  const cashbacks = pricingDetails.total_details?.breakdown?.cashbacks ?? []

  return (
    <div
      aria-labelledby="price-information-heading"
      className={classes.wrapper}
    >
      {(priceDisplayInJourneys === 'show_price' ||
        priceDisplayInJourneys === 'show_as_starting_price' ||
        priceDisplayInJourneys === 'estimated_price') &&
        priceItems.map((item) => (
          <PriceComponentsList key={item._id}>
            {(isCompositePriceItem(item) &&
              item.item_components?.map((component) => (
                <PriceComponentInfo
                  component={component}
                  displayUnitaryAverage={displayUnitaryAverage}
                  key={component.price_id}
                  shouldHideRecurrence={
                    hasOnlyOnetimeRecurrences &&
                    (component._price?.type === 'one_time' ||
                      !component._price?.type)
                  }
                  showTrailingDecimalZeros={showTrailingDecimalZeros}
                  taxes={displayTaxIndexes ? taxes : []}
                />
              ))) || (
              <PriceComponentInfo
                component={item as PriceItem}
                displayUnitaryAverage={displayUnitaryAverage}
                shouldHideRecurrence={
                  hasOnlyOnetimeRecurrences &&
                  (item._price?.type === 'one_time' || !item._price?.type)
                }
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                taxes={displayTaxIndexes ? taxes : []}
              />
            )}
          </PriceComponentsList>
        ))}
      {sortedRecurrenceDetails && (
        <PriceTotals
          className={displayItemList ? classes.spaced : undefined}
          item={price}
          pricingDetails={pricingDetails}
          quantity={parentQuantity}
          recurrenceDetails={sortedRecurrenceDetails}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
        />
      )}
      {(priceDisplayInJourneys === 'show_price' ||
        priceDisplayInJourneys === 'show_as_starting_price' ||
        priceDisplayInJourneys === 'estimated_price') &&
        !!taxes.length && (
          <ProductDetailsPriceInformationTaxes
            className={displayItemList ? classes.spaced : undefined}
            displayIndexes={displayTaxIndexes}
            taxes={taxes}
          />
        )}
      {cashbacks.map((cashback, i) => (
        <ProductDetailsCashback
          cashback={cashback}
          currency={price.currency as Currency}
          key={`cashback-${cashback.cashback_period}-${i}`}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
        />
      ))}
    </div>
  )
}

ProductDetailsPriceInformation.displayName = 'ProductDetailsPriceInformation'

const useStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(2),
      width: '100%',
      padding: theme.spacing(3),
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px',
      backgroundColor: theme.palette.grey[30]
    },
    spaced: {
      margin: theme.spacing(0, 3)
    }
  })
)

const isCompositePriceItem = (
  price: PriceItem | CompositePriceItem
): price is CompositePriceItem => !!price.is_composite_price
