import type { TFunction } from 'i18next'

import {
  ConsentTopics,
  type ConsentData,
  type ConsentTopic
} from '../../consents/types'
import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

type BlockState = Record<ConsentTopic, ConsentData>

export const consentsBlockProperties = [
  'generalTerms',
  'dataPrivacy',
  'cancellationPolicy',
  'emailMarketing',
  'smsMarketing',
  'phoneCallMarketing',
  'printMarketing'
] as const

export type ConsentsBlockProperties = (typeof consentsBlockProperties)[number]

const topicsToProperties: Record<ConsentTopic, ConsentsBlockProperties> = {
  GTC: 'generalTerms',
  DATA_PRIVACY: 'dataPrivacy',
  CANCELLATION_POLICY: 'cancellationPolicy',
  EMAIL_MARKETING: 'emailMarketing',
  SMS_MARKETING: 'smsMarketing',
  PHONE_CALL_MARKETING: 'phoneCallMarketing',
  PRINT_MARKETING: 'printMarketing'
}

export const getVariablesFromConsentsBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: ConsentsBlockProperties[] = []

  if (
    uiSchema.options &&
    'configs' in uiSchema.options &&
    Array.isArray(uiSchema.options.configs)
  ) {
    uiSchema.options.configs.forEach((config) => {
      if (config.topics) {
        config.topics.forEach((topic: ConsentTopic) => {
          properties.push(topicsToProperties[topic])
        })
      }
    })
  }

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromConsentsBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<ConsentsBlockProperties, string>> = {}
  const blockStateData = blockState as BlockState

  Object.keys(ConsentTopics).forEach((topic) => {
    const topicKey = topic as ConsentTopic

    data[topicsToProperties[topicKey]] = `${t(topicKey)}: ${
      blockStateData[topicKey]?.agreed ? t('Yes') : t('No')
    }`
  })

  return data
}
