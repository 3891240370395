import { withDatadogRetries } from '../services/datadog'

import { debug } from './debug'

const timers = new Map<string, [startTime: number, description?: string]>()

/**
 * Starts a new timer with the given name and description.
 *
 * When you're finished timing this operation, call {@link endTimer} to compute its duration and
 * send it to datadog as a vital.
 */
export const startTimer = (name: string, description?: string) => {
  timers.set(name, [Date.now(), description])
}

/**
 * Ends a timer previously started with {@link startTimer} and send it as a vital to Datadog.
 *
 * If Datadog is not ready by the time we call this function, we'll retry sending the duration up to X times
 * followed by a backoff strategy to reduce the likelihood of loosing timers.
 */
export const endTimer = (
  name: string,
  context?: object
): number | undefined => {
  const timer = timers.get(name)

  if (!timer) {
    // eslint-disable-next-line no-console
    console.warn(`[Timer] Timer '${name}' was not started`)

    return undefined
  }

  const [startTime, description] = timer

  const duration = Date.now() - startTime // diff in ms

  withDatadogRetries((client) => {
    client.addDurationVital(name, {
      startTime,
      duration,
      description,
      context
    })
  })

  timers.delete(name)

  debug(`[Timer] Ended timer '${name}' after ${duration}ms`)

  return duration
}
