// Generated by ts-to-zod
import { z } from 'zod'

import { REFERENCED_TYPE, BLOCK_TYPE } from '../../../types/next'

import { iBlockSchema, blockBaseSettingsSchema } from './baseBlock.zod'
import { blockIdSchema, referenceIdSchema } from './ids.zod'
import { blockReferenceSchema } from './references.zod'

export const personalInformationBlockReferencesSchema =
  blockReferenceSchema.extend({
    referenceType: z.literal(REFERENCED_TYPE.BLOCK),
    referenceId: blockIdSchema,
    meaning: z.union([z.literal('address autofill'), z.literal('contact')])
  })

export const commonFieldSettingsSchema = z.object({
  display: z.boolean(),
  required: z.boolean(),
  options: z.array(z.string()).optional(),
  default: z.string().optional()
})

export const personalInformationBlockSchema = iBlockSchema.extend({
  type: z.literal(BLOCK_TYPE.PERSONAL_INFORMATION),
  settings: blockBaseSettingsSchema.and(
    z.object({
      mode: z.string(),
      fields: z.object({
        salutation: commonFieldSettingsSchema,
        title: commonFieldSettingsSchema,
        firstName: commonFieldSettingsSchema,
        lastName: commonFieldSettingsSchema,
        email: commonFieldSettingsSchema,
        telephone: commonFieldSettingsSchema,
        birthDate: commonFieldSettingsSchema,
        companyName: commonFieldSettingsSchema,
        registryCourt: commonFieldSettingsSchema,
        registerNumber: commonFieldSettingsSchema
      }),
      purpose: z.array(z.string())
    })
  ),
  references: z.record(
    referenceIdSchema,
    personalInformationBlockReferencesSchema
  )
})
