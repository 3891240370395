import { toDateString, type JourneyContextValue } from '../../utils'
import type { BlockVariableConfig } from '../types'

export const metadataProperties = [
  'journey_name',
  'document_creation_date',
  'timestamp'
] as const

export type MetadataProperties = (typeof metadataProperties)[number]

export const getVariablesForMetadata = (): BlockVariableConfig => {
  return {
    isAccessedByIndex: false,
    properties: ['journey_name', 'document_creation_date', 'timestamp']
  }
}

export const getValuesForMetadata = ({
  context
}: {
  context: JourneyContextValue
}) => {
  if (!context) {
    return {}
  }

  return {
    journey_name: context.journey.name,
    document_creation_date: toDateString(new Date(), true),
    timestamp: toDateString(new Date(), false)
  } as Record<MetadataProperties, string>
}
