import type { Components } from '@epilot/file-client'
import type { Journey, StepState } from '@epilot/journey-logic-commons'

import { getPortalToken } from '../blocks-renderers'
import { env } from '../utils/config'
import type {
  FileToPersist,
  PersistFilesReq,
  SourcesFiles
} from '../utils/types'

import { axiosCallApi } from './submission-service'

type FileEntity = Components.Schemas.FileEntity

const BASE_URL = env('REACT_APP_JOURNEYS_API_BASE_URL')

/**
 * @todo Refactor to use utility from packages/blocks-renderers/src/renderers/controls/JourneyLauncherControl/JourneyService.ts
 */
export async function getJourney(journeyId: string) {
  try {
    const data = await axiosCallApi<undefined, Journey>({
      method: 'GET',
      url: BASE_URL,
      path: `/configuration/${journeyId}`,
      authToken: (await getPortalToken()) || undefined
    })

    return data
  } catch (originalError) {
    const error = new Error(`Error when getting journey by id ${journeyId}`)

    error.cause = originalError

    throw error
  }
}

export async function persistFiles(
  files: PersistFilesReq['files'],
  orgId: string
) {
  try {
    const data = await axiosCallApi<
      { files: FileEntity[] },
      { files: FileEntity[] }
    >({
      method: 'POST',
      url: BASE_URL,
      path: `/files/persist`,
      data: { files },
      customHeaders: {
        'x-ivy-org-id': orgId
      }
    })

    // returns persisted files
    if (data.files) return data.files

    return []
  } catch (err) {
    console.error(`Error when persisting files`, err)
  }
}

// TODO: The fileuploadfix flag is there just to test that removing parameters don't break anything
// As soon as is ON everywhere and properly tested, it should be removed
export const getFilesData = (
  sources: SourcesFiles,
  data: StepState[],
  filePurposes?: string[],
  fileUploadFix?: boolean
): FileToPersist[] => {
  const locations = sources.fileLocations
  // each upload block (location), can have multiple files, so it can be a multi-dimensional array
  const files: FileToPersist[] = []

  locations.forEach((source) => {
    if (typeof source !== 'string') {
      const fileArray = data[source.stepIndex]?.[source.key]
      const relationTag = `_hidden_ ${source.stepId} - ${source.key}`

      if (fileArray && Array.isArray(fileArray)) {
        if (!fileUploadFix) {
          fileArray.forEach((file) => {
            file?.s3ref &&
              files.push({
                bucket: file.s3ref?.bucket,
                key: file.s3ref?.key,
                filename: file.original_name,
                s3ref: file.s3ref,
                _tags: file._tags,
                _purpose: filePurposes,
                shared_with_end_customer: true,
                relation_tags: [relationTag]
              })
          })
        } else {
          fileArray.forEach((file) => {
            file?.s3ref &&
              files.push({
                filename: file.original_name,
                s3ref: file.s3ref,
                _tags: file._tags,
                _purpose: filePurposes,
                relation_tags: [relationTag]
              })
          })
        }

        return
      }
    }
  })

  return files
}
