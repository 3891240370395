import type { Sortable } from './common'
import type { LogicAction, LogicOperator } from './enums'
import type { BlockId, LogicId, StepId } from './ids'
import type { BlockReference, ContextParameterReference } from './references'

export enum LogicTrigger {
  /**
   * Triggers when the Journey state changes (incl. Context Parameters)
   *
   * TODO: Should we spin off "Journey Loaded" from this to specifically handle context parameters
   *
   * NOTE: Currently, this is not implemented.
   */
  JOURNEY_STATE_CHANGED = 'on-journey-changed',
  /** Triggers when a block state changes */
  BLOCK_STATE_CHANGED = 'on-block-changed',
  /** Triggers when the "next" button is clicked in the Action Bar block */
  NEXT_CLICKED = 'on-click-next'
}

export interface ILogic extends Sortable {
  readonly id: LogicId
  /** Which action should be taken */
  action: LogicAction
  /** When should this logic be evaluated/triggered */
  triggeredOn: LogicTrigger
  /**
   * Which block should trigger this logic when interacted with
   *
   * Depending on "when" the logic is triggered, we wanna know which block should serve
   * as the "trigger" for the logic to be evaluated/executed.
   */
  triggeredBy?: BlockLogicReference
  /**
   * List of logic expressions that must evalutate to "truthy" for the "action" to be taken.
   *
   * These are expressed in "disjunctive normal form" (DNF), whereas the first dimension means `OR` and the second dimension means `AND`,
   * therefore resulting in `[[AND] OR [AND] OR ...]`.
   *
   * @example
   * ```js
   * const conditions = [[a==1], [b==2]] // which represents, a == 1 OR b == 2
   * const conditions = [[a==1, b==2]] // which represents, a == 1 AND b == 2
   * ```
   *
   * @see https://en.wikipedia.org/wiki/Disjunctive_normal_form
   */
  conditions: Clause[][]
  /** Logic-specific settings, if any */
  settings?: Record<string, unknown>
}

export type Clause = {
  /** Which is the logical operator, according to the value types */
  operator: LogicOperator
  /** Reference to the data source */
  a: LogicReference
  /** Which value to compare against the source value */
  b: LogicReference | ComparisonValue
}

export type ComparisonValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | {
      [key: string]: ComparisonValue
    }

export interface BlockLogicReference extends BlockReference {
  meaning: 'data source'
}

export interface ContextParameterLogicReference
  extends ContextParameterReference {
  meaning: 'data source'
}

export type LogicReference =
  | BlockLogicReference
  | ContextParameterLogicReference

export interface JumpToStepLogic extends ILogic {
  action: LogicAction.JUMP_TO_STEP
  triggeredOn: LogicTrigger.BLOCK_STATE_CHANGED | LogicTrigger.NEXT_CLICKED
  triggeredBy: BlockLogicReference
  settings: {
    /** Which Step we should jump to */
    targetStep: StepId
  }
}

export interface SkipStepLogic extends ILogic {
  action: LogicAction.SKIP_STEP
  triggeredOn: LogicTrigger.BLOCK_STATE_CHANGED | LogicTrigger.NEXT_CLICKED
  triggeredBy: BlockLogicReference
  settings: {
    /** Which Steps should be skipped */
    targetSteps: StepId[]
  }
}

export interface BlockVisibilityLogic extends ILogic {
  action: LogicAction.BLOCK_VISIBILITY
  triggeredOn: LogicTrigger.BLOCK_STATE_CHANGED
  triggeredBy: BlockLogicReference
  settings: {
    /** Which block should is controlled by this logic */
    targetBlock: BlockId
  }
}

export interface DataInjectionLogic extends ILogic {
  action: LogicAction.DATA_INJECTION
  triggeredOn: LogicTrigger.BLOCK_STATE_CHANGED
  triggeredBy: BlockLogicReference
  settings: {
    /** From which block the data is coming from */
    sourceBlock: BlockId
    /** To which block the data is going to */
    targetBlock: BlockId
  }
}

export type Logic =
  | JumpToStepLogic
  | SkipStepLogic
  | BlockVisibilityLogic
  | DataInjectionLogic
