import { useMemo, type PropsWithChildren } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { useConfig } from './ConfigContext'

type Props = PropsWithChildren<{}>

export const ReactQueryProvider = (props: Props) => {
  const { STAGE } = useConfig()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      }),
    []
  )

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {STAGE === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
