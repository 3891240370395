// Generated by ts-to-zod
import { z } from 'zod'

import { sortableSchema } from './common.zod'
import { blockTypeSchema } from './enums.zod'
import { blockIdSchema, stepIdSchema } from './ids.zod'
import { referenceableSchema } from './references.zod'

export const blockBaseSettingsSchema = z.object({
  showPaper: z.boolean(),
  title: z.string(),
  subtitle: z.string(),
  variablePath: z.string(),
  sticky: z.boolean().optional(),
  stickyIndex: z.number().optional(),
  halfWidth: z.boolean().optional()
})

export const iBlockSchema = sortableSchema
  .extend(referenceableSchema.shape)
  .extend({
    id: blockIdSchema,
    stepId: stepIdSchema,
    type: blockTypeSchema,
    name: z.string(),
    required: z.boolean(),
    settings: blockBaseSettingsSchema.and(z.record(z.unknown())).optional(),
    parent: blockIdSchema.optional()
  })
