import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useCartItemStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    cartItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '8px',
      flexBasis: '100%',
      background: theme.palette?.grey[20],
      borderRadius: '4px',
      padding: '8px',
      flexWrap: 'nowrap', // No wrapping, maintain the single row layout
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    cartItemImageSection: {
      flexShrink: 0,
      width: '24%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    cartItemDescriptionSection: {
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      minWidth: 0
    },
    productName: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%'
    },
    priceDescription: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%'
    },
    cartItemAmountSection: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      minWidth: 0,
      overflow: 'hidden',
      alignItems: 'flex-end',
      whiteSpace: 'nowrap',
      ['& .row1']: {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    openComponentsButton: {
      padding: 0,
      minWidth: 0,
      maxHeight: 20
    },
    discountInnerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    priceBeforeDiscount: {
      textDecoration: 'line-through',
      opacity: 0.5,
      fontWeight: theme.typography.fontWeightBold as never
    },
    discount: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold as never
    },
    moreDetailsLink: {
      justifyContent: 'center',
      display: 'inline-flex',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
        opacity: 0.8
      }
    }
  })
)
