import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { File } from '@epilot/journey-logic-commons'

export type ImageFile = File & {
  id: string
  src: string
  alt?: string
}
interface ProductImageProps {
  image: ImageFile
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      height: 'auto',
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '3/2'
    }
  })
)

export const OPTIMAL_IMAGE_WIDTH = '350'

export const ProductImage = ({ image }: ProductImageProps) => {
  const classes = useStyles()

  return <img alt={image.filename} className={classes.image} src={image.src} />
}
