export const PossiblePIPurpose = {
  CUSTOMER: 'customer'
}

export enum CONTROL_NAME {
  PERSONAL_INFORMATION_CONTROL = 'PersonalInformationControl',
  CONTACT_CONTROL = 'ContactControl',
  ACCOUNT_CONTROL = 'AccountControl',
  ADDRESS_BLOCK = 'AddressControl',
  CONTROL = 'Control',
  SUMMARY_CONTROL = 'SummaryControl',
  PDF_SUMMARY_CONTROL = 'PdfSummaryControl',
  SHOP_CART_CONTROL = 'ShopCartControl',
  CONFIRMATION_MESSAGE_CONTROL = 'ConfirmationMessageControl',
  ACTION_BAR_CONTROL = 'ActionBarControl',
  AUTH_CONTROL = 'AuthControl',
  SECONDARY_ACTION_BAR_CONTROL = 'SecondaryActionBarControl',
  LABEL_CONTROL = 'Label',
  PRODUCT_SELECTION_CONTROL = 'ProductSelectionControl',
  PRODUCT_CATEGORY_CONTROL = 'ProductCategoryControl',
  PAYMENT_CONTROL = 'PaymentControl',
  UPLOAD_CONTROL = 'UploadPanelControl',
  DIGITAL_SIGNATURE_CONTROL = 'DigitalSignatureControl',
  DATE_PICKER = 'DatePickerControl',
  CROSS_SELL_PRODUCT_SELECTION_CONTROL = 'CrossSellProductSelectionControl',
  NUMBER_INPUT_CONTROL = 'NumberInputControl',
  INPUT_CALCULATOR_CONTROL = 'InputCalculatorControl',
  CONSENTS_CONTROL = 'ConsentsControl',
  AVAILABILITY_CHECK = 'AvailabilityCheckControl',
  MULTICHOICE_CONTROL = 'MultichoiceControl',
  CUSTOM_BLOCK_CONTROL = 'CustomBlockControl',
  BOOLEAN_CONTROL = 'BooleanControl',
  ENUM_CONTROL = 'EnumControl',
  TEXTFIELD_CONTROL = 'TextfieldControl',
  GROUP_LAYOUT = 'GroupLayout',
  METER_READING_CONTROL = 'MeterReadingControl',
  METER_READING_DYNAMIC_CONTROL = 'DynamicMeterReadingControl',
  JOURNEY_LAUNCHER_CONTROL = 'JourneyLauncherControl',
  PREVIOUS_PROVIDER_CONTROL = 'PreviousProviderControl',
  THANK_YOU_CONTROL = 'ThankYouControl',
  IMAGE_CONTROL = 'ImageControl',
  ENTITY_LOOKUP_CONTROL = 'EntityLookupControl',
  ENTITY_ATTRIBUTE_CONTROL = 'EntityAttributeControl',
  PV_ROOF_PLANNER_CONTROL = 'PVRoofPlannerControl',
  APP_CONTROL = 'AppBlockControl'
}

export const controlsForDisplay = [
  CONTROL_NAME.SUMMARY_CONTROL,
  CONTROL_NAME.SHOP_CART_CONTROL,
  CONTROL_NAME.CONFIRMATION_MESSAGE_CONTROL,
  CONTROL_NAME.ACTION_BAR_CONTROL,
  CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL,
  CONTROL_NAME.LABEL_CONTROL,
  CONTROL_NAME.IMAGE_CONTROL
]

export const defaultHiddenSummaryTypes = [
  CONTROL_NAME.PRODUCT_SELECTION_CONTROL
]
