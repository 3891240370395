import type { Tax, TaxAmount } from '../types'

export const getTaxIndexes = (
  componentTaxes: TaxAmount[] | undefined,
  isComponentPriceTaxInclusive: boolean | undefined,
  taxes: (Tax & { inclusive: boolean })[]
): number[] => {
  const sanitizedComponentTaxes =
    componentTaxes?.map(({ tax }) => tax).filter((tax): tax is Tax => !!tax) ??
    []

  return sanitizedComponentTaxes
    .map(({ _id }) =>
      taxes.findIndex(
        (tax) =>
          tax._id === _id && tax.inclusive === isComponentPriceTaxInclusive
      )
    )
    .filter((index) => index >= 0)
    .map((index) => index + 1)
}
