import { useTranslation } from 'react-i18next'

import type { AttributeItemProps } from './SimpleAttributeItem'
import { SimpleAttributeItem } from './SimpleAttributeItem'

type EntityAddress = Partial<{
  _id: string
  street: string
  street_number: string
  postal_code: string
  city: string
  country: string
  $relation_ref: {
    entity_id: string
    path?: string
  }
}>

export function isAddressRelationArray(value: unknown) {
  const isObjectArray =
    Array.isArray(value) && value.length > 0 && typeof value[0] === 'object'

  return isObjectArray && Object.keys(value[0]).includes('$relation_ref')
}

export function isAddressArray(value: unknown) {
  const isObjectArray =
    Array.isArray(value) && value.length > 0 && typeof value[0] === 'object'

  return isObjectArray && Object.keys(value[0]).includes('postal_code')
}

export function AttributeItem({
  label,
  value,
  slug,
  displayMode
}: AttributeItemProps) {
  const { t: tEntity } = useTranslation('entity')
  const { t } = useTranslation()

  const isObject =
    typeof value === 'object' && value !== null && !Array.isArray(value)

  const isObjectArray =
    Array.isArray(value) && value.length > 0 && typeof value[0] === 'object'

  const isRelationArray =
    isObjectArray && Object.keys(value[0]).includes('$relation')

  const isNonObjectArray =
    Array.isArray(value) && value.length > 0 && typeof value[0] !== 'object'

  const isEmptyArray = Array.isArray(value) && value.length == 0

  if (isRelationArray || isEmptyArray) {
    // ignore the array of relations
    return null
  } else if (
    (isAddressRelationArray(value) || isAddressArray(value)) &&
    Array.isArray(value)
  ) {
    return (
      <>
        {value.map((singleAddress: EntityAddress, i) => (
          <SimpleAttributeItem
            displayMode={displayMode}
            key={'address' + i}
            label={tEntity(`attributes.${label}`, label)}
            value={formatEntityAddress(singleAddress)}
          />
        ))}
      </>
    )
  } else if (isObjectArray) {
    return (
      <>
        {value.map((v) => {
          return Object.keys(v).map((k) =>
            k.startsWith('_') ? null : (
              <SimpleAttributeItem
                displayMode={displayMode}
                key={k}
                label={tEntity(`attributes.${k}`, k)}
                value={v[k]}
              />
            )
          )
        })}
      </>
    )
  } else if (isNonObjectArray) {
    return (
      <SimpleAttributeItem
        displayMode={displayMode}
        label={tEntity(`attributes.${label}`, label)}
        value={value.join(', ')}
      />
    )
  } else if (isObject) {
    return (
      <>
        {Object.keys(value).map((k) =>
          k.startsWith('_') ? null : (
            <SimpleAttributeItem
              displayMode={displayMode}
              key={k}
              label={tEntity(`attributes.${k}`, k)}
              value={value[k]}
            />
          )
        )}
      </>
    )
  }

  const valueKey = `${slug}.${label.toLowerCase()}.${value}`
  const translatedLabel = tEntity(`attributes.${label}`, label)

  return (
    <SimpleAttributeItem
      displayMode={displayMode}
      label={translatedLabel || label}
      value={value ? tEntity(valueKey, t(valueKey, value)) : ''}
    />
  )
}

function formatEntityAddress(address: EntityAddress) {
  return `${address.street || ''} ${address.street_number || ''}, ${
    address.postal_code || ''
  } ${address.city || ''}`
}
