// Generated by ts-to-zod
import { z } from 'zod'

import { BLOCK_TYPE } from '../../../types/next'
import { ActionBarActionType } from '../../../types/next/blocks/actionBarBlock'

import { blockBaseSettingsSchema, iBlockSchema } from './baseBlock.zod'
import { stepIdSchema } from './ids.zod'

export const actionBarActionTypeSchema = z.nativeEnum(ActionBarActionType)

export const actionBarBlockSchema = iBlockSchema.extend({
  type: z.literal(BLOCK_TYPE.ACTION_BAR),
  required: z.literal(false),
  settings: blockBaseSettingsSchema.and(
    z.object({
      next: z.union([
        z.object({
          display: z.literal(false),
          label: z.never().optional(),
          action: z.never().optional(),
          nextStep: z.never().optional()
        }),
        z.object({
          display: z.literal(true),
          label: z.string(),
          action: actionBarActionTypeSchema,
          nextStep: stepIdSchema
        })
      ]),
      back: z.union([
        z.object({
          display: z.literal(false),
          label: z.never().optional()
        }),
        z.object({
          display: z.literal(true),
          label: z.string()
        })
      ])
    })
  )
})
