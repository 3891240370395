import type { Price, PriceItem } from '@epilot/journey-logic-commons'
import { PricingModel } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { ComponentMappingDataGraduated } from './ComponentMappingDataGraduated'

interface ComponentMappingDataProps {
  displayUnitaryAverage?: boolean
  itemQuantity: number | undefined
  component: PriceItem
  t: TFunction
  showTrailingDecimalZeros?: boolean
  unitAmount?: string
  unitAmountWithUnit?: string
  formattedQuantity?: string
  amountTotal?: string
}

export const ComponentMappingData = ({
  amountTotal,
  displayUnitaryAverage,
  itemQuantity,
  component,
  t,
  showTrailingDecimalZeros,
  unitAmount,
  unitAmountWithUnit,
  formattedQuantity
}: ComponentMappingDataProps) => {
  const showQuantity =
    component.pricing_model !== PricingModel.externalGetAG ||
    component._price?.get_ag?.type === 'work_price'
      ? formattedQuantity
      : ''

  return (
    <>
      {component.pricing_model === PricingModel.tieredGraduated ? (
        <ComponentMappingDataGraduated
          amountTotal={amountTotal}
          displayUnitaryAverage={displayUnitaryAverage ?? true}
          formattedQuantity={formattedQuantity}
          price={component._price as Price}
          quantity={itemQuantity}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
          unitAmount={unitAmount}
          unitAmountWithUnit={unitAmountWithUnit}
        />
      ) : (
        <>
          {`${showQuantity && formattedQuantity ? `${formattedQuantity} | ` : ''}
           ${unitAmountWithUnit}`}
        </>
      )}
    </>
  )
}
