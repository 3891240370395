// Generated by ts-to-zod
import { z } from 'zod'

import {
  JOURNEY_SCHEMA_VERSION,
  JOURNEY_TYPE,
  JOURNEY_ACCESS_MODE,
  JOURNEY_STATUS,
  JOURNEY_EMBED_MODE,
  JOURNEY_PURPOSE,
  RUNTIME_ENTITY,
  BLOCK_TYPE,
  REFERENCED_TYPE,
  CONTEXT_PARAMETER_TYPE,
  LANGUAGE,
  LogicAction,
  LogicOperator,
  LAYOUT_TYPE,
  JOURNEY_SAVING_PROGRESS_MODE
} from './../../../types/next/enums'

export const journeySchemaVersionSchema = z.nativeEnum(JOURNEY_SCHEMA_VERSION)

export const journeyTypeSchema = z.nativeEnum(JOURNEY_TYPE)

export const journeyAccessModeSchema = z.nativeEnum(JOURNEY_ACCESS_MODE)

export const journeyStatusSchema = z.nativeEnum(JOURNEY_STATUS)

export const journeyEmbedModeSchema = z.nativeEnum(JOURNEY_EMBED_MODE)

export const journeyPurposeSchema = z.nativeEnum(JOURNEY_PURPOSE)

export const runtimeEntitySchema = z.nativeEnum(RUNTIME_ENTITY)

export const blockTypeSchema = z.nativeEnum(BLOCK_TYPE)

export const referencedTypeSchema = z.nativeEnum(REFERENCED_TYPE)

export const contextParameterTypeSchema = z.nativeEnum(CONTEXT_PARAMETER_TYPE)

export const languageSchema = z.nativeEnum(LANGUAGE)

export const logicActionSchema = z.nativeEnum(LogicAction)

export const logicOperatorSchema = z.nativeEnum(LogicOperator)

export const layoutTypeSchema = z.nativeEnum(LAYOUT_TYPE)

export const journeySavingProgressModeSchema = z.nativeEnum(
  JOURNEY_SAVING_PROGRESS_MODE
)
