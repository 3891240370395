import { CONTROL_NAME } from '../../utils/blocks/types'
import type { AdditionalVariableHandler, BlockVariablesHander } from '../types'

import {
  getValuesFromAddressBlock,
  getVariablesFromAddressBlock
} from './AddressBlockHandler'
import {
  getValuesFromAvailabilityBlock,
  getVariablesFromAvailabilityBlock
} from './AvailabilityBlockHandler'
import {
  getValuesFromBooleanInputBlock,
  getVariablesFromBooleanInputBlock
} from './BooleanInputHandler'
import {
  getValuesFromConsentsBlock,
  getVariablesFromConsentsBlock
} from './ConsentsBlockHandler'
import {
  getValuesFromDateBlock,
  getVaribalesFromDateBlock
} from './DateBlockHandler'
import {
  getValuesForMetadata,
  getVariablesForMetadata
} from './MetadataHandler'
import {
  getValuesFromPaymentMethodBlock,
  getVariablesFromPaymentMethodBlock
} from './PaymentMethodBlockHandler'
import {
  getValuesFromPersonalInformationBlock,
  getVariablesFromPersonalInformationBlock
} from './PersonalInformationBlockHandler'
import {
  getValuesFromProductSelectionBlock,
  getVariablesFromProductSelectionBlock
} from './ProductSelectionBlockHandler'
import {
  getValuesFromShoppingCartBlock,
  getVariablesFromShoppingCartBlock
} from './ShoppingCartBlockHandler'
import {
  getNumberValuesFromSimpleInputBlock,
  getTextValuesFromSimpleInputBlock,
  getVariablesFromSimpleInputBlock
} from './SimpleInputHandler'

type BlockHandlerRecord = {
  [key in CONTROL_NAME]: {
    isExportingVariables: boolean
    handler?: BlockVariablesHander
  }
}

type AdditionalHandlerRecord = {
  [key: string]: AdditionalVariableHandler
}

const blockVariablesHandlers: Record<string, BlockVariablesHander> = {
  AddressBlockHandler: {
    getVariablesFromBlock: getVariablesFromAddressBlock,
    getValuesForExport: getValuesFromAddressBlock
  },
  AvailabilityBlockHandler: {
    getVariablesFromBlock: getVariablesFromAvailabilityBlock,
    getValuesForExport: getValuesFromAvailabilityBlock
  },
  BooleanInputBlockHandler: {
    getVariablesFromBlock: getVariablesFromBooleanInputBlock,
    getValuesForExport: getValuesFromBooleanInputBlock
  },
  ConsentsBlockHandler: {
    getVariablesFromBlock: getVariablesFromConsentsBlock,
    getValuesForExport: getValuesFromConsentsBlock
  },
  DateBlockHandler: {
    getVariablesFromBlock: getVaribalesFromDateBlock,
    getValuesForExport: getValuesFromDateBlock
  },
  NumberInputBlockHandler: {
    getVariablesFromBlock: getVariablesFromSimpleInputBlock,
    getValuesForExport: getNumberValuesFromSimpleInputBlock
  },
  PaymentMethodBlockHandler: {
    getVariablesFromBlock: getVariablesFromPaymentMethodBlock,
    getValuesForExport: getValuesFromPaymentMethodBlock
  },
  PersonalInformationBlockHandler: {
    getVariablesFromBlock: getVariablesFromPersonalInformationBlock,
    getValuesForExport: getValuesFromPersonalInformationBlock
  },
  ProductSelectionBlockHandler: {
    getVariablesFromBlock: getVariablesFromProductSelectionBlock,
    getValuesForExport: getValuesFromProductSelectionBlock
  },
  ShoppingCartBlockHandler: {
    getVariablesFromBlock: getVariablesFromShoppingCartBlock,
    getValuesForExport: getValuesFromShoppingCartBlock
  },
  SimpleInputBlockHandler: {
    getVariablesFromBlock: getVariablesFromSimpleInputBlock,
    getValuesForExport: getTextValuesFromSimpleInputBlock
  }
}

const additionalVariablesHandlers: Record<string, AdditionalVariableHandler> = {
  MetadataHandler: {
    nameLabel: 'pdf_summary.additional.metadata',
    icon: 'note_stack',
    getVariables: getVariablesForMetadata,
    getValues: getValuesForMetadata
  }
}

export const blockVariablesHandlerByBlock: BlockHandlerRecord = {
  [CONTROL_NAME.ADDRESS_BLOCK]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.AddressBlockHandler
  },
  [CONTROL_NAME.PRODUCT_SELECTION_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.ProductSelectionBlockHandler
  },
  [CONTROL_NAME.PERSONAL_INFORMATION_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.PersonalInformationBlockHandler
  },
  [CONTROL_NAME.CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.SUMMARY_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.PDF_SUMMARY_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.SHOP_CART_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.ShoppingCartBlockHandler
  },
  [CONTROL_NAME.CONFIRMATION_MESSAGE_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.ACTION_BAR_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.AUTH_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.LABEL_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.PRODUCT_CATEGORY_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.SimpleInputBlockHandler
  },
  [CONTROL_NAME.PAYMENT_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.PaymentMethodBlockHandler
  },
  [CONTROL_NAME.UPLOAD_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.DATE_PICKER]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.DateBlockHandler
  },
  [CONTROL_NAME.CROSS_SELL_PRODUCT_SELECTION_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.NUMBER_INPUT_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.NumberInputBlockHandler
  },
  [CONTROL_NAME.INPUT_CALCULATOR_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.CONSENTS_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.ConsentsBlockHandler
  },
  [CONTROL_NAME.AVAILABILITY_CHECK]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.AvailabilityBlockHandler
  },
  [CONTROL_NAME.MULTICHOICE_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.SimpleInputBlockHandler
  },
  [CONTROL_NAME.CUSTOM_BLOCK_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.BOOLEAN_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.BooleanInputBlockHandler
  },
  [CONTROL_NAME.ENUM_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.SimpleInputBlockHandler
  },
  [CONTROL_NAME.TEXTFIELD_CONTROL]: {
    isExportingVariables: true,
    handler: blockVariablesHandlers.SimpleInputBlockHandler
  },
  [CONTROL_NAME.GROUP_LAYOUT]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.METER_READING_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.METER_READING_DYNAMIC_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.JOURNEY_LAUNCHER_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.THANK_YOU_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.IMAGE_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.ENTITY_LOOKUP_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.PV_ROOF_PLANNER_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.CONTACT_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.ACCOUNT_CONTROL]: {
    isExportingVariables: false
  },
  [CONTROL_NAME.APP_CONTROL]: {
    isExportingVariables: false
  }
}

export const additionalHandlers: AdditionalHandlerRecord = {
  ['metadata']: additionalVariablesHandlers.MetadataHandler
}
