/**
 * @function unreachable
 * The unreachable function is used to ensure that
 * all possible cases are handled in a switch statement. It is used in
 * conjunction with the never type. For example:
 * @example ```ts
  type X = string | number
  const foo = (x: X) => {
    switch (typeof x) {
      case 'string':
        return x.length
      case 'number':
        return x * 10
      default:
        return unreachable(x)
    }
  }
 * ```
 * If the type X is changed to include a new type, the compiler will
 * complain that the switch statement is not exhaustive. The issue is
 * fixed by adding a case for the new type, which makes us consider
 * every possible case. In case, at runtime, the function is called
 * with a value that is does not match the type X, an error will be logged,
 * to DataDog, which allows us to track situations in which misformated data
 * is being handled at runtime.
 */
export const unreachable = (value: never) => {
  const error = new Error(`Unexpected object: ${value}`)

  /**
   * @todo Consider whether it's appropriate to throw error
   * rather than logging and returning it.
   */
  console.error(error)
}
