import { H5, LargeText, Typography, clsx } from '@epilot/journey-elements'
import type { StepState, StepExtended } from '@epilot/journey-logic-commons'
import { JourneyFeatureFlags as FeatureFlags } from '@epilot/journey-logic-commons'
import type { JsonFormsReactProps } from '@jsonforms/react'
import React, { Suspense, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useFlags,
  useJourneyContext
} from '../../../blocks-renderers/providers'
import { IsMobile } from '../../../utils/tools'
import { SpinnerPage } from '../../SpinnerPage'
import { useWrapperStyles } from '../styles'
import { protectAgainstRequiredNoUischema } from '../utils'

import classes from './StepComponent.module.scss'

const StepContent = React.lazy(() => import('./StepContent'))

export type StepComponentProps = {
  data: StepState
  debug?: boolean
  isLauncherJourney: boolean
  onChange: JsonFormsReactProps['onChange']
  step: StepExtended
}

const RequiredLabel = () => {
  const { t } = useTranslation()

  return (
    <Typography
      className={classes.label}
      color="textSecondary"
      variant="caption"
    >
      {'* ' + t('input_required', 'Pflichteingabe')}
    </Typography>
  )
}

export const StepComponent = ({
  data,
  debug,
  isLauncherJourney,
  onChange,
  step
}: StepComponentProps) => {
  const { context } = useJourneyContext()
  const flags = useFlags()

  const useNewDesign = context.journey.settings?.useNewDesign
  const useNewLayouts = flags[FeatureFlags.CONCORDE_LAYOUTS]
  // clean up the step from any potential required blocks that are not in the uischema
  const requiredBlocks = step.schema?.required
  const uischema = step.uischema

  const cleanedRequired = useMemo(
    () => protectAgainstRequiredNoUischema(uischema, requiredBlocks),
    [uischema, requiredBlocks]
  )

  const schema = useMemo(() => {
    if (cleanedRequired.length !== requiredBlocks?.length) {
      return { ...step.schema, required: cleanedRequired }
    }

    return step.schema
  }, [cleanedRequired, requiredBlocks, step.schema])

  const mobile = IsMobile()
  const { wrapperStyles } = useWrapperStyles({
    maxWidth: useNewDesign && useNewLayouts ? 720 : 1256
  })

  const hasRequiredField = Boolean(
    schema?.required && schema.required.length > 0
  )

  return (
    <>
      {!isLauncherJourney && (
        <div className={wrapperStyles}>
          <div
            className={
              step.showStepName || step.showStepSubtitle
                ? classes.titleContainer
                : ''
            }
          >
            <div
              className={clsx(classes.labelContainer, {
                [classes.spaceBetween]: step.showStepName,
                [classes.flexEnd]: !step.showStepName
              })}
            >
              {step.showStepName && (
                <div className={mobile ? '' : classes.desktopStepNameContainer}>
                  <H5
                    color="textPrimary"
                    gutterBottom={!!step.showStepSubtitle}
                  >
                    <b
                      className={
                        mobile
                          ? classes.mobileStepName
                          : classes.desktopStepName
                      }
                    >
                      {step.title === undefined ? step.name : step.title}
                    </b>
                  </H5>
                </div>
              )}
              {!mobile && hasRequiredField && <RequiredLabel />}
            </div>

            {step.showStepSubtitle && (
              <div
                className={
                  mobile
                    ? classes.mobileSubtitleContainer
                    : classes.desktopSubtitleContainer
                }
              >
                <LargeText>
                  <span
                    className={
                      mobile ? classes.mobileSubtitle : classes.desktopSubtitle
                    }
                  >
                    {step.subTitle}
                  </span>
                </LargeText>
              </div>
            )}
          </div>
          {mobile && hasRequiredField && (
            <div className={classes.requiredLabelContainer}>
              <RequiredLabel />
            </div>
          )}
        </div>
      )}

      {/* TODO: revisit the fallback component */}
      <Suspense fallback={SpinnerPage}>
        <StepContent
          data={data}
          debug={debug}
          onChange={onChange}
          schema={schema}
          step={step}
          uischema={uischema}
        />
      </Suspense>
    </>
  )
}

StepComponent.displayName = 'StepComponent'
