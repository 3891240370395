import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  Typography,
  Tooltip
} from '@epilot/journey-elements'
import { useTranslation } from 'react-i18next'

import type { SelectorDialogProps } from './types'

export function SessionSelectorDialogMUI({
  open,
  setSelectedId,
  value,
  onDiscard,
  onSessionSelected,
  sessionsIds,
  sessionsLabel
}: SelectorDialogProps) {
  const { t } = useTranslation()

  return (
    <Dialog open={open}>
      <DialogTitle>
        {t('user_progress.dialog_header', 'Session Picker')}
      </DialogTitle>
      <DialogContent>
        <Typography color="textPrimary" variant="body1">
          {t('user_progress.dialog_subheader')}
        </Typography>
        <RadioGroup
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedId(event.target.value)
          }
          value={value}
        >
          {sessionsIds.map((id, index) => (
            <Radio
              key={id}
              label={sessionsLabel[index]}
              size="small"
              value={id}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            color="primary"
            onClick={() => onDiscard && onDiscard()}
            variant="text"
          >
            {t('user_progress.button_discard', 'Discard')}
          </Button>
          <Tooltip title={!value ? t('user_progress.button_tooltip') : ''}>
            <span>
              <Button
                color="primary"
                disabled={!value}
                onClick={() => value && onSessionSelected?.(value)}
                variant="contained"
              >
                {t('user_progress.button_continue', 'Continue')}
              </Button>
            </span>
          </Tooltip>
        </>
      </DialogActions>
    </Dialog>
  )
}
