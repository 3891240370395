// Generated by ts-to-zod
import { z } from 'zod'

import { JOURNEY_TYPE } from '../../../types/next'

import { iSODateStringSchema, sortableSchema } from './common.zod'
import {
  automationIdSchema,
  blockIdSchema,
  contextParameterIdSchema,
  designIdSchema,
  journeyEntityIdSchema,
  journeyIdSchema,
  logicIdSchema,
  mappingConfigIdSchema,
  organizationIdSchema,
  stepIdSchema,
  templateIdSchema,
  userIdSchema
} from './ids.zod'
import { stepLayoutSchema } from './layout.zod'
import { logicSchema } from './logic.zod'

export const designSchema = z.object({
  id: designIdSchema,
  orgId: z.string().optional(),
  logoUrl: z.string().optional(),
  fileId: z.string().optional(),
  theme: z.record(z.unknown()),
  designTokens: z.record(z.unknown()).optional()
})

const targetedCustomerSchema = z.union([
  z.literal('BUSINESS'),
  z.literal('PRIVATE')
])

export const webHookSchema = z.object({
  name: z.string(),
  hook: z.function().args(z.any()).returns(z.any())
})

const journeySchemaVersionSchema = z.any()

const journeyTypeSchema = z.any()

const journeyStatusSchema = z.any()

const blockSchema = z.any()

const languageSchema = z.any()

const journeyAccessModeSchema = z.any()

const journeyPurposeSchema = z.any()

const runtimeEntitySchema = z.any()

const journeySavingProgressModeSchema = z.any()

const contextParameterTypeSchema = z.any()

const journeyEmbedModeSchema = z.any()

export const contextParameterSchema = z.object({
  id: contextParameterIdSchema,
  type: contextParameterTypeSchema,
  paramKey: z.string(),
  isRequired: z.boolean().optional(),
  shouldLoadEntity: z.boolean().optional()
})

export const journeyEmbedOptionsSchema = z.object({
  mode: journeyEmbedModeSchema,
  width: z.string(),
  topBar: z.boolean(),
  button: z.object({
    text: z.string(),
    align: z.union([z.literal('left'), z.literal('center'), z.literal('right')])
  }),
  scrollToTop: z.boolean().optional(),
  lang: languageSchema.optional()
})

export const baseJourneySettingsSchema = z.object({
  language: languageSchema,
  description: z.string().optional(),
  templateId: templateIdSchema.optional(),
  embedOptions: journeyEmbedOptionsSchema.optional(),
  organizationSettings: z.record(z.boolean()).optional(),
  accessMode: journeyAccessModeSchema.optional(),
  entityTags: z.array(z.string()).optional(),
  useNewDesign: z.boolean().optional(),
  designId: designIdSchema.optional()
})

export const launcherJourneySettingsSchema = baseJourneySettingsSchema.extend({
  placementOnCard: z.boolean()
})

export const regularJourneySettingsSchema = baseJourneySettingsSchema.extend({
  purpose: journeyPurposeSchema,
  automationId: automationIdSchema,
  mappingConfigId: mappingConfigIdSchema,
  safeModeAutomation: z.boolean().optional(),
  runtimeEntities: z.array(runtimeEntitySchema).optional(),
  targetedCustomer: targetedCustomerSchema.optional(),
  publicToken: z.string().optional(),
  filePurposes: z.array(z.string()).optional(),
  addressSuggestionsFileUrl: z.string().optional(),
  addressSuggestionsFileId: z.string().optional(),
  mappingsAutomationId: z.string().optional(),
  savingProgress: z
    .object({
      mode: journeySavingProgressModeSchema,
      supportedRevision: z.number().optional()
    })
    .optional()
})

export const stepSettingsSchema = z.object({
  showStepName: z.boolean().optional(),
  title: z.string().optional().nullable(),
  subTitle: z.string().optional().nullable(),
  showStepSubtitle: z.boolean().optional(),
  showStepper: z.boolean().optional(),
  showStepperLabels: z.boolean().optional(),
  hideNextButton: z.boolean().optional(),
  onNextHooks: z.array(webHookSchema).optional()
})

export const stepSchema = sortableSchema.and(
  z.object({
    id: stepIdSchema,
    name: z.string(),
    settings: stepSettingsSchema,
    layout: stepLayoutSchema
  })
)

export const iJourneySchema = z.object({
  __schemaVersion: journeySchemaVersionSchema.optional(),
  id: journeyIdSchema,
  entityId: journeyEntityIdSchema,
  organizationId: organizationIdSchema,
  version: z.number().optional(),
  revisions: z.number().optional(),
  type: journeyTypeSchema,
  status: journeyStatusSchema,
  name: z.string(),
  createdBy: userIdSchema,
  createdAt: iSODateStringSchema,
  lastModifiedAt: iSODateStringSchema,
  deletedAt: iSODateStringSchema.optional(),
  design: designSchema.optional(),
  settings: baseJourneySettingsSchema.optional(),
  firstStepId: z.union([stepIdSchema, z.never()]).optional(),
  logics: z.union([z.record(logicIdSchema, logicSchema), z.never()]).optional(),
  steps: z.union([z.record(stepIdSchema, stepSchema), z.never()]).optional(),
  blocks: z.union([z.record(blockIdSchema, blockSchema), z.never()]).optional(),
  contextParameters: z
    .union([
      z.record(contextParameterIdSchema, contextParameterSchema),
      z.never()
    ])
    .optional()
})

export const journeySettingsSchema = z.union([
  regularJourneySettingsSchema,
  launcherJourneySettingsSchema
])

export const regularJourneySchema = iJourneySchema.extend({
  type: z.literal(JOURNEY_TYPE.REGULAR),
  settings: regularJourneySettingsSchema,
  firstStepId: z.union([stepIdSchema, z.undefined()]),
  logics: z.record(logicIdSchema, logicSchema),
  steps: z.record(stepIdSchema, stepSchema),
  blocks: z.record(blockIdSchema, blockSchema),
  contextParameters: z.record(contextParameterIdSchema, contextParameterSchema)
})

export const launcherJourneySchema = iJourneySchema.extend({
  type: z.literal(JOURNEY_TYPE.LAUNCHER),
  settings: launcherJourneySettingsSchema,
  firstStepId: z.never().optional(),
  logics: z.never().optional(),
  steps: z.never().optional(),
  blocks: z.never().optional(),
  contextParameters: z.never().optional()
})

export const postQualificationJourneySettingsSchema =
  regularJourneySettingsSchema.extend({
    linkedJourneys: z.array(journeyIdSchema)
  })

export const postQualificationJourneySchema = regularJourneySchema
  .omit({ type: true })
  .extend({
    type: z.literal(JOURNEY_TYPE.POST_QUALIFICATION),
    settings: postQualificationJourneySettingsSchema
  })

export const runtimeStepSchema = stepSchema.and(
  z.object({
    blocks: z.array(blockIdSchema).optional()
  })
)

export const journeySchema = z.union([
  regularJourneySchema,
  launcherJourneySchema,
  postQualificationJourneySchema
])
