import type { JourneyPQTokenPayload } from '@epilot/journey-logic-commons'
import {
  JOURNEY_EMBED_MODE,
  LANGUAGE
} from '@epilot/journey-logic-commons/src/types/next'
import { InvalidTokenError, jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { MAJOR_APP_ERROR, type JourneyQueryParams } from '../types'
import { debug } from '../utils/debug'
import {
  parseUuidOrValue,
  parseBoolean,
  parseMode,
  parseLanguage
} from '../utils/parse'

export class ExpiredTokenError extends Error {}

/**
 * Parse the URL search params according to the expected JourneyQueryParams.
 *
 * TODO: replace `useSearchParams` with a custom implementation to get rid of `react-router-dom` dependency
 * TODO: unit test it
 */
export const parseSearchParams = (
  searchParams: URLSearchParams
): JourneyQueryParams => {
  const journeyToken = searchParams.get('journey_token')

  // TODO: Convert camelCase to snake_case
  const params: JourneyQueryParams = {
    ...searchParams.values(),
    journeyId: parseUuidOrValue(searchParams.get('journeyId')),
    error: undefined,
    debug: searchParams.get('debug') === 'true',
    preview: searchParams.get('preview') === 'true',
    topBar: parseBoolean(searchParams.get('topBar')),
    mode: parseMode(searchParams.get('mode'), JOURNEY_EMBED_MODE.FULL_SCREEN),
    isEmbedded: searchParams.get('isEmbedded') === 'true',
    journeyLanguage: parseLanguage(searchParams.get('lang'), LANGUAGE.DE),
    dataInjectionOptions: searchParams.get('dataInjectionOptions'),
    contextData: Array.from(searchParams.entries()).reduce(
      (acc, [key, value]) => {
        acc[key] = parseUuidOrValue(value)

        return acc
      },
      {}
    ),
    journeyToken
  }

  debug('Query params:', params)

  try {
    if (journeyToken) {
      const {
        journey_id,
        exp,
        iat: __,
        nonce: ___,
        ...tokenParams
      } = jwtDecode<JourneyPQTokenPayload>(journeyToken)

      // __naively__ checks if the token has expired
      if (typeof exp !== 'undefined' && exp * 1000 < Date.now()) {
        throw new ExpiredTokenError('Journey token has expired')
      }

      // override the journey ids
      params.journeyId = journey_id
      // populate contextual data with the token's payload
      Object.assign(params.contextData, tokenParams)
    }
  } catch (originalError) {
    if (originalError instanceof InvalidTokenError) {
      params.error = MAJOR_APP_ERROR.INVALID_JOURNEY_TOKEN
    } else if (originalError instanceof ExpiredTokenError) {
      params.error = MAJOR_APP_ERROR.EXPIRED_JOURNEY_TOKEN
    } else if (!params.error) {
      params.error = MAJOR_APP_ERROR.UNHANDLED_ERROR
    }

    const error = new Error('Failed to parse the journey token')

    error.cause = originalError

    // eslint-disable-next-line no-console
    console.warn(error)

    params.journeyId = null // leave unset to let the app handle it
  }

  return params
}

export const useGetJourneyParams = () => {
  const [searchParams] = useSearchParams()

  return useMemo<JourneyQueryParams>(() => {
    const params = parseSearchParams(searchParams)

    if (!params.isEmbedded) {
      // override based on the iframe detection
      params.isEmbedded = isThisAnIframe()
    }

    return params
  }, [searchParams])
}

export const isThisAnIframe = () => window.self !== window.top
