import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  type?: string
  label?: string
  data?: {
    fullname?: string
    iban?: string
    consent?: boolean
    bic_number?: string
    bank_name?: string
  }
}

export const paymentMethodBlockProperties = [
  'paymentMethod',
  'fullname',
  'iban',
  'bic_number',
  'bank_name'
] as const

export type PaymentMethodBlockProperties =
  (typeof paymentMethodBlockProperties)[number]

export const getVariablesFromPaymentMethodBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: PaymentMethodBlockProperties[] = ['paymentMethod']

  if (
    uiSchema.options &&
    'implementations' in uiSchema.options &&
    Array.isArray(uiSchema.options.implementations)
  ) {
    uiSchema.options.implementations.forEach((implementation) => {
      if (implementation.type === 'SEPA') {
        properties.push('fullname', 'iban', 'bic_number', 'bank_name')
      }
    })
  }

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromPaymentMethodBlock = ({
  state: blockState
}: {
  state: unknown
}) => {
  if (!blockState) {
    return {}
  }

  const { data: sepaData, label } = blockState as BlockState

  const data: Partial<Record<PaymentMethodBlockProperties, string>> = sepaData
    ? getValuesFromBlockState(sepaData, paymentMethodBlockProperties)
    : {}

  data.paymentMethod = label ?? ''

  return data
}
