/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum DateFormat {
  ARABIC_DZ = 'dd-MM-yyyy',
  ARABIC_SA = 'dd/MM/yy',
  ARABIC_UAE = 'dd/MM/yyyy',
  BALTIC = 'dd.MM.yyyy',
  BULGARIAN = 'dd.MM.yyyy',
  CHINESE_SIMPLIFIED = 'yyyy/MM/dd',
  CHINESE_UYGHUR = 'yyyy-M-d',
  CROATIAN_BA = 'dd.MM.yyyy.',
  CZECH = 'dd. MM. yyyy',
  DUTCH = 'dd-MM-yyyy',
  ENGLISH_GLOBAL = 'MM/dd/yyyy',
  ENGLISH_AU = 'dd/MM/yyyy',
  ENGLISH_US = 'dd/M/yyyy',
  ENGLISH_US_ALTERNATIVE = 'MM/dd/yyyy',
  ESTONIAN = 'dd.MM.yyyy',
  FRENCH = 'yyyy-MM-dd',
  DEUTSCH = 'dd.MM.yyyy',
  HUNGARIAN = 'yyyy. MM. dd.',
  LATVIAN = 'yyyy.MM.dd.',
  LITHUANIAN = 'yy.MM.dd',
  MALAYSIAN = 'dd-MM-yy',
  MONGOLIAN = 'yy.MM.dd',
  RUSSIAN = 'dd.MM.yy',
  SAUDI_RU = 'MM.dd.yyyy',
  SOUTH_KOREAN = 'yyyy. MM. dd',
  SPANISH = 'yyyy/MM/dd',
  UZBEK = 'dd/MM/yyyy'
}

/**
 * Returns date format based on language
 * @param lng e.g. taken from navigator.language
 * @todo We don't need anything neerly this comprehensive. Please ajust to just a couple of formats.
 */
const FORMATS = {
  'af-ZA': DateFormat.SPANISH,
  'am-ET': DateFormat.ENGLISH_US,
  'ar-AE': DateFormat.ARABIC_UAE,
  'ar-BH': DateFormat.ARABIC_UAE,
  'ar-DZ': DateFormat.ARABIC_DZ,
  'ar-EG': DateFormat.ARABIC_UAE,
  'ar-IQ': DateFormat.ARABIC_UAE,
  'ar-JO': DateFormat.ARABIC_UAE,
  'ar-KW': DateFormat.ARABIC_UAE,
  'ar-LB': DateFormat.ARABIC_UAE,
  'ar-LY': DateFormat.ARABIC_UAE,
  'ar-MA': DateFormat.ARABIC_DZ,
  'ar-OM': DateFormat.ARABIC_UAE,
  'ar-QA': DateFormat.ARABIC_UAE,
  'ar-SA': DateFormat.ARABIC_SA,
  'ar-SY': DateFormat.ARABIC_UAE,
  'ar-TN': DateFormat.ARABIC_DZ,
  'ar-YE': DateFormat.ARABIC_UAE,
  'arn-CL': DateFormat.ARABIC_DZ,
  'as-IN': DateFormat.ARABIC_DZ,
  'az-Cyrl-AZ': DateFormat.DEUTSCH,
  'az-Latn-AZ': DateFormat.DEUTSCH,
  'ba-RU': DateFormat.RUSSIAN,
  'be-BY': DateFormat.DEUTSCH,
  'bg-BG': DateFormat.BULGARIAN,
  'bn-BD': DateFormat.MALAYSIAN,
  'bn-IN': DateFormat.MALAYSIAN,
  'bo-CN': DateFormat.CHINESE_SIMPLIFIED,
  'br-FR': DateFormat.ARABIC_UAE,
  'bs-Cyrl-BA': DateFormat.BALTIC,
  'bs-Latn-BA': DateFormat.BALTIC,
  'ca-ES': DateFormat.ARABIC_UAE,
  'co-FR': DateFormat.ARABIC_UAE,
  'cs-CZ': DateFormat.BALTIC,
  'cy-GB': DateFormat.ARABIC_UAE,
  'da-DK': DateFormat.ARABIC_DZ,
  'de-AT': DateFormat.DEUTSCH,
  'de-CH': DateFormat.DEUTSCH,
  'de-DE': DateFormat.DEUTSCH,
  'de-LI': DateFormat.DEUTSCH,
  'de-LU': DateFormat.DEUTSCH,
  'dsb-DE': DateFormat.CZECH,
  'dv-MV': DateFormat.ARABIC_SA,
  'el-GR': DateFormat.ENGLISH_US,
  'en-029': DateFormat.ENGLISH_US_ALTERNATIVE,
  'en-AU': DateFormat.ENGLISH_AU,
  'en-BZ': DateFormat.ARABIC_UAE,
  'en-CA': DateFormat.ARABIC_UAE,
  'en-GB': DateFormat.ARABIC_UAE,
  'en-IE': DateFormat.ARABIC_UAE,
  'en-IN': DateFormat.ARABIC_DZ,
  'en-JM': DateFormat.ARABIC_UAE,
  'en-MY': DateFormat.ENGLISH_US,
  'en-NZ': DateFormat.ENGLISH_AU,
  'en-PH': DateFormat.ENGLISH_GLOBAL,
  'en-SG': DateFormat.ENGLISH_US,
  'en-TT': DateFormat.ARABIC_UAE,
  'en-US': DateFormat.ENGLISH_GLOBAL,
  'en-ZA': DateFormat.SPANISH,
  'en-ZW': DateFormat.ENGLISH_GLOBAL,
  'es-AR': DateFormat.ARABIC_UAE,
  'es-BO': DateFormat.ARABIC_UAE,
  'es-CL': DateFormat.ARABIC_DZ,
  'es-CO': DateFormat.ARABIC_UAE,
  'es-CR': DateFormat.ARABIC_UAE,
  'es-DO': DateFormat.ARABIC_UAE,
  'es-EC': DateFormat.ARABIC_UAE,
  'es-ES': DateFormat.ARABIC_UAE,
  'es-GT': DateFormat.ARABIC_UAE,
  'es-HN': DateFormat.ARABIC_UAE,
  'es-MX': DateFormat.ARABIC_UAE,
  'es-NI': DateFormat.ARABIC_UAE,
  'es-PA': DateFormat.ENGLISH_US_ALTERNATIVE,
  'es-PE': DateFormat.ARABIC_UAE,
  'es-PR': DateFormat.ARABIC_UAE,
  'es-PY': DateFormat.ARABIC_UAE,
  'es-SV': DateFormat.ARABIC_UAE,
  'es-US': DateFormat.ENGLISH_GLOBAL,
  'es-UY': DateFormat.ARABIC_UAE,
  'es-VE': DateFormat.ARABIC_UAE,
  'et-EE': DateFormat.ESTONIAN,
  'eu-ES': DateFormat.SPANISH,
  'fa-IR': DateFormat.ENGLISH_US_ALTERNATIVE,
  'fi-FI': DateFormat.BALTIC,
  'fil-PH': DateFormat.ENGLISH_GLOBAL,
  'fo-FO': DateFormat.ARABIC_DZ,
  'fr-BE': DateFormat.ENGLISH_AU,
  'fr-CA': DateFormat.FRENCH,
  'fr-CH': DateFormat.DEUTSCH,
  'fr-FR': DateFormat.ARABIC_UAE,
  'fr-LU': DateFormat.ARABIC_UAE,
  'fr-MC': DateFormat.ARABIC_UAE,
  'fy-NL': DateFormat.DUTCH,
  'ga-IE': DateFormat.ARABIC_UAE,
  'gd-GB': DateFormat.ARABIC_UAE,
  'gl-ES': DateFormat.ARABIC_SA,
  'gsw-FR': DateFormat.ARABIC_UAE,
  'gu-IN': DateFormat.MALAYSIAN,
  'ha-Latn-NG': DateFormat.ENGLISH_US,
  'he-IL': DateFormat.ARABIC_UAE,
  'hi-IN': DateFormat.ARABIC_DZ,
  'hr-BA': DateFormat.CROATIAN_BA,
  'hr-HR': DateFormat.BALTIC,
  'hsb-DE': DateFormat.CZECH,
  'hu-HU': DateFormat.HUNGARIAN,
  'hy-AM': DateFormat.DEUTSCH,
  'id-ID': DateFormat.ARABIC_UAE,
  'ig-NG': DateFormat.ENGLISH_US,
  'ii-CN': DateFormat.CHINESE_SIMPLIFIED,
  'is-IS': DateFormat.BALTIC,
  'it-CH': DateFormat.DEUTSCH,
  'it-IT': DateFormat.ARABIC_UAE,
  'iu-Cans-CA': DateFormat.ENGLISH_US,
  'iu-Latn-CA': DateFormat.ENGLISH_AU,
  'ja-JP': DateFormat.SPANISH,
  'ka-GE': DateFormat.DEUTSCH,
  'kk-KZ': DateFormat.DEUTSCH,
  'kl-GL': DateFormat.ARABIC_DZ,
  'km-KH': DateFormat.FRENCH,
  'kn-IN': DateFormat.MALAYSIAN,
  'ko-KR': DateFormat.SOUTH_KOREAN,
  'kok-IN': DateFormat.ARABIC_DZ,
  'ky-KG': DateFormat.RUSSIAN,
  'lb-LU': DateFormat.ARABIC_UAE,
  'lo-LA': DateFormat.ARABIC_UAE,
  'lt-LT': DateFormat.LITHUANIAN,
  'lv-LV': DateFormat.LATVIAN,
  'mi-NZ': DateFormat.ARABIC_UAE,
  'mk-MK': DateFormat.DEUTSCH,
  'ml-IN': DateFormat.MALAYSIAN,
  'mn-MN': DateFormat.MONGOLIAN,
  'mn-Mong-CN': DateFormat.CHINESE_SIMPLIFIED,
  'moh-CA': DateFormat.ENGLISH_GLOBAL,
  'mr-IN': DateFormat.ARABIC_DZ,
  'ms-BN': DateFormat.ARABIC_UAE,
  'ms-MY': DateFormat.ARABIC_UAE,
  'mt-MT': DateFormat.ARABIC_UAE,
  'nb-NO': DateFormat.DEUTSCH,
  'ne-NP': DateFormat.ENGLISH_GLOBAL,
  'nl-BE': DateFormat.ENGLISH_AU,
  'nl-NL': DateFormat.DUTCH,
  'nn-NO': DateFormat.DEUTSCH,
  'nso-ZA': DateFormat.SPANISH,
  'oc-FR': DateFormat.ARABIC_UAE,
  'or-IN': DateFormat.MALAYSIAN,
  'pa-IN': DateFormat.MALAYSIAN,
  'pl-PL': DateFormat.DEUTSCH,
  'prs-AF': DateFormat.ARABIC_SA,
  'ps-AF': DateFormat.ARABIC_SA,
  'pt-BR': DateFormat.ENGLISH_US,
  'pt-PT': DateFormat.ARABIC_DZ,
  'qut-GT': DateFormat.ARABIC_UAE,
  'quz-BO': DateFormat.ARABIC_UAE,
  'quz-EC': DateFormat.ARABIC_UAE,
  'quz-PE': DateFormat.ARABIC_UAE,
  'rm-CH': DateFormat.ARABIC_UAE,
  'ro-RO': DateFormat.DEUTSCH,
  'ru-RU': DateFormat.DEUTSCH,
  'rw-RW': DateFormat.ENGLISH_GLOBAL,
  'sa-IN': DateFormat.ARABIC_DZ,
  'sah-RU': DateFormat.SAUDI_RU,
  'se-FI': DateFormat.BALTIC,
  'se-NO': DateFormat.DEUTSCH,
  'se-SE': DateFormat.FRENCH,
  'si-LK': DateFormat.FRENCH,
  'sk-SK': DateFormat.CZECH,
  'sl-SI': DateFormat.BALTIC,
  'sma-NO': DateFormat.DEUTSCH,
  'sma-SE': DateFormat.FRENCH,
  'smj-NO': DateFormat.DEUTSCH,
  'smj-SE': DateFormat.FRENCH,
  'smn-FI': DateFormat.BALTIC,
  'sms-FI': DateFormat.BALTIC,
  'sq-AL': DateFormat.FRENCH,
  'sr-Cyrl-BA': DateFormat.BALTIC,
  'sr-Cyrl-CS': DateFormat.BALTIC,
  'sr-Cyrl-ME': DateFormat.BALTIC,
  'sr-Cyrl-RS': DateFormat.BALTIC,
  'sr-Latn-BA': DateFormat.BALTIC,
  'sr-Latn-CS': DateFormat.BALTIC,
  'sr-Latn-ME': DateFormat.BALTIC,
  'sr-Latn-RS': DateFormat.BALTIC,
  'sv-FI': DateFormat.BALTIC,
  'sv-SE': DateFormat.FRENCH,
  'sw-KE': DateFormat.ENGLISH_GLOBAL,
  'syr-SY': DateFormat.ARABIC_UAE,
  'ta-IN': DateFormat.ARABIC_DZ,
  'te-IN': DateFormat.MALAYSIAN,
  'tg-Cyrl-TJ': DateFormat.RUSSIAN,
  'th-TH': DateFormat.ENGLISH_US,
  'tk-TM': DateFormat.RUSSIAN,
  'tn-ZA': DateFormat.SPANISH,
  'tr-TR': DateFormat.DEUTSCH,
  'tt-RU': DateFormat.DEUTSCH,
  'tzm-Latn-DZ': DateFormat.ARABIC_DZ,
  'ug-CN': DateFormat.CHINESE_UYGHUR,
  'uk-UA': DateFormat.DEUTSCH,
  'ur-PK': DateFormat.ARABIC_UAE,
  'uz-Cyrl-UZ': DateFormat.DEUTSCH,
  'uz-Latn-UZ': DateFormat.UZBEK,
  'vi-VN': DateFormat.ARABIC_UAE,
  'wo-SN': DateFormat.ARABIC_UAE,
  'xh-ZA': DateFormat.SPANISH,
  'yo-NG': DateFormat.ENGLISH_US,
  'zh-CN': DateFormat.CHINESE_SIMPLIFIED,
  'zh-HK': DateFormat.ENGLISH_US,
  'zh-MO': DateFormat.ENGLISH_US,
  'zh-SG': DateFormat.ENGLISH_US,
  'zh-TW': DateFormat.CHINESE_SIMPLIFIED,
  'zu-ZA': DateFormat.SPANISH
}

export const getLocaleDateString = (lng: string, showTime?: boolean) => {
  const baseFormat =
    lng in FORMATS ? FORMATS[lng as keyof typeof FORMATS] : DateFormat.DEUTSCH

  return showTime ? `${baseFormat}, HH:mm` : baseFormat
}
