import type { RumGlobal } from '@datadog/browser-rum'

import type { BackOffHandler } from '../utils/backOff'
import { backOffFibonacci } from '../utils/backOff'
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  STAGE,
  VERSION
} from '../utils/config'
import { debug } from '../utils/debug'
import { TRACE_KEYS, getTraceId } from '../utils/trace'

export const initDatadog = ({
  /** Whether we should skip DD initialization */
  disabled = false
}) => {
  if (disabled) {
    debug('[Datadog] Skipped initialization')

    return
  }

  window.DD_RUM?.onReady(() => {
    try {
      // shouldn't happen, mostly coercing TS
      if (!isDatadogAvailable(window.DD_RUM)) return

      window.DD_RUM.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'journey-app-v3',
        env: STAGE ?? 'unknown',
        version: VERSION,
        sessionSampleRate: 100,
        trackResources: true, // resource sizes and timing
        trackUserInteractions: false, // no need at this point
        trackViewsManually: true, // we want manual tracking (i.e. step navigation)
        trackLongTasks: true, // tasks that block the UI thread for longer periods
        usePartitionedCrossSiteSessionCookie: true,
        allowFallbackToLocalStorage: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackingConsent: 'not-granted',
        // enrich event data with journeyId
        beforeSend: (event, _context) => {
          const url = new URL(event.view.url)

          event.context = {
            ...event.context,
            // Adds in the journey Id
            journeyId: url.searchParams.get('journeyId'),
            // Adds in the journey App's sessinon id
            journeySessionId: getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)
          }

          return true
        }
      })

      captureBrowserSize()
      captureDarkMode()

      debug('[Datadog] Initialized')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Datadog] Failed to initialise', error)
      // fail gracefully
    }
  })
}

export const captureBrowserSize = () => {
  try {
    const round = (number: number) => Math.round(number / 100) * 100

    const width = round(window.innerWidth || document.body.clientWidth)
    const height = round(window.innerHeight || document.body.clientHeight)
    const size = width + 'x' + height

    getDatadogClient()?.setGlobalContextProperty('browserSize', size)
  } catch (_error) {
    // eslint-disable-next-line no-console
    console.warn('[Datadog] Failed to measure browser size')
    // fail gracefully
  }
}

export const captureDarkMode = () => {
  try {
    if (typeof window.matchMedia === 'function') {
      const enabled = window.matchMedia('(prefers-color-scheme: dark)').matches

      getDatadogClient()?.setGlobalContextProperty('darkMode', enabled)
    }
  } catch (_error) {
    // eslint-disable-next-line no-console
    console.warn('[Datadog] Failed to capture dark mode')
    // fail gracefully
  }
}

/**
 * Return the DataDog Session Id.
 *
 * NOTE: If DataDog is not initialized, this will return `undefined`.
 */
export const getDataDogSessionId = () => {
  try {
    return (
      isDatadogAvailable(window.DD_RUM) &&
      window.DD_RUM?.getInternalContext()?.session_id
    )
  } catch (originalError) {
    // eslint-disable-next-line no-console
    return undefined
  }
}

/**
 * Determines if Datadog is initialized.
 */
export const isDatadogAvailable = (
  arg: typeof window.DD_RUM
): arg is RumGlobal => {
  try {
    return (
      typeof arg !== 'undefined' &&
      typeof (arg as RumGlobal).init === 'function'
    )
  } catch (originalError) {
    // eslint-disable-next-line no-console
    console.error('[Datadog] Failed to check on Datadog', originalError)

    return false
  }
}

/**
 * Returns Datadog instance, only if it had been initialized.
 */
export const getDatadogClient = () => {
  if (isDatadogAvailable(window.DD_RUM)) return window.DD_RUM

  return undefined
}

/**
 * Generic wrapper to retry a function until Datadog is available,
 * or the maximum number of retries is reached.
 *
 * Returns a cancel function in case needed to stop the retries.
 */
export const withDatadogRetries = (
  /** Callback to be executed when Datadog client is available */
  fn: (client: RumGlobal) => void,
  options?: {
    /**
     * Base timeout in miliseconds
     * @default 100
     */
    timeoutMs?: number
    /**
     * Maximum number of retries before permanently failing
     * @default 10
     */
    maxRetries?: number
    /**
     * Maximum waiting time before retrying
     * @default undefined
     */
    maxWaitMs?: number
    /**
     * Which backoff strategy to use
     * @default {@link backOffFibonacci}
     */
    backOffHandler?: BackOffHandler
  }
) => {
  const {
    backOffHandler = backOffFibonacci,
    maxRetries = 10,
    maxWaitMs,
    timeoutMs = 100
  } = options ?? {}

  let timeoutRef: number | undefined = undefined

  const wrapper = (retries: number = 1) => {
    if (isDatadogAvailable(window.DD_RUM)) {
      fn(window.DD_RUM)
    } else if (retries <= maxRetries) {
      const nextRetries = retries + 1

      timeoutRef = window.setTimeout(
        () => wrapper(nextRetries),
        backOffHandler(timeoutMs, nextRetries, maxWaitMs)
      )
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        new Error(
          '[Datadog] withDatadogRetries permanently failed after retries'
        )
      )
    }
  }

  wrapper()

  // returns a cancel function
  return () => {
    window.clearTimeout(timeoutRef)
  }
}
