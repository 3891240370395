import type { Client } from '@epilot/entity-client'
import { getClient } from '@epilot/entity-client'
import type { AxiosRequestHeaders } from 'axios'

import { AUTH_TOKEN_OVERRIDE } from '../../config'
import { getPortalToken } from '../../utils/hooks/usePortalCheck/usePortalCheck'

let cachedClient: Client

export function getEntityClient(apiUrl: string): Client {
  if (!cachedClient) {
    cachedClient = configureClient(getClient(), apiUrl)
  }

  return cachedClient
}

const configureClient = (client: Client, apiUrl: string) => {
  client.defaults.baseURL = apiUrl

  client.interceptors.request.use(async (request) => {
    if (!request.headers) {
      request.headers = {} as AxiosRequestHeaders
    }

    request.headers['authorization'] =
      `Bearer ${AUTH_TOKEN_OVERRIDE ? AUTH_TOKEN_OVERRIDE : await getPortalToken()}`

    return request
  })

  return client
}
