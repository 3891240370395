import {
  BodyText,
  DialogContentText,
  H2,
  H6,
  SmallText,
  clsx
} from '@epilot/journey-elements'
import type { File, ProductDetailsData } from '@epilot/journey-logic-commons'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'

import { ProductImageRenderer } from '../../../renderers/controls/ProductSelectionControl/components/htmlComponents'

import { MarkdownRenderer } from './MarkdownRenderer'
import { ProductDetailsChart } from './ProductDetailsChart'
import { ProductDetailsDownloads } from './ProductDetailsDownloads'
import { ProductDetailsFeatures } from './ProductDetailsFeatures'
import { ProductDetailsPaymentConditions } from './ProductDetailsPaymentConditions'
import type { PaymentConditionItem } from './ProductDetailsPaymentConditions/types'
import { ProductDetailsPriceInformation } from './ProductDetailsPriceInformation'

type ProductDetailsContentProps = {
  data: ProductDetailsData
  classes: ClassNameMap<string>
  shouldDisplayImageGallery: boolean
  shouldDisplayPriceChart: boolean
  conditions: PaymentConditionItem[]
  downloads: File[]
}

export const ProductDetailsContent = ({
  data,
  classes,
  shouldDisplayImageGallery,
  shouldDisplayPriceChart,
  conditions,
  downloads
}: ProductDetailsContentProps) => {
  const { t } = useTranslation()

  const {
    product,
    price,
    id,
    pricingDetails,
    externalCatalogData,
    displayUnitaryAverage,
    showTrailingDecimalZeros
  } = data
  const {
    name,
    description,
    additional_notes: productAdditionalNotes
  } = product
  const { additional_information: priceAdditionalInformation } = price

  return (
    <>
      <div className={classes.container}>
        {shouldDisplayImageGallery && (
          <div className={classes.imageContainer}>
            <ProductImageRenderer id={id} product={product} />
          </div>
        )}
        <div className={classes.descriptionContainer}>
          {name && <H2 className={classes.descriptionHeading}>{name}</H2>}
          {description && (
            <DialogContentText
              id="product-details-dialog-description"
              tabIndex={-1}
            >
              <BodyText>{description}</BodyText>
            </DialogContentText>
          )}
          <ProductDetailsFeatures product={product} />
        </div>
      </div>

      <div className={classes.detailsContainer}>
        <div
          className={clsx(
            classes.detailsContent,
            !(conditions.length || priceAdditionalInformation) &&
              classes.detailsContentNoInformation
          )}
        >
          <div>
            <H6 className={classes.heading} id="price-information-heading">
              {t(
                'product_details.headings.price_information',
                'Price Information'
              )}
            </H6>
            {price && pricingDetails && (
              <ProductDetailsPriceInformation
                blockMappings={price.price_mappings}
                displayUnitaryAverage={displayUnitaryAverage}
                externalCatalogData={externalCatalogData}
                price={price}
                pricingDetails={pricingDetails}
                showTrailingDecimalZeros={showTrailingDecimalZeros}
              />
            )}
          </div>
          {!!downloads.length && (
            <div>
              <H6 className={classes.heading}>
                {t('product_details.headings.downloads', 'Downloads')}
              </H6>
              <ProductDetailsDownloads
                className={classes.downloadsContainer}
                data={data}
                downloads={downloads}
              />
            </div>
          )}
        </div>

        {!!(conditions.length || priceAdditionalInformation) && (
          <div>
            <H6 className={classes.heading}>
              {t(
                'product_details.headings.conditions_and_information',
                'Additional Information'
              )}
            </H6>
            {!!priceAdditionalInformation && (
              <div className={classes.paragraphContainer}>
                <BodyText>
                  <MarkdownRenderer>
                    {priceAdditionalInformation}
                  </MarkdownRenderer>
                </BodyText>
              </div>
            )}
            {!!conditions.length && (
              <ProductDetailsPaymentConditions conditions={conditions} />
            )}
          </div>
        )}
      </div>
      {shouldDisplayPriceChart && (
        <div className={classes.chartContainer}>
          <ProductDetailsChart price={price} />
        </div>
      )}

      {productAdditionalNotes && (
        <div className={classes.notesContainer}>
          <MarkdownRenderer breakSpaces={false}>
            {productAdditionalNotes}
          </MarkdownRenderer>
        </div>
      )}
      {product.legal_footnote && (
        <div className={classes.container}>
          <SmallText>
            <MarkdownRenderer>{product.legal_footnote}</MarkdownRenderer>
          </SmallText>
        </div>
      )}
    </>
  )
}
