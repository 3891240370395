import { toDateString, type UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  startDate?: Date
  endDate?: Date
}

export const dateBlockPorperties = ['startDate', 'endDate'] as const

export type DateBlockProperties = (typeof dateBlockPorperties)[number]

export const getVaribalesFromDateBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: DateBlockProperties[] = ['startDate']

  if (uiSchema.options?.fields?.endDate !== undefined) {
    properties.push('endDate')
  }

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromDateBlock = ({
  state: blockState
}: {
  state: unknown
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<DateBlockProperties, string>> =
    getValuesFromBlockState(
      blockState as BlockState,
      dateBlockPorperties,
      (value) => toDateString(value, true)
    )

  return data
}
