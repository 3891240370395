import { computeAggregatedAndPriceTotals } from '@epilot/pricing'
import type { CashbackAmount, PriceItemsDto } from '@epilot/pricing-client'

import {
  type CompositePrice,
  getExternalFeesMappings,
  getPriceMappings,
  type Price,
  type Product,
  type RecurrenceAmount,
  type SelectedProductTile,
  type ShoppingCartDataItem,
  sortRecurrences
} from '../../utils'
import type {
  PriceItemWithBlockConfiguration,
  ShoppingCartData,
  ExternalCatalogData
} from '../types'

const DEFAULT_RECURRENCE_AMOUNTS: Array<RecurrenceAmount> = []
const DEFAULT_CASHBACKS: Array<CashbackAmount> = []

export const formatPercentage = (value: number) => `${value}%`

export const generateProductPackage = (items: SelectedProductTile[]) => {
  const productPackage: Product[] = []

  if (items) {
    items.forEach((item) => {
      const quantity = item?.quantity
      const product = item?.product

      if (product && quantity) {
        const productImages = (product as any)?.selectionMetadata
          ?.selectedProduct?.product_images
        const productImage = productImages
          ? productImages[0]?.public_url
          : undefined

        for (let i = 1; i <= quantity; i++) {
          productPackage.push({ ...product, productImage: productImage })
        }
      }
    })
  }

  return productPackage
}

export const mapTileItemsToShoppingCartData = (
  item: SelectedProductTile
): ShoppingCartDataItem => ({
  _id: item.product?.selectedPriceId || '',
  quantity: item.quantity,
  product: item.product?.selectionMetadata?.selectedProduct,
  price: item.product?.selectionMetadata?.selectedPrice as
    | Price
    | CompositePrice,
  externalCatalogData:
    item.product?.selectionMetadata?.selectedExternalCatalogData,
  blockConfiguration: item.product?.selectionMetadata?.blockConfiguration
})

export const defineCartData = (data?: ShoppingCartData) => {
  const itemsCount =
    data?.reduce((count, { quantity }) => count + quantity, 0) ?? 0

  const shoppingCartItems = (data ?? []).map<PriceItemsDto[number]>((item) => {
    const blockMappings = getPriceMappings(item.price)
    const externalFeesMappings = getExternalFeesMappings(item.price)

    return {
      ...item.price,
      quantity: item.quantity || 1,
      _price: item.price,
      _product: item.product,
      _immutable_pricing_details: item.externalCatalogData?.pricing_details,
      blockConfiguration: item.blockConfiguration,
      ...(blockMappings.length > 0 && { price_mappings: blockMappings }),
      ...(externalFeesMappings.length > 0 && {
        external_fees_mappings: externalFeesMappings
      })
    }
  })

  const aggregatedPrices = computeAggregatedAndPriceTotals(shoppingCartItems)

  const aggregatedPricesRecurrences =
    aggregatedPrices.total_details?.breakdown?.recurrences ??
    DEFAULT_RECURRENCE_AMOUNTS

  const recurrences = sortRecurrences(aggregatedPricesRecurrences)

  const cashbacks =
    aggregatedPrices.total_details?.breakdown?.cashbacks ?? DEFAULT_CASHBACKS

  const displayableShoppingCartItems = (aggregatedPrices.items ?? []).map(
    (item) => {
      const tileInputData = data?.find(
        (productData) =>
          productData?.price._id &&
          productData?.price._id === item?._price?._id &&
          productData?.product?._id &&
          productData?.product._id === item?._product?._id
      )

      return {
        ...item,
        quantity: tileInputData?.quantity ?? 1,
        externalCatalogData: tileInputData?.externalCatalogData
      } as PriceItemWithBlockConfiguration & {
        externalCatalogData?: ExternalCatalogData
      }
    }
  )

  return {
    itemsCount,
    aggregatedPrices,
    recurrences,
    cashbacks,
    displayableShoppingCartItems
  }
}
