import { Auth } from '@aws-amplify/auth'
import { journeyStorage } from '@epilot/journey-logic-commons'

import { getCustomerPortalClient } from '../../clients'

export const configure = (userPoolId: string, userPoolClientId: string) => {
  Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    region: 'eu-central-1',
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolClientId
  })
}

export const getPortalConfig = async (
  apiUrl: string,
  organizationId: string
) => {
  return getCustomerPortalClient(apiUrl).getPublicPortalConfig({
    origin: 'END_CUSTOMER_PORTAL',
    org_id: organizationId
  })
}

export const startSignIn = async (email: string) => {
  try {
    const initialResponse = await Auth.signIn(email, undefined, {
      challengeType: 'JOURNEY_LOGIN_INITIATED'
    })

    console.log('🚀 ~ startSignIn ~ initialResponse:', initialResponse)

    return initialResponse
  } catch (originalError) {
    const error = new Error('Failed to start sign in process')

    error.cause = originalError

    throw error
  }
}

export const continueSignIn = async (session: any, code: string) => {
  const secondResponse = await Auth.sendCustomChallengeAnswer(session, code, {
    challengeType: 'JOURNEY_LOGIN_CODE_SENT'
  })

  console.log('🚀 ~ startSignIn ~ secondResponse:', secondResponse, code)

  const currentSession = await Auth.currentSession()

  const cognitoToken = currentSession.getIdToken()
  const token = cognitoToken.getJwtToken()

  if (token) {
    journeyStorage.setItem('token', token)
  }

  console.log('🚀 ~ continueSignIn ~ session:', { currentSession, token })

  return secondResponse
}

export const signOut = (token: string) => {
  console.log('🚀 ~ signOut ~ token:', token)
}
