import { BodyText } from '@epilot/journey-elements'
import {
  type PriceItem,
  getLineDiscountDetails
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { useMemo } from 'react'

import { useCartItemStyles } from '../CatalogShoppingCart/components/CartBody/styles'

type CartItemDetailsLineDiscountProps = {
  item: PriceItem
  t: TFunction
  showTrailingDecimalZeros?: boolean
}

export const CartItemDetailsLineDiscount = ({
  item,
  t,
  showTrailingDecimalZeros
}: CartItemDetailsLineDiscountProps) => {
  const classes = useCartItemStyles()

  const { discountAmountToDisplay, beforeDiscountAmountTotalToDisplay } =
    useMemo(
      () => getLineDiscountDetails(item, t, showTrailingDecimalZeros),
      [item, showTrailingDecimalZeros, t]
    )

  return (
    <div className={classes.discountInnerContainer}>
      {beforeDiscountAmountTotalToDisplay !== undefined && (
        <BodyText
          className={classes.priceBeforeDiscount}
          color="textSecondary"
          display="block"
        >
          {beforeDiscountAmountTotalToDisplay}
        </BodyText>
      )}
      {discountAmountToDisplay !== undefined && (
        <BodyText
          className={classes.discount}
          color="textSecondary"
          display="block"
        >
          -{discountAmountToDisplay}
        </BodyText>
      )}
    </div>
  )
}
