import { makeStyles } from '@material-ui/core'

import type { Theme } from '../'
import { Select, MenuItem, createStyles } from '../'

import type { EnumControlProps } from './EnumControl'

export type EnumSelectControlProps = EnumControlProps

export function EnumSelectControlPure({
  errorMessage,
  label,
  onChange,
  options = [],
  optionsLabels,
  placeholder,
  required,
  value,
  disabled,
  variant,
  size,
  defaultSelection: initial,
  customBackgroundColor,
  removeEmptyOption,
  id
}: EnumSelectControlProps) {
  const labels = optionsLabels ? optionsLabels : options
  const classes = useStyles()

  return (
    <Select
      customBackgroundColor={customBackgroundColor}
      disabled={disabled}
      error={errorMessage ? errorMessage : ''}
      fullWidth={true}
      id={id}
      label={
        <>
          {label}
          {required && (
            <span className={errorMessage && classes.errorColor}> *</span>
          )}
        </>
      }
      onChange={(ev) => onChange(ev.target.value)}
      placeholder={placeholder}
      required={required}
      size={size}
      value={value || initial}
      variant={variant}
    >
      {!removeEmptyOption && <MenuItem key={'empty'} value="" />}
      {options &&
        options.map((option: string, index: number) => (
          <MenuItem key={option + index} value={option}>
            {labels[index]}
          </MenuItem>
        ))}
    </Select>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorColor: {
      color: theme.palette.error.main
    }
  })
)
