export type JourneyLoadingEventPayload = {
  value: boolean
}

export type ActionBarControlOptions = {
  ctaButton: ActionGoNext | ActionSubmitAndGoNext
  goBackButton: ActionGoBack
  consents: ConsentBox[]
}

export type ActionGoNext = ActionCommon & {
  actionType: ACTION_TYPE.GO_NEXT
  targetStepId: string
}

export type ActionGoBack = ActionCommon & {
  actionType: ACTION_TYPE.GO_BACK
}

/**
 * @private Should not be exported
 */
type ActionCommon = {
  label: string
  isVisible: boolean
}

export type Action = ActionSubmitAndGoNext | ActionGoNext | ActionGoBack

export type ActionSubmitAndGoNext = ActionCommon & {
  actionType: ACTION_TYPE.SUBMIT_GO_NEXT
  targetStepId: string
}

export enum ACTION_TYPE {
  SUBMIT_GO_NEXT = 'SubmitAndGoNext',
  GO_NEXT = 'GoNext',
  GO_BACK = 'GoBack'
}

export type ConsentBox = {
  name: string
  text: string
  isRequired: boolean
  isVisible: boolean
}

export type Consents = Record<string, boolean>
