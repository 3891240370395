import type {
  StepState,
  HistoryStack,
  EpilotECPTokenPayload,
  JourneyAccessMode,
  JourneySession
} from '@epilot/journey-logic-commons'
import {
  JOURNEY_ACCESS_MODE,
  journeyStorage
} from '@epilot/journey-logic-commons'
import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { jwtDecode } from 'jwt-decode'
import { useCallback } from 'react'

import { getLocalToken } from '../usePortalCheck'

import type { UserSessions, useUserProgressProps } from './types'

let currentSessionId: string | undefined = undefined
let journeyAPIBaseUrl: string | undefined = undefined

export function useUserProgress({ journey }: useUserProgressProps) {
  const mode = getStorageMode(
    journey?.settings?.savingProgress?.mode,
    journey?.settings?.accessMode
  )

  const onSaveProgress = (
    stepIndex: number,
    stepsState: StepState[],
    historyStack: HistoryStack[]
  ) => {
    if (
      journey?.journeyId &&
      mode !== JOURNEY_SAVING_PROGRESS_MODE.NONE &&
      currentSessionId
    ) {
      saveProgress(
        journey.journeyId,
        stepIndex,
        stepsState,
        currentSessionId,
        historyStack,
        journey.revisions,
        mode
      )
    }
  }

  const setCurrentSessionId = useCallback(
    (sessionId: string, journeyAPI: string) => {
      currentSessionId = sessionId
      journeyAPIBaseUrl = journeyAPI
    },
    []
  )

  const getExistingSessions = useCallback(
    (journeyAPI: string) => {
      journeyAPIBaseUrl = journeyAPI
      if (journey?.journeyId && mode !== JOURNEY_SAVING_PROGRESS_MODE.NONE) {
        return getSessions(journey.journeyId, journey.revisions, mode)
      }

      return Promise.resolve([])
    },
    [journey, mode]
  )

  const clearCurrentUserSession = async () => {
    if (journey?.journeyId && currentSessionId) {
      const sessions = await getSessions(journey.journeyId, journey.revisions)

      deleteSessionLocally(sessions, journey.journeyId)

      if (mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE) {
        const currentSession = sessions.find(
          (session) => session.id === currentSessionId
        )

        currentSession && deleteSessionRemotely(currentSession)
      }

      currentSessionId = undefined
    }
  }

  return {
    saveProgress: onSaveProgress,
    setCurrentSessionId,
    currentSessionId,
    getExistingSessions,
    clearCurrentUserSession
  }
}

export function getJourneySessionKey(journeyId: string) {
  return `journey-progress-${journeyId}`
}

// write data to local storage
const saveProgress = (
  journeyId: string,
  stepIndex: number,
  stepsState: StepState[],
  sessionId: string,
  historyStack: HistoryStack[],
  journeyVersion?: number,
  mode?: JOURNEY_SAVING_PROGRESS_MODE
) => {
  // if data in the stepsState array is empty, return
  if (
    !stepsState.length ||
    stepsState.filter(
      (stepState) => !(stepState && Object.keys(stepState).length > 0)
    ).length === 0
  ) {
    return
  }
  const previousProgressStr = journeyStorage.getItem(
    getJourneySessionKey(journeyId)
  )

  let previousProgress: JourneySession[] = []

  if (previousProgressStr) {
    previousProgress = JSON.parse(previousProgressStr)
  }

  const currentSessionIndex = previousProgress.findIndex(
    (session) => session.id === sessionId
  )

  const progress: JourneySession = {
    journeyId,
    stepIndex,
    stepsState,
    id: sessionId,
    date: new Date(),
    journeyVersion,
    historyStack
  }

  if (currentSessionIndex > -1) {
    previousProgress[currentSessionIndex] = progress
  } else {
    previousProgress.push(progress)
  }

  saveProgressLocally(previousProgress, journeyId)
  journeyAPIBaseUrl &&
    mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE &&
    saveProgressRemotely(progress)
}

function getUpdateJourneySessionURL(journeyId: string, userId: string) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}/session?user_id=${userId}`
}

function getDeleteJourneySessionURL(
  journeyId: string,
  userId: string,
  sessionId: string
) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}/session/${sessionId}?user_id=${userId}`
}

function getJourneySessionsURL(journeyId: string, userId: string) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}?user_id=${userId}`
}

function saveProgressRemotely(jourrneySession: JourneySession) {
  const token = getLocalToken()

  if (token) {
    const claims = jwtDecode<EpilotECPTokenPayload>(token)
    const url = getUpdateJourneySessionURL(
      jourrneySession.journeyId,
      claims.email
    )

    // put jourrneySession via fetch call to url, token should be sat in the Authorization header
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(jourrneySession)
    })
  }
}

async function deleteSessionLocally(
  sessions: JourneySession[],
  journeyId: string
) {
  const cleanSessions = sessions?.filter(
    (session) => session.id !== currentSessionId
  )

  journeyStorage.setItem(
    getJourneySessionKey(journeyId),
    JSON.stringify(cleanSessions)
  )
}

function deleteSessionRemotely(jourrneySession: JourneySession) {
  const token = getLocalToken()

  if (token) {
    const claims = jwtDecode<EpilotECPTokenPayload>(token)
    const url = getDeleteJourneySessionURL(
      jourrneySession.journeyId,
      claims.email,
      jourrneySession.id
    )

    // put jourrneySession via fetch call to url, token should be sat in the Authorization header
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
  }
}

export function getStorageMode(
  storageMode?: JOURNEY_SAVING_PROGRESS_MODE,
  journeyAccessMode?: JourneyAccessMode
) {
  switch (storageMode) {
    // in case it is auto, check the journey access mode, if private then store remotely, otherwise store locally
    case JOURNEY_SAVING_PROGRESS_MODE.AUTO:
      return journeyAccessMode === JOURNEY_ACCESS_MODE.PRIVATE
        ? JOURNEY_SAVING_PROGRESS_MODE.REMOTE
        : JOURNEY_SAVING_PROGRESS_MODE.LOCAL

    case JOURNEY_SAVING_PROGRESS_MODE.REMOTE:
      return JOURNEY_SAVING_PROGRESS_MODE.REMOTE
    case JOURNEY_SAVING_PROGRESS_MODE.LOCAL:
      return JOURNEY_SAVING_PROGRESS_MODE.LOCAL
    default:
      return JOURNEY_SAVING_PROGRESS_MODE.NONE
  }
}

function saveProgressLocally(
  previousProgress: JourneySession[],
  journeyId: string
) {
  journeyStorage.setItem(
    getJourneySessionKey(journeyId),
    JSON.stringify(previousProgress)
  )
}

async function getSessionsRemotely(journeyId: string, journeyVersion?: number) {
  const token = getLocalToken()

  if (token) {
    try {
      const claims = jwtDecode<EpilotECPTokenPayload>(token)
      const url = getJourneySessionsURL(journeyId, claims.email)

      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      const userSessions: UserSessions = await res.json()

      const previousProgress = userSessions.journeyId
        ? userSessions.sessions
        : []

      return typeof journeyVersion === 'number'
        ? previousProgress.filter(
            (session) => session.journeyVersion === journeyVersion
          )
        : previousProgress
    } catch {
      return []
    }
  }

  return []
}

async function getSessions(
  journeyId: string,
  journeyVersion?: number,
  mode?: JOURNEY_SAVING_PROGRESS_MODE
) {
  let sessions = getSessionsLocally(journeyId, journeyVersion)

  if (mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE) {
    const remoteSessions = await getSessionsRemotely(journeyId, journeyVersion)

    if (Array.isArray(remoteSessions) && remoteSessions.length > 0) {
      sessions = mergeRemoteSessionsWithLocal(sessions, remoteSessions)
      saveProgressLocally(sessions, journeyId)
    }

    return remoteSessions
  }

  return sessions
}

export function mergeRemoteSessionsWithLocal(
  localSessions: JourneySession[],
  remoteSessions: JourneySession[]
) {
  const sessionMap = new Map<string, JourneySession>()

  // Helper function to add/update the session in the map
  function addOrUpdateSession(session: JourneySession) {
    const existingSession = sessionMap.get(session.id)

    if (
      !existingSession ||
      new Date(session.date) > new Date(existingSession.date)
    ) {
      sessionMap.set(session.id, session)
    }
  }

  // Merge both arrays
  localSessions.forEach(addOrUpdateSession)
  remoteSessions.forEach(addOrUpdateSession)

  // Convert map values to array and sort by date (most recent first)
  return Array.from(sessionMap.values()).sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  )
}

function getSessionsLocally(journeyId: string, journeyVersion?: number) {
  const previousProgressStr = journeyStorage.getItem(
    getJourneySessionKey(journeyId)
  )

  let previousProgress: JourneySession[] = []

  if (previousProgressStr) {
    previousProgress = JSON.parse(previousProgressStr)
  }

  return typeof journeyVersion === 'number'
    ? previousProgress.filter(
        (session) => session.journeyVersion === journeyVersion
      )
    : previousProgress
}
