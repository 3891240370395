/**
 * @todo Rename file to extractAndSortItemRecurrences.ts
 */
import { computePriceItemDetails, BillingPeriods } from '@epilot/pricing'
import type {
  CompositePriceItem,
  CompositePriceItemDto,
  PriceItem,
  PricingDetails,
  RecurrenceAmount
} from '@epilot/pricing-client'

const compareRecurrences = (
  firstRecurrence: RecurrenceAmount,
  secondRecurrence: RecurrenceAmount
) => {
  if (firstRecurrence.type === 'one_time') return -1
  if (secondRecurrence.type === 'one_time') return 1

  const sortedRecurrences = [...BillingPeriods]
  const firstRecurrencePeriodIndex = sortedRecurrences.indexOf(
    firstRecurrence.billing_period!
  )
  const secondRecurrencePeriodIndex = sortedRecurrences.indexOf(
    secondRecurrence.billing_period!
  )

  return firstRecurrencePeriodIndex - secondRecurrencePeriodIndex
}

/**
 * Sort recurrences by billing period without mutating the original array
 */
export const sortRecurrences = (recurrences: RecurrenceAmount[]) => {
  return [...recurrences].sort(compareRecurrences)
}

const makeDefaultRecurrence = (
  item: PriceItem | CompositePriceItem
): RecurrenceAmount => {
  return {
    ...item,
    type: item._price?.type,
    billing_period: item._price?.billing_period,
    amount_subtotal: item.amount_subtotal ?? 0,
    amount_subtotal_decimal: item.amount_subtotal_decimal ?? '0',
    amount_total: item.amount_total ?? 0,
    amount_total_decimal: item.amount_total_decimal ?? '0'
  }
}

const isPricingDetails = (
  item: PriceItem | CompositePriceItem
): item is PricingDetails => 'total_details' in item

/**
 * Extracts recurrences for a given price item
 */
export const extractItemRecurrences = (
  item: PriceItem | CompositePriceItem
): Array<RecurrenceAmount> => {
  if (isCompositePriceItemDto(item)) {
    let pricingDetails

    if (item._product?.['is_external']) {
      pricingDetails = item as PricingDetails
    } else if (isPricingDetails(item)) {
      pricingDetails = item
    } else {
      /**
       * This case shouldn't occur, as item has already been computed.
       * Leave error logging for now until it's certain it's not occuring in production.
       * @todo Remove error logging once it's certain it's not occuring in production.
       */
      console.error(new Error('Had to compute price item details again'))
      pricingDetails = computePriceItemDetails(item)
    }

    return (
      pricingDetails.total_details?.breakdown?.recurrences ?? [
        makeDefaultRecurrence(item)
      ]
    )
  } else {
    return [makeDefaultRecurrence(item)]
  }
}

/**
 * Extracts recurrences for a given price item and returns them sorted
 */
export const extractAndSortItemRecurrences = (
  item: PriceItem | CompositePriceItem
): Array<RecurrenceAmount> => {
  const recurrences = extractItemRecurrences(item)

  return sortRecurrences(recurrences)
}

const isCompositePriceItemDto = (
  item: PriceItem | CompositePriceItem
): item is CompositePriceItemDto => Boolean(item.is_composite_price)
