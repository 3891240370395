import type { ChangeEvent, FocusEventHandler } from 'react'
import { useState, forwardRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { TextField } from './TextField'
import type { TextFieldProps } from './types'

const DEFAULT_DEBOUNCE_TIME_MS = 300

type DebouncedTextFieldProps = TextFieldProps & {
  debounceTime?: number
  onChange?: (event: any) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | string
}

export const DebouncedTextField = forwardRef<
  HTMLInputElement,
  DebouncedTextFieldProps
>(({ debounceTime = DEFAULT_DEBOUNCE_TIME_MS, ...props }, ref) => {
  const [value, setValue] = useState<any>(props.value)

  const onChangeDebounced = useDebouncedCallback((value: string) => {
    props.onChange?.({ target: { value } })
  }, debounceTime)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onChangeDebounced(event.target.value)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (props.onBlur) {
      const value = props.onBlur(event)

      if (typeof value === 'string') setValue(value)
    }
  }

  return (
    <TextField
      {...props}
      onBlur={handleBlur}
      onChange={handleChange}
      ref={ref}
      value={value}
    />
  )
})

DebouncedTextField.displayName = 'DebouncedTextField'
